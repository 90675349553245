<template>
  <div>
    <div class="data-content" :class="{show: historic}" :style="{display: historic ? 'none' : 'block'}">
      <div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Situação</th>
              <th scope="col">Referência</th>
              <th scope="col">Busca</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              :key="i"
              v-for="(reference, i) in data.references"
            >
              <td>
                <template v-if="reference.loading || reference.status.info == 'Verificado Erro' || reference.status.info == 'Informar Faturamento' || reference.status.info == 'Não Verificado'">
                  <div class="info-status status-loading" style="font-size: 12px; font-weight: 700;">
                    Buscando...
                  </div>
                </template>
                <template v-else>
                  <div class="info-status" :class="[reference.status ? reference.status.color : '']" style="font-size: 12px; font-weight: 700;">
                    <template v-if="reference.status.info === 'Em Aberto'">
                      Em Dia
                    </template>
                    <template v-else>
                      {{reference.status.info}}
                    </template>
                  </div>
                </template>
              </td>
              <td>{{reference.referenceNumber}}</td>
              <td>{{reference.lastProcess.replaceAll('-','/')}}</td>
              <td style="width: 140px"><a class="btn btn-sm btn-primary w-100" :class="!reference.historic ? 'disabled' : '' " @click="historic = reference.historic">Acessar Guias</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="data-content" :class="{show: historic}" :style="{display: historic ? 'block' : 'none'}">
      <div>
        <div class="row justify-content-end pr-3 pt-3"><div style="padding: 10px 16px !important;" class="btn btn-secondary btn-sm" @click="historic = null"><i class="fa-solid fa-xmark" style="margin-right: 0 !important;"></i></div></div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Parcela</th>
              <th scope="col">Situação</th>
              <th scope="col">Busca</th>
              <th scope="col">Vencimento</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody v-if="historic">
            <tr
              :key="i"
              v-for="(historic, i) in Object.keys(this.historic).map(i => this.historic[i]).sort((a, b) => a.paymentNumber - b.paymentNumber).slice().reverse()"
            >
              <td>{{historic.paymentNumber}}</td>
              <td>{{historic.paymentStatus}}</td>
              <td>{{historic.dtFinished.replaceAll('-','/')}}</td>
              <td>{{historic.dtDue.replaceAll('-','/')}}</td>
              <td style="width: 120px"><a class="btn btn-sm btn-primary w-100" target="_black" :href="historic.documentUrl" :class="historic.documentUrl && historic.paymentStatus !== 'Pago' ? '' : 'disabled'">Visualizar</a></td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--<div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal()" data-bs-dismiss="modal">Fechar</button>
        </div>-->
    </div>
  </div>

</template>

<script>
export default {
  props: ['data'],

  components: {
  },

  data: () => ({
    showModal: false,
    tabsType: '',
    historic: null
  }),

  computed: {
  },

  methods: {
    closeModal () {
      this.showModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.data-content{
  .btn-primary{
    background: var(--featured-dark) !important;
  }
  .modal-header, .modal-footer{
    border: none !important;
  }

  .modal-body{
    padding-top: 0;
  }

  .nav-tabs{
    border: 0;
    margin: 0;
    // border-radius: 7px !important;
  }
  .nav-tabs .nav-item {
    margin-bottom: 0px;
    text-align: center;
    color: var(--featured-dark);
    font-weight: 700;
    border-radius: 0px !important;
    border: var(--featured-dark) solid 2px !important;
    cursor: pointer !important;
    border-right: 0 !important;
    font-size: 13.5px;
    &.active{
      color: white;
      background: var(--featured-dark);
    }
  }

  .table{
    margin-top: 15px;
    box-shadow: none;
    background-color: white;
    border-radius: 4px;
    padding: 0;
    border-spacing: 0;
    th{
      border-top: none !important;
      border-bottom: none !important;
      padding: 7px 5px !important;
      padding-top: 5px !important;
      font-size: 13.5px;
    }
    td{
      border-top: #e9e9e9 solid 1px;
      font-size: 13px;
      padding: 7px 5px !important;
    }
    .btn-secondary, .btn-primary, .btn-danger, .btn-warning{
      font-size: 12px !important;
      padding: .65rem .9rem !important;
    }
  }

  .info-status{
    border: none;
    cursor: default !important;
    font-size: 12px !important;
    margin: 0px 0px 0px 3px !important;
    border-radius: 4px;
    height: 30px;
    font-weight: 700 !important;
    background-color: white;
    transition: all .2s;
    padding: 6px 8px !important;
    min-width: 40px;
    width: 160px !important;
    text-align: center;
  }
  h4, h5{
    font-weight: 700;
  }
  h5{
    font-size: 15px;
  }
  .form-group{
    margin: 7px 0;
  }
}
.details-buttom{
  color: blue !important;
  cursor: pointer;
  &[disabled]{
    cursor: not-allowed !important;
    color: gray !important;
  }
  &::selection {
      color: none;
      background: none;
  }
}
</style>
