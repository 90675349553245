<template>
  <div>
    <div class="data-content" :class="{show: details}" :style="{display: details ? 'none' : 'block'}">
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <div :key="i" v-for="(historic, key, i) in historic" :style="{'width': 100 / historicLenght + '%'}">
            <a class="nav-item nav-link" :class="[tabsType === key ? 'active' : '']" :style="historicLenght === 1 ? 'border-radius: 5px !important;' : '' || i === 0 ? 'border-top-left-radius: 5px !important; border-bottom-left-radius: 5px !important;' : '' || i === historicLenght - 1 ? 'border-top-right-radius: 5px !important; border-bottom-right-radius: 5px !important; border-right: var(--featured-light) solid 2px !important;' : ''" @click="tabsType = key">{{key.toUpperCase()}}</a>
          </div>
        </div>
      </nav>
      <div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Per.</th>
              <th scope="col" style="width: 135px">Pagamento</th>
              <th scope="col" v-if="$screen.height > 900">Busca</th>
              <th scope="col">Total</th>
              <th scope="col">Venc.</th>
              <th scope="col">Declarações</th>
              <th scope="col" style="min-width: 120px !important; width: 120px !important"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              :key="i"
              v-for="(historic, i) in sortedHistoric"
            >
              <template v-if="historic.monthCollection && historic.transmissions">
                <td style="width: 40px">
                  <div class="info-status m-0 status-success" style="width: 50px !important; min-width: 50px !important; height: 30px; padding: 7px">
                    {{historic.monthCollection}}
                  </div>
                </td>

                <td>
                  <template v-if="historic.installment">Parcelado</template>
                  <template v-else-if="verifyPayment(historic) == 'Sem Movimento'">Sem Movimento</template>
                  <template v-else-if="verifyPayment(historic)">Pago</template>
                  <template v-else>Pendente</template>
                </td>

                <td v-if="$screen.height > 900">{{historic.lastProcess.replaceAll('-', '/')}}</td>

                <td
                  data-toggle="tooltip"
                  data-placement="bottom"
                  style="cursor: pointer"
                  :title="historic.generated && historic.generated.lastGenerated ? 'Principal = ' + historic.generated.lastGenerated.value + '\nMulta = ' + historic.generated.lastGenerated.fine + '\nJuros = ' + historic.generated.lastGenerated.fees + '\nTotal = ' + historic.generated.lastGenerated.total : ''"
                >
                  <template v-if="historic.generated && historic.generated.lastGenerated">
                    R${{historic.generated.lastGenerated.total}}
                  </template>
                  <template v-else>
                    -
                  </template>

                </td>

                <td>
                  <template v-if="historic.generated && historic.generated.lastGenerated">
                    {{historic.generated.lastGenerated.expirationDate.replaceAll('-', '/')}}
                  </template>
                  <template v-else>
                    -
                  </template>

                </td>

                <td><a class="details-buttom" @click="details = historic.transmissions">Consultar</a></td>

                <td>
                  <template v-if="historic.generated && historic.generated.lastGenerated && !verifyPayment(historic)">
                    <a class="btn btn-sm btn-primary w-100" target="_black" :href="historic.generated.lastGenerated.documentUrl">Baixar Guia</a>
                  </template>
                  <template v-else>
                    <a class="btn btn-sm btn-secondary disabled w-100" target="_black">Baixar Guia</a>
                  </template>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="data-details" :class="{show: details}" :style="{display: details ? 'block' : 'none'}">
      <div>
        <div class="row justify-content-end pr-3"><div style="padding: 10px 16px !important;" class="btn btn-secondary btn-sm" @click="details = null"><i class="fa-solid fa-xmark" style="margin-right: 0 !important;"></i></div></div>
        <table class="table">
          <tbody class="w-100">
            <div :key="i"
              v-for="(detail, i) in details" class="mb-3">
            <thead class="w-100">
              <tr>
                <th scope="col">Operação</th>
                <th scope="col">N° Declaração</th>
                <th scope="col">Rec.</th>
                <th scope="col">Dec.</th>
                <th scope="col">Emissão</th>
                <th scope="col">Extrato</th>
                <th scope="col">Pago</th>
              </tr>
            </thead>
            <tr>
              <td style="background-color: var(--background-gray); min-width: 100px; width: 100%;">{{detail.operation}}</td>
              <td style="background-color: var(--background-gray); min-width: 110px">{{detail.numberDeclaration}}</td>
              <td class="text-center" style="background-color: var(--background-gray); min-width: 35px"><a :href="detail.documentReceiptUrl" :disabled="!detail.documentReceiptUrl" targer="_black"><i class="fas fa-arrow-circle-down"></i></a></td>
              <td class="text-center" style="background-color: var(--background-gray); min-width: 35px"><a :href="detail.documentDeclarationUrl" :disabled="!detail.documentDeclarationUrl" targer="_black"><i class="fas fa-arrow-circle-down"></i></a></td>
              <td style="background-color: var(--background-gray); width: 90px"></td>
              <td style="background-color: var(--background-gray); width: 50px"></td>
              <td style="background-color: var(--background-gray); width: 50px"></td>
            </tr>
            <tr
              :key="i"
              v-for="(item, i) in detail.dasGenerated"
              class="text-left;"
            >
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{item.dtEmission.substring(0, 10)}}</td>
              <td class="text-center"><a :href="item.documentExtractUrl" :disabled="!item.documentExtractUrl" targer="_black"><i class="fas fa-arrow-circle-down"></i></a></td>
              <td>{{item.paied}}</td>
            </tr>
            </div>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['collection', 'historic'],

  components: {
  },

  created () {
    this.$root.$on('SimplesHistoric::show', () => this.initicalChanges())
    this.initicalChanges()
  },

  data: () => ({
    showModal: false,
    tabsType: '',
    details: null
  }),

  computed: {
    sortedTransmissions () {
      if (this.details) {
        var tempTransmissions = this.details

        tempTransmissions = Object.keys(this.transmissions).map(i => this.transmissions[i]).slice()

        tempTransmissions = tempTransmissions.sort((a, b) => { return parseInt(a.paymentNumber, 10) - parseInt(b.paymentNumber, 10) })

        return tempTransmissions
      }
      return null
    },
    sortedHistoric () {
      var tempHistoric = this.historic

      if (this.historic && this.historic.[this.tabsType]) {
        tempHistoric = this.historic.[this.tabsType]
        tempHistoric = Object.keys(this.historic.[this.tabsType]).map(i => this.historic.[this.tabsType][i]).slice().reverse()

        tempHistoric = tempHistoric.sort((a, b) => { return parseInt(a.monthCollection, 10) - parseInt(b.monthCollection, 10) }).slice().reverse()
      }
      // return tempHistoric.sort((a, b) => a.paymentNumber - b.paymentNumber).slice().reverse()
      return tempHistoric
    },
    historicLenght () {
      var tempHistoric = this.historic
      if (this.historic) {
        tempHistoric = Object.keys(this.historic).map(i => this.historic[i]).slice().reverse()
      }
      return tempHistoric.length
    }
  },

  methods: {
    verifyPayment (historic) {
      var hasDas = false
      if (historic.transmissions) {
        const tempTransmissions = Object.keys(historic.transmissions).map(i => historic.transmissions[i])
        for (let i = 0; i < tempTransmissions.length; i++) {
          if (tempTransmissions[i].withoutMoviment) {
            return 'Sem Movimento'
          } else if (!tempTransmissions[i].dasGenerated && i === tempTransmissions.length - 1) {
            hasDas = false
          } else if (tempTransmissions[i].dasGenerated) {
            hasDas = true
            const tempDasGenerated = Object.keys(tempTransmissions[i].dasGenerated).map(t => tempTransmissions[i].dasGenerated[t])
            for (let z = 0; z < tempDasGenerated.length; z++) {
              if (tempDasGenerated[z].paied === 'Sim') {
                return true
              }
            }
          }
        }
      }
      if (!hasDas) {
        return 'Sem Movimento'
      }
      return false
    },
    emailsReadList (emailsRead) {
      let info = ''
      emailsRead = Object.keys(emailsRead).map(i => emailsRead[i])
      for (let i = 0; i < emailsRead.length; i++) {
        info += emailsRead[i].emailAddress + ' abriu no dia ' + emailsRead[i].dtSent + '\n'
      }
      return info
    },
    closeModal () {
      this.showModal = false
    },
    initicalChanges () {
      var tempHistoric = this.historic
      if (this.historic) {
        tempHistoric = Object.keys(this.historic).map(i => this.historic[i]).slice().reverse()
        this.tabsType = Object.keys(this.historic)[tempHistoric.length - 1]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.data-content{
  .btn-primary{
    background: var(--featured-dark) !important;
  }
  .modal-header, .modal-footer{
    border: none !important;
  }

  .modal-body{
    padding-top: 0;
  }

  .nav-tabs{
    border: 0;
    margin: 0;
    // border-radius: 7px !important;
  }
  .nav-tabs .nav-item {
    margin-bottom: 0px;
    text-align: center;
    color: var(--featured-dark);
    font-weight: 700;
    border-radius: 0px !important;
    border: var(--featured-dark) solid 2px !important;
    cursor: pointer !important;
    border-right: 0 !important;
    font-size: 13.5px;
    &.active{
      color: white;
      background: var(--featured-dark);
    }
  }

  .table{
    box-shadow: none;
    background-color: white;
    border-radius: 4px;
    padding: 0;
    border-spacing: 0;
    th{
      border-top: none !important;
      border-bottom: none !important;
      padding: 7px 5px !important;
      padding-top: 5px !important;
      font-size: 13.5px;
    }
    td{
      border-top: #e9e9e9 solid 1px;
      font-size: 12.5px;
      padding: 7px 5px !important;
    }
    .btn-secondary, .btn-primary, .btn-danger, .btn-warning{
      font-size: 12px !important;
      padding: .65rem .9rem !important;
    }
  }
  h4, h5{
    font-weight: 700;
  }
  h5{
    font-size: 15px;
  }
  .form-group{
    margin: 7px 0;
  }
}
.details-buttom{
  color: blue !important;
  cursor: pointer;
  &[disabled]{
    cursor: not-allowed !important;
    color: gray !important;
  }
  &::selection {
      color: none;
      background: none;
  }
}
.data-details{
  .table{
    box-shadow: none;
    background-color: white;
    border-radius: 4px;
    padding: 0;
    border-spacing: 0;
    th{
      border-top: none !important;
      border-bottom: none !important;
      padding: 7px 7px !important;
      padding-top: 5px !important;
      font-size: 13px;
    }
    td{
      border-top: none;
      font-size: 11.7px;
      padding: 7px 7px !important;
    }
    .btn-secondary, .btn-primary, .btn-danger, .btn-warning{
      padding: .7rem 1rem !important;
    }
  }
}
</style>
