<template>
  <div class="container-fluid content-container" :style="'--featured: ' + customersArea.featured + '; --featured-dark: ' + customersArea.featuredDark + '; --featured-light: ' + customersArea.featuredLight + '; --featured-light-1: ' + customersArea.featuredLight1 + '; --featured-light-2: ' + customersArea.featuredLight2 + '; --featured-light-03: ' + customersArea.featuredLight03 + '; --featured-light-02: ' + customersArea.featuredLight02 + '; --featured-light-01: ' + customersArea.featuredLight01">
    <a class="float" v-if="screen.width < 900 && page !== 'chat'" @click="page = 'chat'; scrollMenuTo('chat')"><i class="fa-solid fa-message my-float"></i></a>
    <div class="row">
      <div class="navigation-sidebar" v-if="screen.width > 900">
        <div class="col w-100 p-0">
          <a class="nav-button" @click="page = 'chat'; scrollMenuTo('chat'); changeInitialPage()" :class="[page === 'chat' ? 'active' : '']"><i class="fa-solid fa-message"></i><div class="new-box"><span>1</span></div></a>
          <a class="nav-button" @click="page = 'inbox'; scrollMenuTo('inbox'); changeInitialPage()" :class="[page === 'inbox' ? 'active' : '']"><i class="fa-solid fa-inbox"></i></a>
          <a class="nav-button" v-if="summary.simplesCollection.loading || summary.simplesCollection.status" @click="page = 'simples-collection'; scrollMenuTo('simples-collection')" :class="[page === 'simples-collection' ? 'active' : '']"><svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M64,0C28.7,0,0,28.7,0,64V448c0,35.3,28.7,64,64,64H320c35.3,0,64-28.7,64-64V160h-128c-17.7,0-32-14.3-32-32V0H64Zm192,0V128h128L256,0ZM64,80c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm0,64c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm128,72c8.8,0,16,7.2,16,16v17.3c8.5,1.2,16.7,3.1,24.1,5.1,8.5,2.3,13.6,11,11.3,19.6s-11,13.6-19.6,11.3c-11.1-3-22-5.2-32.1-5.3-8.4-.1-17.4,1.8-23.6,5.5-5.7,3.4-8.1,7.3-8.1,12.8,0,3.7,1.3,6.5,7.3,10.1,6.9,4.1,16.6,7.1,29.2,10.9l.5,.1h0c11.3,3.4,25.3,7.6,36.3,14.6,12.1,7.6,22.4,19.7,22.7,38.2,.3,19.3-9.6,33.3-22.9,41.6-7.7,4.8-16.4,7.6-25.1,9.1v17.1c0,8.8-7.2,16-16,16s-16-7.2-16-16v-17.8c-11.2-2.1-21.7-5.7-30.9-8.9h0c-2.1-.7-4.2-1.4-6.2-2.1-8.4-2.8-12.9-11.9-10.1-20.2,2.8-8.3,11.9-12.9,20.2-10.1,2.5,.8,4.8,1.6,7.1,2.4h0c13.6,4.6,24.6,8.4,36.3,8.7,9.1,.3,17.9-1.7,23.7-5.3,5.1-3.2,7.9-7.3,7.8-14-.1-4.6-1.8-7.8-7.7-11.6-6.8-4.3-16.5-7.4-29-11.2l-1.6-.5h0c-11-3.3-24.3-7.3-34.8-13.7-12-7.2-22.6-18.9-22.7-37.3-.1-19.4,10.8-32.8,23.8-40.5,7.5-4.4,15.8-7.2,24.1-8.7v-17.2c0-8.8,7.2-16,16-16h0Z"/></svg></a>
          <a class="nav-button" v-if="summary.meiCollection.loading || summary.meiCollection.status" @click="page = 'mei-collection'; scrollMenuTo('mei-collection')" :class="[page === 'mei-collection' ? 'active' : '']"><svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M64,0C28.7,0,0,28.7,0,64V448c0,35.3,28.7,64,64,64H320c35.3,0,64-28.7,64-64V160h-128c-17.7,0-32-14.3-32-32V0H64Zm192,0V128h128L256,0ZM64,80c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm0,64c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm128,72c8.8,0,16,7.2,16,16v17.3c8.5,1.2,16.7,3.1,24.1,5.1,8.5,2.3,13.6,11,11.3,19.6s-11,13.6-19.6,11.3c-11.1-3-22-5.2-32.1-5.3-8.4-.1-17.4,1.8-23.6,5.5-5.7,3.4-8.1,7.3-8.1,12.8,0,3.7,1.3,6.5,7.3,10.1,6.9,4.1,16.6,7.1,29.2,10.9l.5,.1h0c11.3,3.4,25.3,7.6,36.3,14.6,12.1,7.6,22.4,19.7,22.7,38.2,.3,19.3-9.6,33.3-22.9,41.6-7.7,4.8-16.4,7.6-25.1,9.1v17.1c0,8.8-7.2,16-16,16s-16-7.2-16-16v-17.8c-11.2-2.1-21.7-5.7-30.9-8.9h0c-2.1-.7-4.2-1.4-6.2-2.1-8.4-2.8-12.9-11.9-10.1-20.2,2.8-8.3,11.9-12.9,20.2-10.1,2.5,.8,4.8,1.6,7.1,2.4h0c13.6,4.6,24.6,8.4,36.3,8.7,9.1,.3,17.9-1.7,23.7-5.3,5.1-3.2,7.9-7.3,7.8-14-.1-4.6-1.8-7.8-7.7-11.6-6.8-4.3-16.5-7.4-29-11.2l-1.6-.5h0c-11-3.3-24.3-7.3-34.8-13.7-12-7.2-22.6-18.9-22.7-37.3-.1-19.4,10.8-32.8,23.8-40.5,7.5-4.4,15.8-7.2,24.1-8.7v-17.2c0-8.8,7.2-16,16-16h0Z"/></svg></a>
          <a class="nav-button" v-if="summary.dctfwebCollection.loading || summary.dctfwebCollection.status" @click="page = 'dctfweb-collection'; scrollMenuTo('dctfweb-collection')" :class="[page === 'dctfweb-collection' ? 'active' : '']"><svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><polygon points="256 0 256 128 384 128 256 0"/><g><path d="M207.74,272.43c-7.93-2.44-17.52-3.66-28.76-3.66h-24.29v123.33h27.89c6.11,0,10.91-.16,14.38-.5,3.47-.33,7.06-1.16,10.78-2.48,3.72-1.32,6.94-3.18,9.67-5.58,12.31-10.41,18.47-28.34,18.47-53.8,0-17.93-2.71-31.36-8.12-40.28-5.41-8.92-12.09-14.61-20.02-17.04Z"/><path d="M256,160c-17.7,0-32-14.3-32-32V0H64C28.7,0,0,28.7,0,64V448c0,35.3,28.7,64,64,64H320c35.3,0,64-28.7,64-64V160h-128ZM80,64h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16s7.2-16,16-16Zm-16,80c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm206.34,215.88c-1.9,9.01-4.83,17.13-8.8,24.36-3.97,7.23-9.05,13.7-15.25,19.4-4.88,4.38-10.21,7.89-15.99,10.54-5.78,2.65-12,4.5-18.65,5.58-6.65,1.08-14.15,1.61-22.5,1.61h-47.97c-6.69,0-11.74-1.01-15.12-3.04-3.39-2.02-5.6-4.87-6.63-8.55-1.03-3.68-1.55-8.45-1.55-14.32v-133.25c0-7.93,1.78-13.68,5.33-17.23,3.55-3.55,9.3-5.33,17.23-5.33h47.97c12.48,0,23.18,1.16,32.1,3.47,8.92,2.31,17.02,6.65,24.29,13.02,18.92,16.2,28.39,40.82,28.39,73.88,0,10.91-.95,20.87-2.85,29.87Z"/></g></svg></a>
          <a class="nav-button" v-if="summary.installments.loading || summary.installments.status" @click="page = 'installments'; scrollMenuTo('installments')" :class="[page === 'installments' ? 'active' : '']"><svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><polygon points="256 0 256 128 384 128 256 0"/><path d="M256,160c-17.7,0-32-14.3-32-32V0H64C28.7,0,0,28.7,0,64V448C0,483.3,28.7,512,64,512H320c35.3,0,64-28.7,64-64V160H256Zm-116.19,237.83c0,13-10.54,23.53-23.53,23.53h-5.15c-13,0-23.53-10.54-23.53-23.53v-134.59c0-13,10.54-23.53,23.53-23.53h5.15c13,0,23.53,10.54,23.53,23.53v134.59Zm4.19-237.83H80c-8.8,0-16-7.2-16-16s7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16Zm0-64H80c-8.8,0-16-7.2-16-16s7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16Zm74.55,301.77c0,13-10.54,23.53-23.53,23.53h-5.15c-13,0-23.53-10.54-23.53-23.53v-134.59c0-13,10.54-23.53,23.53-23.53h5.15c13,0,23.53,10.54,23.53,23.53v134.59Zm78.74,.06c0,13-10.54,23.53-23.53,23.53h-5.15c-13,0-23.53-10.54-23.53-23.53v-134.59c0-13,10.54-23.53,23.53-23.53h5.15c13,0,23.53,10.54,23.53,23.53v134.59Z"/></svg></a>
          <a class="nav-button" v-if="summary.certificates.loading || summary.certificates.status" @click="page = 'certificates'; scrollMenuTo('certificates')" :class="[page === 'certificates' ? 'active' : '']"><svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><polygon points="256 0 256 128 384 128 256 0"/><path d="M256,160c-17.7,0-32-14.3-32-32V0H64C28.7,0,0,28.7,0,64V448C0,483.3,28.7,512,64,512H320c35.3,0,64-28.7,64-64V160H256ZM80,64h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16s7.2-16,16-16Zm-16,80c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm221.25,206.84c2.41,2.37,3.38,5.83,2.49,9.1-.89,3.27-3.46,5.83-6.73,6.65l-24.3,6.14,6.8,24.1c.93,3.27,0,6.76-2.37,9.14-2.37,2.37-5.87,3.3-9.14,2.37l-24.1-6.8-6.14,24.3c-.86,3.27-3.38,5.83-6.65,6.73-3.27,.89-6.73-.08-9.1-2.49l-17.49-17.96-17.49,17.96c-2.37,2.41-5.83,3.38-9.1,2.49-3.27-.89-5.83-3.46-6.65-6.73l-6.14-24.3-24.1,6.8c-3.27,.93-6.76,0-9.14-2.37-2.37-2.37-3.3-5.87-2.37-9.14l6.8-24.1-24.3-6.14c-3.27-.86-5.87-3.38-6.73-6.65-.86-3.27,.08-6.76,2.53-9.1l17.96-17.49-17.96-17.49c-2.45-2.33-3.42-5.79-2.53-9.06,.89-3.27,3.46-5.83,6.73-6.65l24.3-6.18-6.8-24.1c-.93-3.27,0-6.76,2.37-9.14,2.37-2.37,5.87-3.3,9.14-2.37l24.1,6.8,6.14-24.3c.86-3.27,3.38-5.87,6.65-6.73,3.27-.86,6.76,.08,9.1,2.53l17.49,17.96,17.49-17.96c2.33-2.45,5.79-3.42,9.06-2.53,3.27,.89,5.83,3.46,6.65,6.73l6.18,24.3,24.1-6.8c3.27-.93,6.76,0,9.14,2.37,2.37,2.37,3.3,5.87,2.37,9.14l-6.8,24.1,24.3,6.14c3.27,.86,5.83,3.38,6.73,6.65,.89,3.27-.08,6.73-2.49,9.1l-17.96,17.49,17.96,17.49Z"/></svg></a>
          <a class="nav-button" v-if="summary.mailboxes.loading || summary.mailboxes.status" @click="page = 'mailboxes'; scrollMenuTo('mailboxes')" :class="[page === 'mailboxes' ? 'active' : '']"><i class="fas fa-envelope"></i><div class="new-box"><span>9</span></div></a>
          <!--<a class="nav-button" @click="page = 'help'; scrollMenuTo('help')" :class="[page === 'help' ? 'active' : '']"><i class="fa-solid fa-circle-question"></i></a>-->
          <a class="nav-button logout" @click="doLogout()"><i class="fa-solid fa-right-from-bracket"></i></a>
        </div>
      </div>
      <div class="col-lg-12 col-content" :style="screen.width < 900 ? 'padding-left: 0 !important;' : '' ">
        <div class="content" :style="screen.width < 900 ? 'padding: 10px; height: 100% !important;' : 'padding-bottom: 10px; padding-top: 8px;'">
          <!--<h5 class="router-text">Resumo</h5>-->
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-4 pr-0 summary" v-if="screen.width > 900">
                <h4 class="font-weight-bold mb-3">Resumo</h4>
                <div class="pl-0 pr-0 card table-card" :style="'height: calc('+ screen.cardHeight + ' - 122px) !important;'">
                  <h5 class="font-weight-bold mb-2 ml-2" v-if="showResume">Principais</h5>

                  <div class="historic" v-if="summary.simplesCollection.loading || summary.simplesCollection.status" @click="page = 'simples-collection'; scrollMenuTo('simples-collection')" :style="page === 'simples-collection' ? 'background-color: var(--featured-light-03) !important' : ''">
                    <div class="container">
                      <div class="row justfy-content-between">
                        <div>
                          <div class="icon-box">
                            <i>
                              <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M64,0C28.7,0,0,28.7,0,64V448c0,35.3,28.7,64,64,64H320c35.3,0,64-28.7,64-64V160h-128c-17.7,0-32-14.3-32-32V0H64Zm192,0V128h128L256,0ZM64,80c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm0,64c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm128,72c8.8,0,16,7.2,16,16v17.3c8.5,1.2,16.7,3.1,24.1,5.1,8.5,2.3,13.6,11,11.3,19.6s-11,13.6-19.6,11.3c-11.1-3-22-5.2-32.1-5.3-8.4-.1-17.4,1.8-23.6,5.5-5.7,3.4-8.1,7.3-8.1,12.8,0,3.7,1.3,6.5,7.3,10.1,6.9,4.1,16.6,7.1,29.2,10.9l.5,.1h0c11.3,3.4,25.3,7.6,36.3,14.6,12.1,7.6,22.4,19.7,22.7,38.2,.3,19.3-9.6,33.3-22.9,41.6-7.7,4.8-16.4,7.6-25.1,9.1v17.1c0,8.8-7.2,16-16,16s-16-7.2-16-16v-17.8c-11.2-2.1-21.7-5.7-30.9-8.9h0c-2.1-.7-4.2-1.4-6.2-2.1-8.4-2.8-12.9-11.9-10.1-20.2,2.8-8.3,11.9-12.9,20.2-10.1,2.5,.8,4.8,1.6,7.1,2.4h0c13.6,4.6,24.6,8.4,36.3,8.7,9.1,.3,17.9-1.7,23.7-5.3,5.1-3.2,7.9-7.3,7.8-14-.1-4.6-1.8-7.8-7.7-11.6-6.8-4.3-16.5-7.4-29-11.2l-1.6-.5h0c-11-3.3-24.3-7.3-34.8-13.7-12-7.2-22.6-18.9-22.7-37.3-.1-19.4,10.8-32.8,23.8-40.5,7.5-4.4,15.8-7.2,24.1-8.7v-17.2c0-8.8,7.2-16,16-16h0Z"/></svg>
                            </i>
                          </div>
                        </div>
                        <div class="row">
                          <div>
                            <p class="local mb-1">DAS do Simples</p>
                            <p class="title">Pagamento do imposto mensal</p>
                            <div class="row" style="padding-left: 29px;">
                              <template v-if="summary.simplesCollection.loading || summary.simplesCollection.status === 'Não Verificado'">
                                <div class="info-status status-loading">Buscando...</div>
                              </template>
                              <template v-else-if="summary.simplesCollection.status === 'Pendências' || summary.simplesCollection.status === 'Informar Faturamento'">
                                <div class="info-status status-warning">Pendências</div>
                              </template>
                              <template v-else-if="summary.simplesCollection.status === 'Em Dia' || summary.simplesCollection.status === 'Em Aberto'">
                                <div class="info-status status-success">Em Dia</div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="historic" v-if="summary.meiCollection.loading || summary.meiCollection.status" @click="page = 'mei-collection'; scrollMenuTo('mei-collection')" :style="page === 'mei-collection' ? 'background-color: var(--featured-light-03) !important' : ''">
                    <div class="container">
                      <div class="row justfy-content-between">
                        <div>
                          <div class="icon-box">
                            <i>
                              <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M64,0C28.7,0,0,28.7,0,64V448c0,35.3,28.7,64,64,64H320c35.3,0,64-28.7,64-64V160h-128c-17.7,0-32-14.3-32-32V0H64Zm192,0V128h128L256,0ZM64,80c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm0,64c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm128,72c8.8,0,16,7.2,16,16v17.3c8.5,1.2,16.7,3.1,24.1,5.1,8.5,2.3,13.6,11,11.3,19.6s-11,13.6-19.6,11.3c-11.1-3-22-5.2-32.1-5.3-8.4-.1-17.4,1.8-23.6,5.5-5.7,3.4-8.1,7.3-8.1,12.8,0,3.7,1.3,6.5,7.3,10.1,6.9,4.1,16.6,7.1,29.2,10.9l.5,.1h0c11.3,3.4,25.3,7.6,36.3,14.6,12.1,7.6,22.4,19.7,22.7,38.2,.3,19.3-9.6,33.3-22.9,41.6-7.7,4.8-16.4,7.6-25.1,9.1v17.1c0,8.8-7.2,16-16,16s-16-7.2-16-16v-17.8c-11.2-2.1-21.7-5.7-30.9-8.9h0c-2.1-.7-4.2-1.4-6.2-2.1-8.4-2.8-12.9-11.9-10.1-20.2,2.8-8.3,11.9-12.9,20.2-10.1,2.5,.8,4.8,1.6,7.1,2.4h0c13.6,4.6,24.6,8.4,36.3,8.7,9.1,.3,17.9-1.7,23.7-5.3,5.1-3.2,7.9-7.3,7.8-14-.1-4.6-1.8-7.8-7.7-11.6-6.8-4.3-16.5-7.4-29-11.2l-1.6-.5h0c-11-3.3-24.3-7.3-34.8-13.7-12-7.2-22.6-18.9-22.7-37.3-.1-19.4,10.8-32.8,23.8-40.5,7.5-4.4,15.8-7.2,24.1-8.7v-17.2c0-8.8,7.2-16,16-16h0Z"/></svg>
                            </i>
                          </div>
                        </div>
                        <div class="row">
                          <div>
                            <p class="local mb-1">DAS do MEI</p>
                            <p class="title">Pagamento do imposto mensal</p>
                            <div class="row" style="padding-left: 29px;">
                              <template v-if="summary.meiCollection.loading || summary.meiCollection.status === 'Não Verificado'">
                                <div class="info-status status-loading">Buscando...</div>
                              </template>
                              <template v-else-if="summary.meiCollection.status === 'Pendências' || summary.meiCollection.status === 'Informar Faturamento'">
                                <div class="info-status status-warning">Pendências</div>
                              </template>
                              <template v-else-if="summary.meiCollection.status === 'Em Dia' || summary.meiCollection.status === 'Em Aberto'">
                                <div class="info-status status-success">Em Dia</div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="historic" v-if="summary.dctfwebCollection.loading || summary.dctfwebCollection.status" @click="page = 'dctfweb-collection'; scrollMenuTo('dctfweb-collection')" :style="page === 'dctfweb-collection' ? 'background-color: var(--featured-light-03) !important' : ''">
                    <div class="container">
                      <div class="row justfy-content-between">
                        <div>
                          <div class="icon-box">
                            <i>
                              <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><polygon points="256 0 256 128 384 128 256 0"/><g><path d="M207.74,272.43c-7.93-2.44-17.52-3.66-28.76-3.66h-24.29v123.33h27.89c6.11,0,10.91-.16,14.38-.5,3.47-.33,7.06-1.16,10.78-2.48,3.72-1.32,6.94-3.18,9.67-5.58,12.31-10.41,18.47-28.34,18.47-53.8,0-17.93-2.71-31.36-8.12-40.28-5.41-8.92-12.09-14.61-20.02-17.04Z"/><path d="M256,160c-17.7,0-32-14.3-32-32V0H64C28.7,0,0,28.7,0,64V448c0,35.3,28.7,64,64,64H320c35.3,0,64-28.7,64-64V160h-128ZM80,64h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16s7.2-16,16-16Zm-16,80c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm206.34,215.88c-1.9,9.01-4.83,17.13-8.8,24.36-3.97,7.23-9.05,13.7-15.25,19.4-4.88,4.38-10.21,7.89-15.99,10.54-5.78,2.65-12,4.5-18.65,5.58-6.65,1.08-14.15,1.61-22.5,1.61h-47.97c-6.69,0-11.74-1.01-15.12-3.04-3.39-2.02-5.6-4.87-6.63-8.55-1.03-3.68-1.55-8.45-1.55-14.32v-133.25c0-7.93,1.78-13.68,5.33-17.23,3.55-3.55,9.3-5.33,17.23-5.33h47.97c12.48,0,23.18,1.16,32.1,3.47,8.92,2.31,17.02,6.65,24.29,13.02,18.92,16.2,28.39,40.82,28.39,73.88,0,10.91-.95,20.87-2.85,29.87Z"/></g></svg>
                            </i>
                          </div>
                        </div>
                        <div class="row">
                          <div>
                            <p class="local mb-1">Guias de DCTFWeb</p>
                            <p class="title">Pagamento do INSS</p>
                            <div class="row" style="padding-left: 29px;">
                              <template v-if="summary.dctfwebCollection.loading || summary.dctfwebCollection.status === 'Não Verificado'">
                                <div class="info-status status-loading">Buscando...</div>
                              </template>
                              <template v-else-if="summary.dctfwebCollection.status === 'Pendências' || summary.dctfwebCollection.status === 'Informar Faturamento'">
                                <div class="info-status status-warning">Pendências</div>
                              </template>
                              <template v-else-if="summary.dctfwebCollection.status === 'Sem Movimento'">
                                <div class="info-status status-success">Sem Movimento</div>
                              </template>
                              <template v-else-if="summary.dctfwebCollection.status === 'Em Dia' || summary.dctfwebCollection.status === 'Em Aberto'">
                                <div class="info-status status-success">Em Dia</div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="historic" v-if="summary.installments.loading || summary.installments.status" @click="page = 'installments'; scrollMenuTo('installments')" :style="page === 'installments' ? 'background-color: var(--featured-light-03) !important' : ''">
                    <div class="container">
                      <div class="row justfy-content-between">
                        <div>
                          <div class="icon-box" style="transform: translate(0, 2px);">
                            <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><polygon points="256 0 256 128 384 128 256 0"/><path d="M256,160c-17.7,0-32-14.3-32-32V0H64C28.7,0,0,28.7,0,64V448C0,483.3,28.7,512,64,512H320c35.3,0,64-28.7,64-64V160H256Zm-116.19,237.83c0,13-10.54,23.53-23.53,23.53h-5.15c-13,0-23.53-10.54-23.53-23.53v-134.59c0-13,10.54-23.53,23.53-23.53h5.15c13,0,23.53,10.54,23.53,23.53v134.59Zm4.19-237.83H80c-8.8,0-16-7.2-16-16s7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16Zm0-64H80c-8.8,0-16-7.2-16-16s7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16Zm74.55,301.77c0,13-10.54,23.53-23.53,23.53h-5.15c-13,0-23.53-10.54-23.53-23.53v-134.59c0-13,10.54-23.53,23.53-23.53h5.15c13,0,23.53,10.54,23.53,23.53v134.59Zm78.74,.06c0,13-10.54,23.53-23.53,23.53h-5.15c-13,0-23.53-10.54-23.53-23.53v-134.59c0-13,10.54-23.53,23.53-23.53h5.15c13,0,23.53,10.54,23.53,23.53v134.59Z"/></svg>
                          </div>
                        </div>
                        <div class="row">
                          <div>
                            <p class="local mb-1">Parcelamentos</p>
                            <p class="title">Pagamento dos impostos parcelados</p>
                            <div class="row" style="padding-left: 29px;">
                              <template v-if="summary.installments.loading || summary.installments.status === 'Não Verificado' || summary.installments.lastProcess === 'Não Executado'">
                                <div class="info-status status-loading">Buscando...</div>
                              </template>
                              <template v-else-if="summary.installments.status === 'Pendências' || summary.installments.status === 'Informar Faturamento'">
                                <div class="info-status status-warning">Pendências</div>
                              </template>
                              <template v-else-if="summary.installments.status === 'Em Dia' || summary.installments.status === 'Em Aberto'">
                                <div class="info-status status-success">Em Dia</div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="historic" v-if="summary.certificates.loading || summary.certificates.status" @click="page = 'certificates'; scrollMenuTo('certificates')" :style="page === 'certificates' ? 'background-color: var(--featured-light-03) !important' : ''">
                    <div class="container">
                      <div class="row justfy-content-between">
                        <div>
                          <div class="icon-box" style="transform: translate(0, 2px);">
                            <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><polygon points="256 0 256 128 384 128 256 0"/><path d="M256,160c-17.7,0-32-14.3-32-32V0H64C28.7,0,0,28.7,0,64V448C0,483.3,28.7,512,64,512H320c35.3,0,64-28.7,64-64V160H256ZM80,64h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16s7.2-16,16-16Zm-16,80c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm221.25,206.84c2.41,2.37,3.38,5.83,2.49,9.1-.89,3.27-3.46,5.83-6.73,6.65l-24.3,6.14,6.8,24.1c.93,3.27,0,6.76-2.37,9.14-2.37,2.37-5.87,3.3-9.14,2.37l-24.1-6.8-6.14,24.3c-.86,3.27-3.38,5.83-6.65,6.73-3.27,.89-6.73-.08-9.1-2.49l-17.49-17.96-17.49,17.96c-2.37,2.41-5.83,3.38-9.1,2.49-3.27-.89-5.83-3.46-6.65-6.73l-6.14-24.3-24.1,6.8c-3.27,.93-6.76,0-9.14-2.37-2.37-2.37-3.3-5.87-2.37-9.14l6.8-24.1-24.3-6.14c-3.27-.86-5.87-3.38-6.73-6.65-.86-3.27,.08-6.76,2.53-9.1l17.96-17.49-17.96-17.49c-2.45-2.33-3.42-5.79-2.53-9.06,.89-3.27,3.46-5.83,6.73-6.65l24.3-6.18-6.8-24.1c-.93-3.27,0-6.76,2.37-9.14,2.37-2.37,5.87-3.3,9.14-2.37l24.1,6.8,6.14-24.3c.86-3.27,3.38-5.87,6.65-6.73,3.27-.86,6.76,.08,9.1,2.53l17.49,17.96,17.49-17.96c2.33-2.45,5.79-3.42,9.06-2.53,3.27,.89,5.83,3.46,6.65,6.73l6.18,24.3,24.1-6.8c3.27-.93,6.76,0,9.14,2.37,2.37,2.37,3.3,5.87,2.37,9.14l-6.8,24.1,24.3,6.14c3.27,.86,5.83,3.38,6.73,6.65,.89,3.27-.08,6.73-2.49,9.1l-17.96,17.49,17.96,17.49Z"/></svg>
                          </div>
                        </div>
                        <div class="row">
                          <div>
                            <p class="local mb-1">Certidões e Relatórios Fiscais</p>
                            <p class="title">Comprovação de ausência de dívidas</p>
                            <div class="row" style="padding-left: 29px;">
                              <template v-if="summary.certificates.loading || summary.certificates.status === 'Não Verificado'">
                                <div class="info-status status-loading">Buscando...</div>
                              </template>
                              <template v-else-if="summary.certificates.status === 'Pendências' || summary.certificates.status === 'Informar Faturamento'">
                                <div class="info-status status-warning">Pendências</div>
                              </template>
                              <template v-else-if="summary.certificates.status === 'Em Dia' || summary.certificates.status === 'Em Aberto'">
                                <div class="info-status status-success">Em Dia</div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="historic" v-if="summary.mailboxes.loading || summary.mailboxes.status" @click="page = 'mailboxes'; scrollMenuTo('mailboxes')" :style="page === 'mailboxes' ? 'background-color: var(--featured-light-03) !important' : ''">
                    <div class="container">
                      <div class="row justfy-content-between">
                        <div>
                          <div class="icon-box">
                            <i class="fas fa-envelope" style="font-size: 26px"></i>
                          </div>
                        </div>
                        <div class="row">
                          <div>
                            <p class="local mb-1">Caixas Postais</p>
                            <p class="title">Mensagens da Receita Federal</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <h5 class="font-weight-bold mb-2 ml-2 mt-4" v-if="inboxes">Recentes</h5>
                  <!--<div class="new-box"><span>1</span></div>-->
                  <table class="m-0">
                    <tbody style="background-color: white;">
                      <tr
                        :key="i"
                        v-for="(inbox, key, i) in filteredInboxes"
                      >
                        <td v-if="!inbox.customer">
                          <div class="historic" @click="page = 'inbox'; scrollMenuTo('inbox'); changeInitialPage()">
                            <div class="container">
                              <div class="row justfy-content-between">
                                <div>
                                  <div class="icon-box">
                                    <i class="fa-solid fa-file-excel"></i>
                                  </div>
                                </div>
                                <div class="row">
                                  <div>
                                    <p class="local mb-1">{{customersArea.name}}</p>
                                    <p class="title">{{inbox.title.substring(0, 28)}}</p>
                                    <div class="row" style="padding-left: 29px;">
                                      <div class="info-status status-success" v-if="verifyStatusInbox(inbox)">Em Dia</div>
                                      <div class="info-status status-warning" v-else>Pendência</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row"><img class="img-fluid" src="../../assets/images/svg/sh-logo-login-gray.svg"></div>
              </div>
              <div class="col-lg-8 pr-0" :style="screen.width < 900 ? 'padding: 0px;' : ''">
                <div class="pl-0 pr-0 horizontal-scrollable container-flex">
                  <div class="btn btn-arrow to-left"  @click="scrollToElement('left')" v-if="screen.width > 900 && menuScrollPosition > 15"><i class="fa-solid fa-chevron-left"></i></div>
                  <div class="btn btn-arrow to-right" @click="scrollToElement('right')" v-if="screen.width > 900 && showRightArrow"><i class="fa-solid fa-chevron-right"></i></div>
                  <div class="row flex-nowrap">
                    <button id="btn-summary" class="btn btn-primary" v-if="screen.width < 900" @click="page = 'summary'" :class="[page !== 'summary' ? 'disabled' : '']"><i class="fa-solid fa-square-poll-vertical"></i>Resumo</button>
                    <button id="btn-chat" class="btn btn-primary" @click="page = 'chat'; scrollMenuTo('chat'); changeInitialPage()" :class="[page !== 'chat' ? 'disabled' : '']"><i class="fa-solid fa-message"></i>Chat</button>
                    <button id="btn-inbox" class="btn btn-primary" @click="page = 'inbox'; scrollMenuTo('inbox'); changeInitialPage()" :class="[page !== 'inbox' ? 'disabled' : '']"><i class="fa-solid fa-inbox"></i>Guias</button>
                    <button id="btn-simples-collection" class="btn btn-primary" v-if="summary.simplesCollection.loading || summary.simplesCollection.status" @click="page = 'simples-collection'; scrollMenuTo('simples-collection')" :class="[page !== 'simples-collection' ? 'disabled' : '']"><i><svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M64,0C28.7,0,0,28.7,0,64V448c0,35.3,28.7,64,64,64H320c35.3,0,64-28.7,64-64V160h-128c-17.7,0-32-14.3-32-32V0H64Zm192,0V128h128L256,0ZM64,80c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm0,64c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm128,72c8.8,0,16,7.2,16,16v17.3c8.5,1.2,16.7,3.1,24.1,5.1,8.5,2.3,13.6,11,11.3,19.6s-11,13.6-19.6,11.3c-11.1-3-22-5.2-32.1-5.3-8.4-.1-17.4,1.8-23.6,5.5-5.7,3.4-8.1,7.3-8.1,12.8,0,3.7,1.3,6.5,7.3,10.1,6.9,4.1,16.6,7.1,29.2,10.9l.5,.1h0c11.3,3.4,25.3,7.6,36.3,14.6,12.1,7.6,22.4,19.7,22.7,38.2,.3,19.3-9.6,33.3-22.9,41.6-7.7,4.8-16.4,7.6-25.1,9.1v17.1c0,8.8-7.2,16-16,16s-16-7.2-16-16v-17.8c-11.2-2.1-21.7-5.7-30.9-8.9h0c-2.1-.7-4.2-1.4-6.2-2.1-8.4-2.8-12.9-11.9-10.1-20.2,2.8-8.3,11.9-12.9,20.2-10.1,2.5,.8,4.8,1.6,7.1,2.4h0c13.6,4.6,24.6,8.4,36.3,8.7,9.1,.3,17.9-1.7,23.7-5.3,5.1-3.2,7.9-7.3,7.8-14-.1-4.6-1.8-7.8-7.7-11.6-6.8-4.3-16.5-7.4-29-11.2l-1.6-.5h0c-11-3.3-24.3-7.3-34.8-13.7-12-7.2-22.6-18.9-22.7-37.3-.1-19.4,10.8-32.8,23.8-40.5,7.5-4.4,15.8-7.2,24.1-8.7v-17.2c0-8.8,7.2-16,16-16h0Z"/></svg></i>DAS do Simples</button>
                    <button id="btn-mei-collection" class="btn btn-primary" v-if="summary.meiCollection.loading || summary.meiCollection.status" @click="page = 'mei-collection'; scrollMenuTo('mei-collection')" :class="[page !== 'mei-collection' ? 'disabled' : '']"><i><svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M64,0C28.7,0,0,28.7,0,64V448c0,35.3,28.7,64,64,64H320c35.3,0,64-28.7,64-64V160h-128c-17.7,0-32-14.3-32-32V0H64Zm192,0V128h128L256,0ZM64,80c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm0,64c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm128,72c8.8,0,16,7.2,16,16v17.3c8.5,1.2,16.7,3.1,24.1,5.1,8.5,2.3,13.6,11,11.3,19.6s-11,13.6-19.6,11.3c-11.1-3-22-5.2-32.1-5.3-8.4-.1-17.4,1.8-23.6,5.5-5.7,3.4-8.1,7.3-8.1,12.8,0,3.7,1.3,6.5,7.3,10.1,6.9,4.1,16.6,7.1,29.2,10.9l.5,.1h0c11.3,3.4,25.3,7.6,36.3,14.6,12.1,7.6,22.4,19.7,22.7,38.2,.3,19.3-9.6,33.3-22.9,41.6-7.7,4.8-16.4,7.6-25.1,9.1v17.1c0,8.8-7.2,16-16,16s-16-7.2-16-16v-17.8c-11.2-2.1-21.7-5.7-30.9-8.9h0c-2.1-.7-4.2-1.4-6.2-2.1-8.4-2.8-12.9-11.9-10.1-20.2,2.8-8.3,11.9-12.9,20.2-10.1,2.5,.8,4.8,1.6,7.1,2.4h0c13.6,4.6,24.6,8.4,36.3,8.7,9.1,.3,17.9-1.7,23.7-5.3,5.1-3.2,7.9-7.3,7.8-14-.1-4.6-1.8-7.8-7.7-11.6-6.8-4.3-16.5-7.4-29-11.2l-1.6-.5h0c-11-3.3-24.3-7.3-34.8-13.7-12-7.2-22.6-18.9-22.7-37.3-.1-19.4,10.8-32.8,23.8-40.5,7.5-4.4,15.8-7.2,24.1-8.7v-17.2c0-8.8,7.2-16,16-16h0Z"/></svg></i>DAS do MEI</button>
                    <button id="btn-dctfweb-collection" class="btn btn-primary" v-if="summary.dctfwebCollection.loading || summary.dctfwebCollection.status" @click="page = 'dctfweb-collection'; scrollMenuTo('dctfweb-collection')" :class="[page !== 'dctfweb-collection' ? 'disabled' : '']"><i><svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M64,0C28.7,0,0,28.7,0,64V448c0,35.3,28.7,64,64,64H320c35.3,0,64-28.7,64-64V160h-128c-17.7,0-32-14.3-32-32V0H64Zm192,0V128h128L256,0ZM64,80c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm0,64c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm128,72c8.8,0,16,7.2,16,16v17.3c8.5,1.2,16.7,3.1,24.1,5.1,8.5,2.3,13.6,11,11.3,19.6s-11,13.6-19.6,11.3c-11.1-3-22-5.2-32.1-5.3-8.4-.1-17.4,1.8-23.6,5.5-5.7,3.4-8.1,7.3-8.1,12.8,0,3.7,1.3,6.5,7.3,10.1,6.9,4.1,16.6,7.1,29.2,10.9l.5,.1h0c11.3,3.4,25.3,7.6,36.3,14.6,12.1,7.6,22.4,19.7,22.7,38.2,.3,19.3-9.6,33.3-22.9,41.6-7.7,4.8-16.4,7.6-25.1,9.1v17.1c0,8.8-7.2,16-16,16s-16-7.2-16-16v-17.8c-11.2-2.1-21.7-5.7-30.9-8.9h0c-2.1-.7-4.2-1.4-6.2-2.1-8.4-2.8-12.9-11.9-10.1-20.2,2.8-8.3,11.9-12.9,20.2-10.1,2.5,.8,4.8,1.6,7.1,2.4h0c13.6,4.6,24.6,8.4,36.3,8.7,9.1,.3,17.9-1.7,23.7-5.3,5.1-3.2,7.9-7.3,7.8-14-.1-4.6-1.8-7.8-7.7-11.6-6.8-4.3-16.5-7.4-29-11.2l-1.6-.5h0c-11-3.3-24.3-7.3-34.8-13.7-12-7.2-22.6-18.9-22.7-37.3-.1-19.4,10.8-32.8,23.8-40.5,7.5-4.4,15.8-7.2,24.1-8.7v-17.2c0-8.8,7.2-16,16-16h0Z"/></svg></i>DCTFWeb</button>
                    <button id="btn-installments" class="btn btn-primary" v-if="summary.installments.loading || summary.installments.status" @click="page = 'installments'; scrollMenuTo('installments')" :class="[page !== 'installments' ? 'disabled' : '']"><i><svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M64,0C28.7,0,0,28.7,0,64V448c0,35.3,28.7,64,64,64H320c35.3,0,64-28.7,64-64V160h-128c-17.7,0-32-14.3-32-32V0H64Zm192,0V128h128L256,0ZM64,80c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm0,64c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm128,72c8.8,0,16,7.2,16,16v17.3c8.5,1.2,16.7,3.1,24.1,5.1,8.5,2.3,13.6,11,11.3,19.6s-11,13.6-19.6,11.3c-11.1-3-22-5.2-32.1-5.3-8.4-.1-17.4,1.8-23.6,5.5-5.7,3.4-8.1,7.3-8.1,12.8,0,3.7,1.3,6.5,7.3,10.1,6.9,4.1,16.6,7.1,29.2,10.9l.5,.1h0c11.3,3.4,25.3,7.6,36.3,14.6,12.1,7.6,22.4,19.7,22.7,38.2,.3,19.3-9.6,33.3-22.9,41.6-7.7,4.8-16.4,7.6-25.1,9.1v17.1c0,8.8-7.2,16-16,16s-16-7.2-16-16v-17.8c-11.2-2.1-21.7-5.7-30.9-8.9h0c-2.1-.7-4.2-1.4-6.2-2.1-8.4-2.8-12.9-11.9-10.1-20.2,2.8-8.3,11.9-12.9,20.2-10.1,2.5,.8,4.8,1.6,7.1,2.4h0c13.6,4.6,24.6,8.4,36.3,8.7,9.1,.3,17.9-1.7,23.7-5.3,5.1-3.2,7.9-7.3,7.8-14-.1-4.6-1.8-7.8-7.7-11.6-6.8-4.3-16.5-7.4-29-11.2l-1.6-.5h0c-11-3.3-24.3-7.3-34.8-13.7-12-7.2-22.6-18.9-22.7-37.3-.1-19.4,10.8-32.8,23.8-40.5,7.5-4.4,15.8-7.2,24.1-8.7v-17.2c0-8.8,7.2-16,16-16h0Z"/></svg></i>Parcelamentos</button>
                    <button id="btn-certificates" class="btn btn-primary" v-if="summary.certificates.loading || summary.certificates.status" @click="page = 'certificates'; scrollMenuTo('certificates')" :class="[page !== 'certificates' ? 'disabled' : '']"><i><svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M64,0C28.7,0,0,28.7,0,64V448c0,35.3,28.7,64,64,64H320c35.3,0,64-28.7,64-64V160h-128c-17.7,0-32-14.3-32-32V0H64Zm192,0V128h128L256,0ZM64,80c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm0,64c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm128,72c8.8,0,16,7.2,16,16v17.3c8.5,1.2,16.7,3.1,24.1,5.1,8.5,2.3,13.6,11,11.3,19.6s-11,13.6-19.6,11.3c-11.1-3-22-5.2-32.1-5.3-8.4-.1-17.4,1.8-23.6,5.5-5.7,3.4-8.1,7.3-8.1,12.8,0,3.7,1.3,6.5,7.3,10.1,6.9,4.1,16.6,7.1,29.2,10.9l.5,.1h0c11.3,3.4,25.3,7.6,36.3,14.6,12.1,7.6,22.4,19.7,22.7,38.2,.3,19.3-9.6,33.3-22.9,41.6-7.7,4.8-16.4,7.6-25.1,9.1v17.1c0,8.8-7.2,16-16,16s-16-7.2-16-16v-17.8c-11.2-2.1-21.7-5.7-30.9-8.9h0c-2.1-.7-4.2-1.4-6.2-2.1-8.4-2.8-12.9-11.9-10.1-20.2,2.8-8.3,11.9-12.9,20.2-10.1,2.5,.8,4.8,1.6,7.1,2.4h0c13.6,4.6,24.6,8.4,36.3,8.7,9.1,.3,17.9-1.7,23.7-5.3,5.1-3.2,7.9-7.3,7.8-14-.1-4.6-1.8-7.8-7.7-11.6-6.8-4.3-16.5-7.4-29-11.2l-1.6-.5h0c-11-3.3-24.3-7.3-34.8-13.7-12-7.2-22.6-18.9-22.7-37.3-.1-19.4,10.8-32.8,23.8-40.5,7.5-4.4,15.8-7.2,24.1-8.7v-17.2c0-8.8,7.2-16,16-16h0Z"/></svg></i>Certidões e Relatórios Fiscais</button>
                    <button id="btn-mailboxes" class="btn btn-primary" v-if="summary.mailboxes.loading || summary.mailboxes.status" @click="page = 'mailboxes'; scrollMenuTo('mailboxes')" :class="[page !== 'mailboxes' ? 'disabled' : '']"><i class="fas fa-envelope"></i>Caixas Postais</button>
                    <!--<button id="btn-help" class="btn btn-primary" @click="page = 'help'; scrollMenuTo('help')" :class="[page !== 'help' ? 'disabled' : '']"><i class="fa-solid fa-circle-question"></i>Ajuda</button>-->
                  </div>
                </div>
                <div v-if="page !== 'summary'">
                  <chat :name="name" :permissions="permissions" :customersArea="customersArea" :initialPage="page" v-if="page === 'chat' || page === 'inbox'" :style="page === 'chat' || page === 'inbox' ? 'opacity: 1; transition: all .5s;' : 'opacity: 0; transition: all .5s;'"/>
                  <dctfweb-collection :name="name" :permissions="permissions" :customersArea="customersArea" v-if="page === 'dctfweb-collection'" :style="page === 'dctfweb-collection' ? 'opacity: 1; transition: all .5s;' : 'opacity: 0; transition: all .5s;'"/>
                  <mei-collection :name="name" :permissions="permissions" :customersArea="customersArea" v-if="page === 'mei-collection'" :style="page === 'mei-collection' ? 'opacity: 1; transition: all .5s;' : 'opacity: 0; transition: all .5s;'"/>
                  <simples-collection :name="name" :permissions="permissions" :customersArea="customersArea" v-if="page === 'simples-collection'" :style="page === 'simples-collection' ? 'opacity: 1; transition: all .5s;' : 'opacity: 0; transition: all .5s;'"/>
                  <mail-boxes :name="name" :permissions="permissions" :customersArea="customersArea" v-if="page === 'mailboxes'" :style="page === 'mailboxes' ? 'opacity: 1; transition: all .5s;' : 'opacity: 0; transition: all .5s;'"/>
                  <certificates :name="name" :permissions="permissions" :customersArea="customersArea" v-if="page === 'certificates'" :style="page === 'certificates' ? 'opacity: 1; transition: all .5s;' : 'opacity: 0; transition: all .5s;'"/>
                  <installments :name="name" :permissions="permissions" :customersArea="customersArea" v-if="page === 'installments'" :style="page === 'installments' ? 'opacity: 1; transition: all .5s;' : 'opacity: 0; transition: all .5s;'"/>
                </div>
                <div v-else>
                  <div class="summary" :style="'height: calc('+ this.$screen.cardHeight + ' - 85px) !important;'">
                    <h4 class="font-weight-bold mb-3">Resumo</h4>
                    <div class="pl-0 pr-0 card table-card" :style="'height: calc('+ screen.cardHeight + ' - 200px) !important;'">
                      <h5 class="font-weight-bold mb-2 ml-2" v-if="showResume">Principais</h5>

                      <div class="historic" v-if="summary.simplesCollection.loading || summary.simplesCollection.status" @click="page = 'simples-collection'; scrollMenuTo('simples-collection')" :style="page === 'simples-collection' ? 'background-color: var(--featured-light-03) !important' : ''">
                        <div class="container">
                          <div class="row justfy-content-between">
                            <div>
                              <div class="icon-box">
                                <i>
                                  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M64,0C28.7,0,0,28.7,0,64V448c0,35.3,28.7,64,64,64H320c35.3,0,64-28.7,64-64V160h-128c-17.7,0-32-14.3-32-32V0H64Zm192,0V128h128L256,0ZM64,80c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm0,64c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm128,72c8.8,0,16,7.2,16,16v17.3c8.5,1.2,16.7,3.1,24.1,5.1,8.5,2.3,13.6,11,11.3,19.6s-11,13.6-19.6,11.3c-11.1-3-22-5.2-32.1-5.3-8.4-.1-17.4,1.8-23.6,5.5-5.7,3.4-8.1,7.3-8.1,12.8,0,3.7,1.3,6.5,7.3,10.1,6.9,4.1,16.6,7.1,29.2,10.9l.5,.1h0c11.3,3.4,25.3,7.6,36.3,14.6,12.1,7.6,22.4,19.7,22.7,38.2,.3,19.3-9.6,33.3-22.9,41.6-7.7,4.8-16.4,7.6-25.1,9.1v17.1c0,8.8-7.2,16-16,16s-16-7.2-16-16v-17.8c-11.2-2.1-21.7-5.7-30.9-8.9h0c-2.1-.7-4.2-1.4-6.2-2.1-8.4-2.8-12.9-11.9-10.1-20.2,2.8-8.3,11.9-12.9,20.2-10.1,2.5,.8,4.8,1.6,7.1,2.4h0c13.6,4.6,24.6,8.4,36.3,8.7,9.1,.3,17.9-1.7,23.7-5.3,5.1-3.2,7.9-7.3,7.8-14-.1-4.6-1.8-7.8-7.7-11.6-6.8-4.3-16.5-7.4-29-11.2l-1.6-.5h0c-11-3.3-24.3-7.3-34.8-13.7-12-7.2-22.6-18.9-22.7-37.3-.1-19.4,10.8-32.8,23.8-40.5,7.5-4.4,15.8-7.2,24.1-8.7v-17.2c0-8.8,7.2-16,16-16h0Z"/></svg>
                                </i>
                              </div>
                            </div>
                            <div class="row">
                              <div>
                                <p class="local mb-1">DAS do Simples</p>
                                <p class="title">Pagamento do imposto mensal</p>
                                <div class="row" style="padding-left: 29px;">
                                  <template v-if="summary.simplesCollection.loading || summary.simplesCollection.status === 'Não Verificado'">
                                    <div class="info-status status-loading">Buscando...</div>
                                  </template>
                                  <template v-else-if="summary.simplesCollection.status === 'Pendências' || summary.simplesCollection.status === 'Informar Faturamento'">
                                    <div class="info-status status-warning">Pendências</div>
                                  </template>
                                  <template v-else-if="summary.simplesCollection.status === 'Em Dia' || summary.simplesCollection.status === 'Em Aberto'">
                                    <div class="info-status status-success">Em Dia</div>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="historic" v-if="summary.meiCollection.loading || summary.meiCollection.status" @click="page = 'mei-collection'; scrollMenuTo('mei-collection')" :style="page === 'mei-collection' ? 'background-color: var(--featured-light-03) !important' : ''">
                        <div class="container">
                          <div class="row justfy-content-between">
                            <div>
                              <div class="icon-box">
                                <i>
                                  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M64,0C28.7,0,0,28.7,0,64V448c0,35.3,28.7,64,64,64H320c35.3,0,64-28.7,64-64V160h-128c-17.7,0-32-14.3-32-32V0H64Zm192,0V128h128L256,0ZM64,80c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm0,64c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm128,72c8.8,0,16,7.2,16,16v17.3c8.5,1.2,16.7,3.1,24.1,5.1,8.5,2.3,13.6,11,11.3,19.6s-11,13.6-19.6,11.3c-11.1-3-22-5.2-32.1-5.3-8.4-.1-17.4,1.8-23.6,5.5-5.7,3.4-8.1,7.3-8.1,12.8,0,3.7,1.3,6.5,7.3,10.1,6.9,4.1,16.6,7.1,29.2,10.9l.5,.1h0c11.3,3.4,25.3,7.6,36.3,14.6,12.1,7.6,22.4,19.7,22.7,38.2,.3,19.3-9.6,33.3-22.9,41.6-7.7,4.8-16.4,7.6-25.1,9.1v17.1c0,8.8-7.2,16-16,16s-16-7.2-16-16v-17.8c-11.2-2.1-21.7-5.7-30.9-8.9h0c-2.1-.7-4.2-1.4-6.2-2.1-8.4-2.8-12.9-11.9-10.1-20.2,2.8-8.3,11.9-12.9,20.2-10.1,2.5,.8,4.8,1.6,7.1,2.4h0c13.6,4.6,24.6,8.4,36.3,8.7,9.1,.3,17.9-1.7,23.7-5.3,5.1-3.2,7.9-7.3,7.8-14-.1-4.6-1.8-7.8-7.7-11.6-6.8-4.3-16.5-7.4-29-11.2l-1.6-.5h0c-11-3.3-24.3-7.3-34.8-13.7-12-7.2-22.6-18.9-22.7-37.3-.1-19.4,10.8-32.8,23.8-40.5,7.5-4.4,15.8-7.2,24.1-8.7v-17.2c0-8.8,7.2-16,16-16h0Z"/></svg>
                                </i>
                              </div>
                            </div>
                            <div class="row">
                              <div>
                                <p class="local mb-1">DAS do MEI</p>
                                <p class="title">Pagamento do imposto mensal</p>
                                <div class="row" style="padding-left: 29px;">
                                  <template v-if="summary.meiCollection.loading || summary.meiCollection.status === 'Não Verificado'">
                                    <div class="info-status status-loading">Buscando...</div>
                                  </template>
                                  <template v-else-if="summary.meiCollection.status === 'Pendências' || summary.meiCollection.status === 'Informar Faturamento'">
                                    <div class="info-status status-warning">Pendências</div>
                                  </template>
                                  <template v-else-if="summary.meiCollection.status === 'Em Dia' || summary.meiCollection.status === 'Em Aberto'">
                                    <div class="info-status status-success">Em Dia</div>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="historic" v-if="summary.dctfwebCollection.loading || summary.dctfwebCollection.status" @click="page = 'dctfweb-collection'; scrollMenuTo('dctfweb-collection')" :style="page === 'dctfweb-collection' ? 'background-color: var(--featured-light-03) !important' : ''">
                        <div class="container">
                          <div class="row justfy-content-between">
                            <div>
                              <div class="icon-box">
                                <i>
                                  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><polygon points="256 0 256 128 384 128 256 0"/><g><path d="M207.74,272.43c-7.93-2.44-17.52-3.66-28.76-3.66h-24.29v123.33h27.89c6.11,0,10.91-.16,14.38-.5,3.47-.33,7.06-1.16,10.78-2.48,3.72-1.32,6.94-3.18,9.67-5.58,12.31-10.41,18.47-28.34,18.47-53.8,0-17.93-2.71-31.36-8.12-40.28-5.41-8.92-12.09-14.61-20.02-17.04Z"/><path d="M256,160c-17.7,0-32-14.3-32-32V0H64C28.7,0,0,28.7,0,64V448c0,35.3,28.7,64,64,64H320c35.3,0,64-28.7,64-64V160h-128ZM80,64h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16s7.2-16,16-16Zm-16,80c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm206.34,215.88c-1.9,9.01-4.83,17.13-8.8,24.36-3.97,7.23-9.05,13.7-15.25,19.4-4.88,4.38-10.21,7.89-15.99,10.54-5.78,2.65-12,4.5-18.65,5.58-6.65,1.08-14.15,1.61-22.5,1.61h-47.97c-6.69,0-11.74-1.01-15.12-3.04-3.39-2.02-5.6-4.87-6.63-8.55-1.03-3.68-1.55-8.45-1.55-14.32v-133.25c0-7.93,1.78-13.68,5.33-17.23,3.55-3.55,9.3-5.33,17.23-5.33h47.97c12.48,0,23.18,1.16,32.1,3.47,8.92,2.31,17.02,6.65,24.29,13.02,18.92,16.2,28.39,40.82,28.39,73.88,0,10.91-.95,20.87-2.85,29.87Z"/></g></svg>
                                </i>
                              </div>
                            </div>
                            <div class="row">
                              <div>
                                <p class="local mb-1">Guias de DCTFWeb</p>
                                <p class="title">Pagamento do INSS</p>
                                <div class="row" style="padding-left: 29px;">
                                  <template v-if="summary.dctfwebCollection.loading || summary.dctfwebCollection.status === 'Não Verificado'">
                                    <div class="info-status status-loading">Buscando...</div>
                                  </template>
                                  <template v-else-if="summary.dctfwebCollection.status === 'Pendências' || summary.dctfwebCollection.status === 'Informar Faturamento'">
                                    <div class="info-status status-warning">Pendências</div>
                                  </template>
                                  <template v-else-if="summary.dctfwebCollection.status === 'Sem Movimento'">
                                    <div class="info-status status-success">Sem Movimento</div>
                                  </template>
                                  <template v-else-if="summary.dctfwebCollection.status === 'Em Dia' || summary.dctfwebCollection.status === 'Em Aberto'">
                                    <div class="info-status status-success">Em Dia</div>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="historic" v-if="summary.installments.loading || summary.installments.status" @click="page = 'installments'; scrollMenuTo('installments')" :style="page === 'installments' ? 'background-color: var(--featured-light-03) !important' : ''">
                        <div class="container">
                          <div class="row justfy-content-between">
                            <div>
                              <div class="icon-box" style="transform: translate(0, 2px);">
                                <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><polygon points="256 0 256 128 384 128 256 0"/><path d="M256,160c-17.7,0-32-14.3-32-32V0H64C28.7,0,0,28.7,0,64V448C0,483.3,28.7,512,64,512H320c35.3,0,64-28.7,64-64V160H256Zm-116.19,237.83c0,13-10.54,23.53-23.53,23.53h-5.15c-13,0-23.53-10.54-23.53-23.53v-134.59c0-13,10.54-23.53,23.53-23.53h5.15c13,0,23.53,10.54,23.53,23.53v134.59Zm4.19-237.83H80c-8.8,0-16-7.2-16-16s7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16Zm0-64H80c-8.8,0-16-7.2-16-16s7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16Zm74.55,301.77c0,13-10.54,23.53-23.53,23.53h-5.15c-13,0-23.53-10.54-23.53-23.53v-134.59c0-13,10.54-23.53,23.53-23.53h5.15c13,0,23.53,10.54,23.53,23.53v134.59Zm78.74,.06c0,13-10.54,23.53-23.53,23.53h-5.15c-13,0-23.53-10.54-23.53-23.53v-134.59c0-13,10.54-23.53,23.53-23.53h5.15c13,0,23.53,10.54,23.53,23.53v134.59Z"/></svg>
                              </div>
                            </div>
                            <div class="row">
                              <div>
                                <p class="local mb-1">Parcelamentos</p>
                                <p class="title">Pagamento dos impostos parcelados</p>
                                <div class="row" style="padding-left: 29px;">
                                  <template v-if="summary.installments.loading || summary.installments.status === 'Não Verificado'  || summary.installments.lastProcess === 'Não Executado'">
                                    <div class="info-status status-loading">Buscando...</div>
                                  </template>
                                  <template v-else-if="summary.installments.status === 'Pendências' || summary.installments.status === 'Informar Faturamento'">
                                    <div class="info-status status-warning">Pendências</div>
                                  </template>
                                  <template v-else-if="summary.installments.status === 'Em Dia' || summary.installments.status === 'Em Aberto'">
                                    <div class="info-status status-success">Em Dia</div>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="historic" v-if="summary.certificates.loading || summary.certificates.status" @click="page = 'certificates'; scrollMenuTo('certificates')" :style="page === 'certificates' ? 'background-color: var(--featured-light-03) !important' : ''">
                        <div class="container">
                          <div class="row justfy-content-between">
                            <div>
                              <div class="icon-box" style="transform: translate(0, 2px);">
                                <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><polygon points="256 0 256 128 384 128 256 0"/><path d="M256,160c-17.7,0-32-14.3-32-32V0H64C28.7,0,0,28.7,0,64V448C0,483.3,28.7,512,64,512H320c35.3,0,64-28.7,64-64V160H256ZM80,64h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16s7.2-16,16-16Zm-16,80c0-8.8,7.2-16,16-16h64c8.8,0,16,7.2,16,16s-7.2,16-16,16H80c-8.8,0-16-7.2-16-16Zm221.25,206.84c2.41,2.37,3.38,5.83,2.49,9.1-.89,3.27-3.46,5.83-6.73,6.65l-24.3,6.14,6.8,24.1c.93,3.27,0,6.76-2.37,9.14-2.37,2.37-5.87,3.3-9.14,2.37l-24.1-6.8-6.14,24.3c-.86,3.27-3.38,5.83-6.65,6.73-3.27,.89-6.73-.08-9.1-2.49l-17.49-17.96-17.49,17.96c-2.37,2.41-5.83,3.38-9.1,2.49-3.27-.89-5.83-3.46-6.65-6.73l-6.14-24.3-24.1,6.8c-3.27,.93-6.76,0-9.14-2.37-2.37-2.37-3.3-5.87-2.37-9.14l6.8-24.1-24.3-6.14c-3.27-.86-5.87-3.38-6.73-6.65-.86-3.27,.08-6.76,2.53-9.1l17.96-17.49-17.96-17.49c-2.45-2.33-3.42-5.79-2.53-9.06,.89-3.27,3.46-5.83,6.73-6.65l24.3-6.18-6.8-24.1c-.93-3.27,0-6.76,2.37-9.14,2.37-2.37,5.87-3.3,9.14-2.37l24.1,6.8,6.14-24.3c.86-3.27,3.38-5.87,6.65-6.73,3.27-.86,6.76,.08,9.1,2.53l17.49,17.96,17.49-17.96c2.33-2.45,5.79-3.42,9.06-2.53,3.27,.89,5.83,3.46,6.65,6.73l6.18,24.3,24.1-6.8c3.27-.93,6.76,0,9.14,2.37,2.37,2.37,3.3,5.87,2.37,9.14l-6.8,24.1,24.3,6.14c3.27,.86,5.83,3.38,6.73,6.65,.89,3.27-.08,6.73-2.49,9.1l-17.96,17.49,17.96,17.49Z"/></svg>
                              </div>
                            </div>
                            <div class="row">
                              <div>
                                <p class="local mb-1">Certidões e Relatórios Fiscais</p>
                                <p class="title">Comprovação de ausência de dívidas</p>
                                <div class="row" style="padding-left: 29px;">
                                  <template v-if="summary.certificates.loading || summary.certificates.status === 'Não Verificado'">
                                    <div class="info-status status-loading">Buscando...</div>
                                  </template>
                                  <template v-else-if="summary.certificates.status === 'Pendências' || summary.certificates.status === 'Informar Faturamento'">
                                    <div class="info-status status-warning">Pendências</div>
                                  </template>
                                  <template v-else-if="summary.certificates.status === 'Em Dia' || summary.certificates.status === 'Em Aberto'">
                                    <div class="info-status status-success">Em Dia</div>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="historic" v-if="summary.mailboxes.loading || summary.mailboxes.status" @click="page = 'mailboxes'; scrollMenuTo('mailboxes')" :style="page === 'mailboxes' ? 'background-color: var(--featured-light-03) !important' : ''">
                        <div class="container">
                          <div class="row justfy-content-between">
                            <div>
                              <div class="icon-box">
                                <i class="fas fa-envelope" style="font-size: 26px"></i>
                              </div>
                            </div>
                            <div class="row">
                              <div>
                                <p class="local mb-1">Caixas Postais</p>
                                <p class="title">Mensagens da Receita Federal</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <h5 class="font-weight-bold mb-2 ml-2 mt-4" v-if="inboxes">Recentes</h5>
                      <!--<div class="new-box"><span>1</span></div>-->
                      <table class="m-0">
                        <tbody style="background-color: white;">
                          <tr
                            :key="i"
                            v-for="(inbox, key, i) in filteredInboxes"
                          >
                            <td>
                              <div class="historic" @click="page = 'inbox'; scrollMenuTo('inbox'); changeInitialPage()">
                                <div class="container">
                                  <div class="row justfy-content-between">
                                    <div>
                                      <div class="icon-box">
                                        <i class="fa-solid fa-file-excel"></i>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div>
                                        <p class="local mb-1">Outras Guias</p>
                                        <p class="title">{{inbox.title.substring(0, 28)}}</p>
                                        <div class="row" style="padding-left: 29px;">
                                          <div class="info-status status-success" v-if="verifyStatusInbox(inbox)">Em Dia</div>
                                          <div class="info-status status-warning" v-else>Pendência</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="row"><img class="img-fluid" src="../../assets/images/svg/sh-logo-login-gray.svg"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Chat from './Chat'
import DctfwebCollection from './collections/dctfweb/DctfwebCollection'
import MeiCollection from './collections/mei/MeiCollection'
import SimplesCollection from './collections/simples/SimplesCollection'
import MailBoxes from './mailboxes/MailBoxes'
import Certificates from './certificates/Certificates'
import Installments from './installments/Installments'

export default {
  name: 'home',
  components: {
    Chat,
    DctfwebCollection,
    MeiCollection,
    SimplesCollection,
    MailBoxes,
    Certificates,
    Installments
  },
  data: () => {
    return {
      screen: {
        height: 0,
        width: 0,
        cardheight: 0
      },
      customersArea: {},
      page: 'chat',
      menuScrollPosition: 0,
      showRightArrow: true,
      summary: {
        installments: { loading: null, status: null, lastProcess: null },
        simplesCollection: { loading: null, status: null, lastProcess: null },
        meiCollection: { loading: null, status: null, lastProcess: null },
        dctfwebCollection: { loading: null, status: null, lastProcess: null },
        mailboxes: { loading: null, status: null, lastProcess: null },
        certificates: { loading: null, status: null, lastProcess: null }
      },
      showResume: false,
      dtLastSee: 0,
      inboxes: null,
      name: '',
      permissions: {
        online: false,
        reculculos: false,
        dctfwebCollection: false,
        simplesCollection: false,
        meiCollection: false,
        certificates: false,
        installments: false,
        mailboxes: false
      }
    }
  },

  async mounted () {
    setInterval(this.verifyTab, 1000)
    await this.getPermissions()
    this.$root.$emit('Spinner::show')
    this.$root.$on('Do::Logout', () => this.doLogout())
    window.addEventListener('resize', this.onResize)
    window.addEventListener('click', this.onLastSee, true)
    window.addEventListener('mousemove', this.onLastSee, true)
    await this.getCustomerName()
    await this.getVisualSettings()
    await this.getInboxes()
    await this.onResize()
    // await this.getInstallmentSummary()
    // await this.getMeiCollectionSummary()
    // await this.getSimplesCollectionSummary()
    // await this.getDctfwebCollectionSummary()
    // await this.getCertificateSummary()
    // await this.getMailBoxeSummary()
    await this.$root.$emit('Spinner::hide')
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize, true)
    window.removeEventListener('click', this.onLastSee, true)
    window.removeEventListener('mousemove', this.onLastSee, true)
  },

  computed: {
    filteredInboxes () {
      if (this.inboxes) {
        let tempInboxes = Object.keys(this.inboxes).map(i => this.inboxes[i])
        tempInboxes = tempInboxes.sort((a, b) => {
          const fa = a.ts
          const fb = b.ts
          if (fa < fb) {
            return -1
          }
          if (fa > fb) {
            return 1
          }
          return 0
        })
        return tempInboxes.reverse()
      }
      return null
    }
  },

  methods: {
    verifyTab () {
      console.log(window.uid)
      if (this.$profile == null) {
        Vue.prototype.$profile = { uid: window.uid }
      }
      if (this.$profile.uid && window.uid !== this.$profile.uid) {
        window.close()
      }
    },

    verifyStatusInbox (inbox) {
      if (inbox.documents) {
        for (let i = 0; i < inbox.documents.length; i++) {
          if (inbox.documents[i] && inbox.documents[i].payGuide && !inbox.documents[i].pay) {
            return false
          }
        }
      }
      return true
    },
    async changeInitialPage () {
      await this.sleep(1)
      await this.$root.$emit('Change::initialPage')
    },

    async getInboxes () {
      await this.$firebase.database().ref(`simplesconnect/users/${JSON.parse(this.$cookie.get('profile')).cid}/chatData/${window.uid}/inboxes`).limitToLast(30).on('value', snapshot => {
        const value = snapshot.val()
        if (value) {
          this.inboxes = value
        }
      })
    },

    async getCustomerName () {
      await this.$firebase.database().ref(`customers/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}/name`).on('value', snapshot => {
        const value = snapshot.val()
        if (value) {
          this.name = value
        }
      })
    },

    sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },

    onResize () {
      this.screen.height = window.screen.height
      Vue.prototype.$screen.height = this.height
      this.screen.width = window.screen.width
      Vue.prototype.$screen.width = this.width
      if (window.screen.width <= 1000 && this.$route.name !== 'index') {
        document.body.style.zoom = '68%'
      } else if (window.screen.width <= 1100 && this.$route.name !== 'index') {
        document.body.style.zoom = '59.5%'
      } else if (window.screen.width <= 1200 && this.$route.name !== 'index') {
        document.body.style.zoom = '65%'
      } else if (window.screen.width <= 1300 && this.$route.name !== 'index') {
        document.body.style.zoom = '70%'
      } else if (window.screen.width <= 1400 && this.$route.name !== 'index') {
        document.body.style.zoom = '75%'
      } else if (window.screen.width <= 1500 && this.$route.name !== 'index') {
        document.body.style.zoom = '80%'
      } else if (window.screen.width <= 1600 && this.$route.name !== 'index') {
        document.body.style.zoom = '86%'
      } else if (window.screen.width <= 1700 && this.$route.name !== 'index') {
        document.body.style.zoom = '90%'
      } else if (window.screen.width <= 1800 && this.$route.name !== 'index') {
        document.body.style.zoom = '95%'
      } else {
        document.body.style.zoom = '100%'
      }

      if (parseInt(document.querySelector('body').style.zoom.replace('%', '')) < 100) {
        if (window.screen.width < 900) {
          this.page = 'summary'
        }
        this.screen.cardHeight = window.screen.height / (parseInt(document.querySelector('body').style.zoom.replace('%', '')) / 100) + 'px'
      } else if (parseInt(document.querySelector('body').style.zoom.replace('%', '')) > 100) {
        this.screen.cardHeight = window.screen.height / (parseInt(document.querySelector('body').style.zoom.replace('%', '')) / 100) + 'px'
      } else {
        this.screen.cardHeight = (window.screen.height - 100) + 'px'
      }

      Vue.prototype.$screen.cardHeight = this.screen.cardHeight
    },

    async onLastSee () {
      const tempTime = Date.now()
      if (!JSON.parse(this.$cookie.get('currentProfile')) && (!this.dtLastSee || this.moment(this.dtLastSee).add(120, 'seconds').isBefore(this.moment(Date.now())))) {
        this.dtLastSee = tempTime
        await this.$firebase.database().ref(`customers/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}`).update({ dtCustomerLastSee: tempTime })
      }
    },

    async scrollMenuTo (id) {
      const menu = await document.querySelector('.horizontal-scrollable .row')
      const element = await document.querySelector('#btn-' + id)

      const lastMenuScrollPosition = this.menuScrollPosition
      this.showRightArrow = true

      if (element.offsetLeft < menu.scrollLeft + element.offsetWidth) {
        if (screen.width < 900) {
          menu.scrollLeft = element.offsetLeft - 15
        } else {
          menu.scrollLeft = element.offsetLeft - 115
        }

        if (lastMenuScrollPosition === this.menuScrollPosition) {
          this.showRightArrow = false
        }
        this.menuScrollPosition = menu.scrollLeft
      } else {
        if (screen.width < 900) {
          menu.scrollLeft = element.offsetLeft - 15
        } else {
          menu.scrollLeft = element.offsetLeft - 115
        }
        if (lastMenuScrollPosition === this.menuScrollPosition) {
          this.showRightArrow = false
        }
        this.menuScrollPosition = menu.scrollLeft
      }
    },

    async scrollToElement (side) {
      const el = document.querySelector('.horizontal-scrollable .row')

      const lastMenuScrollPosition = this.menuScrollPosition
      this.showRightArrow = true

      if (side === 'right') {
        el.scrollLeft = el.scrollLeft + 300
        this.menuScrollPosition = el.scrollLeft
        if (lastMenuScrollPosition === this.menuScrollPosition) {
          this.showRightArrow = false
        }
      } else {
        el.scrollLeft = el.scrollLeft - 300
        this.menuScrollPosition = el.scrollLeft
        if (lastMenuScrollPosition === this.menuScrollPosition) {
          this.showRightArrow = false
        }
      }
    },

    hexToRgbA (hex, opacity) {
      var c
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('')
        if (c.length === 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = '0x' + c.join('')
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')'
      }
    },

    async doLogout () {
      this.$root.$emit('Spinner::show')
      this.$cookie.delete('profile')
      await this.$firebase.auth().signOut()
      setTimeout(() => {
        this.$router.push({ path: 'login' })
        this.$root.$emit('Spinner::hide')
      }, 1500)
    },

    async getVisualSettings () {
      await this.$firebase.database().ref(`simplesconnect/users/${JSON.parse(this.$cookie.get('profile')).cid}`).child('customersArea').on('value', snapshot => {
        const customersArea = snapshot.val()
        if (customersArea) {
          this.customersArea = { ...this.customersArea, ...customersArea }
          this.customersArea.featured = this.hexToRgbA(customersArea.primaryColor, '.80')
          this.customersArea.featuredLight = this.hexToRgbA(customersArea.primaryColor, '.60')
          this.customersArea.featuredDark = this.hexToRgbA(customersArea.primaryColor, '1')
          this.customersArea.featuredLight2 = this.hexToRgbA(customersArea.primaryColor, '.20')
          this.customersArea.featuredLight1 = this.hexToRgbA(customersArea.primaryColor, '.1')
          this.customersArea.featuredLight03 = this.hexToRgbA(customersArea.primaryColor, '.03')
          this.customersArea.featuredLight02 = this.hexToRgbA(customersArea.primaryColor, '.02')
          this.customersArea.featuredLight01 = this.hexToRgbA(customersArea.primaryColor, '.01')
        }
      })
    },

    async getPermissions () {
      if (!JSON.parse(this.$cookie.get('profile')) || (JSON.parse(this.$cookie.get('profile')) && !window.uid)) {
        await this.doLogout()
        return null
      }
      await this.$firebase.database().ref(`customers/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}/blockSimplesConnectAccess`).on('value', snapshot => {
        const value = snapshot.val()
        if (value) {
          this.doLogout()
        }
      })
      await this.$firebase.database().ref(`customers/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}`).update({ dtCustomerLastSee: Date.now() })
      await this.$firebase.database().ref(`simplesconnect/users/${JSON.parse(this.$cookie.get('profile')).cid}`).child('customersPermissions').child(window.uid).on('value', async snapshot => {
        const value = snapshot.val()
        if (value) {
          this.permissions = value
          const processes = []
          processes.push(this.getInstallmentSummary())
          processes.push(this.getMeiCollectionSummary())
          processes.push(this.getSimplesCollectionSummary())
          processes.push(this.getDctfwebCollectionSummary())
          processes.push(this.getCertificateSummary())
          processes.push(this.getMailBoxeSummary())
          await Promise.all(processes)
        }
      })
    },

    async getInstallmentSummary () {
      if (this.permissions.installments) {
        const tables = [
          'pgfnInstallments',
          'simplesInstallments',
          'receitaInstallments',
          'especialInstallments'
        ]
        for (let i = 0; i < tables.length; i++) {
          await this.$firebase.database().ref(tables[i] + `/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}/loading`).on('value', snapshot => {
            const loading = snapshot.val()
            this.summary.installments.loading = loading

            if (this.summary.installments.loading) {
              this.showResume = true
              return null
            }

            this.$firebase.database().ref(tables[i] + `/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}/lastProcess`).once('value', snapshot => {
              const lastProcess = snapshot.val()
              if (lastProcess) {
                this.summary.installments.lastProcess = lastProcess
                this.showResume = true
              }
            })

            this.$firebase.database().ref(tables[i] + `/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}/status`).once('value', snapshot => {
              const status = snapshot.val()
              if (status && status.color !== 'status-danger') {
                this.summary.installments.status = status.info
                this.showResume = true
              }
            })
          })
          // if (this.summary.installments.status === 'Pendências' || this.summary.installments.status === 'Informar Faturamento') {
          //   return null
          // }
        }
      } else {
        this.summary.installments = { loading: null, status: null, lastProcess: null }
      }
    },

    async getSimplesCollectionSummary () {
      if (this.permissions.simplesCollection) {
        await this.$firebase.database().ref(`simplesCollections/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}/loading`).on('value', snapshot => {
          const loading = snapshot.val()
          this.summary.simplesCollection.loading = loading
        })

        if (this.summary.simplesCollection.loading) {
          this.showResume = true
          return null
        }

        await this.$firebase.database().ref(`simplesCollections/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}/lastProcess`).once('value', snapshot => {
          const lastProcess = snapshot.val()
          if (lastProcess) {
            this.summary.simplesCollection.lastProcess = lastProcess
            this.showResume = true
          }
        })

        await this.$firebase.database().ref(`simplesCollections/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}/status`).once('value', snapshot => {
          const status = snapshot.val()
          if (status && status.color !== 'status-danger') {
            this.summary.simplesCollection.status = status.info
            this.showResume = true
          }
        })
      } else {
        this.summary.simplesCollection = { loading: null, status: null, lastProcess: null }
      }
    },

    async getMeiCollectionSummary () {
      if (this.permissions.meiCollection) {
        await this.$firebase.database().ref(`meiCollections/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}/loading`).on('value', snapshot => {
          const loading = snapshot.val()
          this.summary.meiCollection.loading = loading
        })

        if (this.summary.meiCollection.loading) {
          this.showResume = true
          return null
        }

        await this.$firebase.database().ref(`meiCollections/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}/lastProcess`).once('value', snapshot => {
          const lastProcess = snapshot.val()
          if (lastProcess) {
            this.summary.meiCollection.lastProcess = lastProcess
            this.showResume = true
          }
        })

        await this.$firebase.database().ref(`meiCollections/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}/status`).once('value', snapshot => {
          const status = snapshot.val()
          if (status && status.color !== 'status-danger') {
            this.summary.meiCollection.status = status.info
            this.showResume = true
          }
        })
      } else {
        this.summary.meiCollection = { loading: null, status: null, lastProcess: null }
      }
    },

    async getCertificateSummary () {
      if (this.permissions.certificates) {
        await this.$firebase.database().ref(`certificates/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}/loading`).on('value', snapshot => {
          const loading = snapshot.val()
          this.summary.certificates.loading = loading
          if (this.summary.certificates.loading) {
            this.showResume = true
            return null
          }

          this.$firebase.database().ref(`certificates/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}/lastProcess`).once('value', snapshot => {
            const lastProcess = snapshot.val()
            if (lastProcess) {
              this.summary.certificates.lastProcess = lastProcess
              this.showResume = true
            }
          })

          this.$firebase.database().ref(`certificates/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}/status`).once('value', snapshot => {
            const status = snapshot.val()
            if (status && status.color !== 'status-danger') {
              this.summary.certificates.status = status.info
              this.showResume = true
            }
          })
        })
      } else {
        this.summary.certificates = { loading: null, status: null, lastProcess: null }
      }
    },

    async getMailBoxeSummary () {
      if (this.permissions.mailboxes) {
        await this.$firebase.database().ref(`mailboxes/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}/lastProcess`).on('value', snapshot => {
          const lastProcess = snapshot.val()
          if (lastProcess) {
            this.summary.mailboxes.loading = true
            this.showResume = true
          }
        })
      } else {
        this.summary.mailboxes = { loading: null, status: null, lastProcess: null }
      }
    },

    async getDctfwebCollectionSummary () {
      if (this.permissions.dctfwebCollection) {
        await this.$firebase.database().ref(`dctfwebCollections/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}`).on('value', snapshot => {
          const data = snapshot.val()
          if (data && data.status && data.status.color === 'status-danger') {
            return null
          }
          if ((data && data.loading) || (data && data.status && data.status.info === 'Verificando Erro')) {
            this.summary.dctfwebCollection.loading = data.loading
            this.showResume = true
          } else if (data && data.historic) {
            this.showResume = true
            const historic = data.historic
            var withoutMovement = true
            var tempHistoric = Object.keys(historic).map(i => historic[i])
            for (let i = 0; i < tempHistoric.length; i++) {
              const tempYear = Object.keys(tempHistoric[i]).map(t => tempHistoric[i].[t])
              for (let z = 0; z < tempYear.length; z++) {
                if (tempYear[z].type && (!tempYear[z].type.includes('Original sem movimento') || !tempYear[z].value === '0,00')) {
                  withoutMovement = false
                }
              }
            }
            if (withoutMovement === true) {
              this.summary.dctfwebCollection.status = 'Sem Movimento'
              return true
            }
            tempHistoric = Object.keys(historic).map(i => historic[i])
            for (let i = 0; i < tempHistoric.length; i++) {
              const tempYear = Object.keys(tempHistoric[i]).map(t => tempHistoric[i].[t])
              for (let z = 0; z < tempYear.length; z++) {
                if (tempYear[z].type && (tempYear[z].type !== 'Original sem movimento' && tempYear[z].value !== '0,00') && !tempYear[z].receiptsGuides && !tempYear[z].paied) {
                  this.summary.dctfwebCollection.status = 'Pendências'
                  return true
                }
              }
            }
            this.summary.dctfwebCollection.status = 'Em Dia'
            return false
          }
        })
      } else {
        this.summary.dctfwebCollection = { loading: null, status: null, lastProcess: null }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 45px;
  right: 30px;
  background-color: var(--featured-dark);
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3);
  z-index: 1;
  .my-float {
    margin-top: 17px;
    margin-right: 7px;
    height: 22px !important;
    width: 22px !important;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
.content-container{
  background-color: var(--featured-light-03) !important;
}
.btn-arrow {
  position: absolute;
  height: 55px;
  z-index: 100;
  border-radius: 0 !important;
  color: #000000;
  &.to-left{
    left: 15px;
    width: 140px;
    top: 0;
    text-align: left;
    padding-left: 8px !important;
    background: linear-gradient(90deg, rgba(242,243,248,.95) 0%, rgba(242,243,248,0.95) 10%, rgba(242,243,248,0.95) 20%, rgba(248,248,251,0.85) 30%, rgba(255,255,255,0.75) 40%, rgba(255,255,255,0.55) 50%, rgba(255,255,255,0.45) 60%, rgba(255,255,255,0.35) 70%, rgba(255,255,255,0.25) 80%, rgba(255,255,255,0.15) 90%, rgba(255,255,255,0) 100%);
  }
  &.to-right{
    right: 0px;
    width: 140px;
    top: 0;
    text-align: right;
    padding-right: 8px !important;
    background: linear-gradient(-90deg, rgba(242,243,248,.95) 0%, rgba(242,243,248,0.95) 10%, rgba(242,243,248,0.95) 20%, rgba(248,248,251,0.85) 30%, rgba(255,255,255,0.75) 40%, rgba(255,255,255,0.55) 50%, rgba(255,255,255,0.45) 60%, rgba(255,255,255,0.35) 70%, rgba(255,255,255,0.25) 80%, rgba(255,255,255,0.15) 90%, rgba(255,255,255,0) 100%);}
  &:active{
    opacity: 1 !important;
    box-shadow: none !important;
  }
}

.horizontal-scrollable {
  margin: 0 15px 10px 15px !important;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 320px) and (max-width: 992px) {
    /* width */
    ::-webkit-scrollbar {
      display: block;
      height: 11px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #eee;
      border-radius: 4px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 4px;
      background-clip: content-box;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #c1c1c1;
    }
  }
  .btn-primary {
    background: var(--featured-dark) !important;
    &:hover{
      transform: translate(0, 0px);
    }
    &.disabled {
      background-color: transparent !important;
      color: #505050;
      svg{
        fill: #505050 !important;
      }
    }
  }
}

.horizontal-scrollable > .row {
  overflow-x: auto;
  white-space: nowrap;
  height: 55px !important;
}

.table-card{
  overflow: hidden auto;
  background-color: white !important;
  box-shadow: none !important;
  tbody{
    background: #f2f3f8;
  }
  tr{
    background: transparent;
  }
  td{
    padding: 0 !important;
    background: transparent;
    border: none;
  }
}

.summary {
  background-color: white;
  padding: 17px 3px 40px 17px !important;
  border-radius: 5px;
  box-shadow: 0px 0px 4px 3px rgba(0,0,0,.03) !important;
  .info-status{
    border: none;
    cursor: default !important;
    font-size: 11px !important;
    margin: 0px 0px 0px 3px !important;
    border-radius: 4px;
    height: 24px;
    font-weight: 700 !important;
    background-color: white;
    transition: all .2s;
    padding: 4px 8px;
    min-width: 40px;
    width: 130px;
    text-align: center;
  }
  img{
    position: absolute;
    bottom: 5px;
    right: 5px;
    height: 27px;
    margin: 0;
  }
  h5{
    font-size: 19px;
  }
}

.historic{
  transition: all .3s;
  background-color: #fff;
  padding:  9px 9px 9px 9px !important;
  margin-right: 10px;
  border-radius: 0px;
  margin-bottom: 0px;
  border-bottom: 1px solid #f5f5f5;
  cursor: pointer;
  p {
    font-size: 11.5px;
    margin: 0;
    font-weight: 500;
  }
  .local{
      font-size: 12px;
      font-weight: 400 !important;
      margin-left: 17px;
      margin-bottom: 0 !important;
  }
  .title{
    font-size: 14px;
    font-weight: 700 !important;
    margin-left: 17px;
    margin-bottom: 5px !important;
  }
}

.icon-box{
  //background-color: #505050 !important;
  background-color: transparent !important;
  height: 38px;
  width: 38px;
  border-radius: 3px;
  text-align: center;
  margin-right: 0px;
  &.background-success{
    background-color: var(--featured-light) !important;
  }
  &.background-red{
    background-color: var(--red-light) !important;
  }
  i{
    font-size: 29px;
    color: var(--featured-light) !important;
    height: 38px;
    width: 38px !important;
    vertical-align: middle;
    item-align: center;
    line-height: 38px;
  }
  svg{
    fill: var(--featured-light) !important;
    height: 29px;
    width: 29px !important;
    vertical-align: middle;
    item-align: center;
    line-height: 29px;
  }
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d1d1d1;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c1c1c1;
}

.new-box{
  background-color: #f26754;
  border-radius: 4px;
  padding: auto;
  position: absolute;
  left: 120px;
  width: 17px;
  height: 17px;
  text-align: center;
  border: none;
  transform: translate(-12px, -30px);
  cursor: pointer;
  span{
    color: white;
    font-size: 10px;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    transform: translate(0, 2px);
    position: absolute;
    text-align: center;
  }
}

.navigation-sidebar{
  position: fixed;
  top: 0;
  float: center;
  height: 100% !important;
  // height: 100vh;
  overflow: hidden auto;
  width: 70px;
  border-radius: 0px;
  padding: 0;
  margin: 0;
  box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.1);
  background-color: white;
  z-index: 100;
  display: flex;
  align-items: center;
  &::-webkit-scrollbar {
    display: none !important;
  }
  .col {
    text-align: center;
  }
  .nav-item{
    height: 50px;
  }
  .nav-button:last-child{
    margin-bottom: 50px;
  }
  .nav-button{
    display: inline-block;
    cursor: pointer;
    color: #505050;
    //color: var(--featured) !important;
    text-align: center;
    padding: 16px 25px !important;
    border-radius: 0px !important;
    transition: all .3s;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
    &.active{
      background-color: var(--featured-light-1);
      color: var(--featured) !important;
      transition: all .3s;
      z-index: 999;
      svg{
        fill:var(--featured-dark) !important;
      }
    }

    .new-box{
      background-color: #f26754;
      border-radius: 4px;
      padding: auto;
      position: absolute;
      right: 0px;
      width: 17px;
      height: 17px;
      text-align: center;
      border: none;
      transform: translate(-15px, -35px);
      cursor: pointer;
      span{
        color: white;
        font-size: 10px;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        transform: translate(0, 1px);
        position: absolute;
        text-align: center;
      }
    }

    &:hover{
      background-color: var(--featured-light-1);
      color: var(--featured-dark) !important;
      svg{
        fill: var(--featured-dark) !important;
      }
    }
    &.logout{
      &:hover{
        background-color: rgba(232, 121, 121, .09) !important;
        color: var(--red-light) !important;
      }
    }
    &.disabled{
      color: #c1c1c1 !important;
      svg{
        fill:#c1c1c1 !important;
      }
    }
  }
  i, svg{
    height: 21px !important;
    width: 21px !important;
    font-size: 20px;
    fill:#505050;
  }
  svg{
    transform: translate(0, -3px);
  }
  .icon-ajust{
    margin-left: 2px;
    margin-right: 10px;
  }
  img{
    display: flex;
    width: 56%;
    justify-content: center;
    margin: 27px auto;
  }
  li{
    margin: 3.5px 5px;
    letter-spacing: .25px;
  }
  .nav-link:focus {
      outline:none !important;
      box-shadow: none !important;
  }
  .nav-link::selection {
      color: none;
      background: none;
  }
  .nav-link::-moz-selection {
      color: none;
      background: none;
  }
}
</style>
