<template>
  <div>
    <div class="data-content" :class="{show: content}" :style="{display: content ? 'none' : 'block'}">
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <div :key="i" v-for="(historic, key, i) in registered" :style="{'width': 100 / historicLenght + '%'}">
            <a class="nav-item nav-link" :class="[tabsType === key ? 'active' : '']" :style="historicLenght === 1 ? 'border-radius: 5px !important;' : '' || i === 0 ? 'border-top-left-radius: 5px !important; border-bottom-left-radius: 5px !important;' : '' || i === historicLenght - 1 ? 'border-top-right-radius: 5px !important; border-bottom-right-radius: 5px !important; border-right: var(--featured-light) solid 2px !important;' : ''" @click="tabsType = key">
              <template v-if="key === 'mensagens-ecac'">
                Mensagens e-CAC
              </template>
            </a>
          </div>
        </div>
      </nav>
      <div>
        <table class="table" v-if="sortedHistoric && sortedHistoric.length">
          <thead>
            <tr>
              <th scope="col" style="width: 65px !important; max-width: 65px !important; min-width: 100px !important;">Importância</th>
              <th scope="col" style="width: 100px">Recebida</th>
              <th scope="col" style="width: 450px">Assunto</th>
              <th scope="col" style="width: 120px">Mensagem</th>
            </tr>
          </thead>
          <tbody>
            <tr
              :key="i"
              v-for="(historic, i) in sortedHistoric"
            >
              <template v-if="historic.important">
                <td class="text-center"><i style="color: var(--danger); font-size: 23px" class="fas fa-exclamation-circle"></i></td>
              </template>
              <template v-else>
                <td class="text-center"><i style="color: var(--success); font-size: 23px" class="fas fa-dot-circle"></i></td>
              </template>
              <td>{{historic.dtReceived.replaceAll('-', '/')}}</td>
              <template v-if="historic.subject.length > 25">
                <td>{{historic.subject.substring(0, 25)}}...</td>
              </template>
              <template v-else>
                <td>{{historic.subject}}</td>
              </template>
              <td><button :class="'btn btn-sm btn-primary w-100'" @click="content = historic.content; subject = historic.subject">Visualizar</button></td>
            </tr>
          </tbody>
        </table>
        <div class="container-flex warning-alert" v-else>
          <p class="mb-0 text-center">Você não tem nenhuma mensagem por aqui nos últimos 3 meses.</p>
        </div>
      </div>
    </div>
    <div class="modal-content" :class="{show: content}" :style="{display: content ? 'block' : 'none'}">
      <div class="row justify-content-end pr-3"><div style="padding: 10px 16px !important;" class="btn btn-secondary btn-sm" @click="content = null"><i class="fa-solid fa-xmark" style="margin-right: 0 !important;"></i></div></div>
      <div class="modal-body">
        <p class="mb-4">Assunto: {{subject}}</p>
        <span v-html="content"></span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['mailbox', 'registered'],

  components: {
  },

  data: () => ({
    showModal: false,
    tabsType: 'mensagens-ecac',
    content: null,
    subject: null
  }),

  computed: {
    historicLenght () {
      var tempRegistered = this.registered
      if (this.registered) {
        tempRegistered = Object.keys(this.registered).map(i => this.registered[i]).slice().reverse()
      }
      return tempRegistered.length
    },
    sortedHistoric () {
      var tempHistoric = this.mailbox.registered[this.tabsType].historic
      if (this.mailbox.registered[this.tabsType].historic) {
        tempHistoric = Object.keys(this.mailbox.registered[this.tabsType].historic).map(i => this.mailbox.registered[this.tabsType].historic[i]).slice().reverse()
        tempHistoric = tempHistoric.sort((a, b) => { return parseInt(a.dtReceived.substring(6, 10) + a.dtReceived.substring(3, 5) + a.dtReceived.substring(0, 2)) - parseInt(b.dtReceived.substring(6, 10) + b.dtReceived.substring(3, 5) + b.dtReceived.substring(0, 2)) }).slice().reverse()
      }
      return tempHistoric
    }
  },

  methods: {
    closeModal () {
      this.showModal = false
    },
    emailsReadList (emailsRead) {
      let info = ''
      emailsRead = Object.keys(emailsRead).map(i => emailsRead[i])
      for (let i = 0; i < emailsRead.length; i++) {
        info += emailsRead[i].emailAddress + ' abriu no dia ' + emailsRead[i].dtSent + '\n'
      }
      return info
    }
  }
}
</script>

<style lang="scss" scoped>
.warning-alert{
  // box-shadow: 0px 0px 8px 6px rgba(0,0,0,0.1);
  margin: 10px 0 15px 0;
  border-radius: 5px;
  padding: 18px;
  background: var(--featured-light);
  color: #fff;
  .row{
    margin: 0;
  }
  .close{
    color: #856404;
    font-size: 16px;
    margin-top: -15px;
  }
  h5{
    font-size: 14px;
    font-weight: 700;
  }
  p{
    font-size: 14px
  }
}
.data-content{
  .btn-primary{
    background: var(--featured-dark) !important;
  }
  .modal-header, .modal-footer{
    border: none !important;
  }

  .modal-body{
    padding-top: 0;
  }

  .nav-tabs{
    border: 0;
    margin: 0;
    // border-radius: 7px !important;
  }
  .nav-tabs .nav-item {
    font-size: 13.5px;
    margin-bottom: 0px;
    text-align: center;
    color: var(--featured-dark);
    font-weight: 700;
    border-radius: 0px !important;
    border: var(--featured-dark) solid 2px !important;
    cursor: pointer !important;
    border-right: 0 !important;
    &.active{
      color: white;
      background: var(--featured-dark);
    }
  }

  .table{
    box-shadow: none;
    background-color: white;
    border-radius: 4px;
    padding: 0;
    border-spacing: 0;
    th{
      border-top: none !important;
      border-bottom: none !important;
      padding: 7px 5px !important;
      padding-top: 5px !important;
      font-size: 13.5px;
    }
    td{
      border-top: #e9e9e9 solid 1px;
      font-size: 13px;
      padding: 7px 5px !important;
    }
    .btn-secondary, .btn-primary, .btn-danger, .btn-warning{
      font-size: 12px !important;
      padding: .65rem .9rem !important;
    }
  }
  h4, h5{
    font-weight: 700;
  }
  h5{
    font-size: 15px;
  }
  .form-group{
    margin: 7px 0;
  }
}
.details-buttom{
  color: blue !important;
  cursor: pointer;
  &[disabled]{
    cursor: not-allowed !important;
    color: gray !important;
  }
  &::selection {
      color: none;
      background: none;
  }
}
</style>
