<template>
  <div>
    <div class="pl-0 pr-0 card chat-card" :style="'height: calc('+ this.$screen.cardHeight + ' - 85px) !important;'">
      <input hidden type="file" multiple class="dropzone-chat" id="dropzone-chat" @change="onChangeChat" ref="filesChat" accept=".jpeg,.jpg,.png,.pdf"/>
      <div class="row chat-header">
        <div class="col">
          <div class="row">
            <img class="img-fluid" :src="customersArea.logoUrl">
            <div>
              <p class="document mt-auto mb-auto">{{customersArea.name}}</p>
              <p class="name mt-auto mb-auto" v-if="name && name.length > 30">{{name.substring(0,30)}}...</p>
              <p class="name mt-auto mb-auto" v-else>{{name}}</p>
            </div>
          </div>
        </div>
        <div class="pr-3">
          <div class="row justify-content-end" v-if="permissions.online">
            <template v-if="online">
              <div class="status-dot" style="background-color: var(--success)"></div>
              <p class="status-online">Online</p>
            </template>
            <template v-else>
              <div class="status-dot" style="background-color: var(--red-light)"></div>
              <p class="status-online">Offline</p>
            </template>
          </div>
        </div>
      </div>
      <div class="chat-body" v-if="!inboxToSend.documents.length && !hoverDocument && initialPage === 'chat'" @dragover="dragoverChat">
        <table class="chat-body m-0 w-100">
          <div class="row" :style="'height: ' + fillHeight + ' !important;'"></div>
            <tbody>
              <div class="chat-elements">
                <div
                  :key="i"
                  v-for="(message, key, i) in filteredMessages"
                  class="row-flex w-100"
                >
                  <td style="float: left; width: 100%;" v-if="message.date"><p class="day-date mt-3 mb-3">{{moment(message.ts, "x").format('DD/MM/YYYY')}}</p></td>
                  <td style="float: right; width: 100%;" v-if="message.c && !message.date">
                    <div class="chat-message" v-if="message.t && message.t !== ''">
                      <div class="chat-box counter">
                        <div>{{message.t}}</div>
                        <div class="date counter-date row justify-content-end">
                          {{moment(message.ts, "x").format('HH:mm')}}
                        </div>
                      </div>
                    </div>
                    <div style="float: right; width: 100%;" v-if="message.ibId && inboxes[message.ibId]">
                      <div class="chat-documents counter">
                        <div class="row title-document">
                          <i class="fa-solid fa-inbox"></i>
                          <div>
                            <p class="category" v-if="inboxes[message.ibId].category">{{inboxes[message.ibId].category}}</p>
                            <p class="category" v-else>{{customersArea.name}}</p>
                            <p class="title" v-if="inboxes[message.ibId].title.length > 35">{{inboxes[message.ibId].title.substring(0, 33)}}...</p>
                            <p class="title" v-else>{{inboxes[message.ibId].title}}</p>
                          </div>
                        </div>
                        <table class="table" >
                          <thead class="w-100">
                            <tr>
                              <!--<th scope="col">Tipo</th>-->
                              <th scope="col">Título</th>
                              <th scope="col">Situação</th>
                              <th scope="col">Pago</th>
                              <th scope="col">Arquivo</th>
                            </tr>
                          </thead>
                          <tbody class="w-100">
                            <tr
                              :key="i"
                              v-for="(document, key, i) in inboxes[message.ibId].documents"
                            >
                              <template v-if="document">
                                <template>
                                  <td style="min-width: 250px; width: 100%" v-if="document && document.title.length > 17">{{document.title.substring(0, 17)}}...</td>
                                  <td style="min-width: 250px;  width: 100%" v-else>{{document.title}}</td>
                                </template>

                                <td>
                                  <div class="info-status status-success" v-if="document && document.payGuide && document.pay">Pago</div>
                                  <div class="info-status status-warning" v-else-if="document && document.payGuide && !document.pay">Pendente</div>
                                  <div class="info-status status-loading" style="opacity: .35;" v-else></div>
                                </td>

                                <td>
                                  <label class="custom-control custom-checkbox text-center" v-if="document.payGuide">
                                    <input type="checkbox" class="custom-control-input" :checked="document.pay" @click="changePaymentDocument(inboxes[message.ibId], document, key)">
                                    <span class="custom-control-label"></span>
                                  </label>
                                  <label class="custom-control custom-checkbox text-center" style="opacity: .45; cursor: pointer !important;" v-else>
                                    <input type="checkbox" class="custom-control-input" :disabled="true" @click="changePaymentDocument(inboxes[message.ibId], document, key)">
                                    <span class="custom-control-label"></span>
                                  </label>
                                </td>

                                <td class="text-center">
                                  <a v-if="document.received && (document.received.whatsapp || document.received.email || document.received.chat)" @click="openDocument(inboxes[message.ibId], document, key, document.url)"><i class="fa-regular fa-circle-check"></i></a>
                                  <a v-else @click="openDocument(inboxes[message.ibId], document, key, document.url)"><i class="fa-solid fa-circle-down"></i></a>
                                </td>
                              </template>
                            </tr>
                          </tbody>
                        </table>
                        <div class="date document-date row justify-content-end">
                          {{moment(message.ts, "x").format('HH:mm')}}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td style="float: right; width: 100%;" v-else-if="!message.date">
                    <div class="chat-message" v-if="message.t && message.t !== ''">
                      <div class="chat-box customer">
                        <!--<div class="delete-arrow dropup">
                          <i class="fa-solid fa-chevron-down" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                          <div class="dropdown-menu dropdown-menu-right dropdown-menu-start" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" @click="deleteMessage(message)"><i class="fa-solid fa-trash-can pr-3" style="color: var(--red-light)"></i>Apagar mensagem</a>
                          </div>
                        </div>-->
                        <div>{{message.t}}</div>
                        <div class="date customer-date row justify-content-end">
                          <div style="margin-top: 2px; margin-right: 5px;">{{moment(message.ts, "x").format('HH:mm')}}</div>
                          <!--<div class="checks pr-1">
                            <i v-if="dtCounterLastSee > message.ts" class="fa-regular fa-circle-check" style="margin-left: 2px; margin-right: 0px; font-size: 13px"></i>
                            <i class="fa-regular fa-circle-check" style="margin-left: 2px; margin-right: -3px; font-size: 13px"></i>
                          </div>-->
                          <div class="checks pr-0 pb-1">
                            <i v-if="dtCounterLastSee > message.ts" style="font-size: 13px;">
                              <svg width="24" height="24" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.996 16.239C11.356 16.389 10.69 16.48 10 16.48C9.431 16.48 8.876 16.424 8.339 16.321L5.2 17.867C5.2 16.797 5.198 15.778 5.199 15.022C3.262 13.704 2 11.614 2 9.24C2 5.223 5.598 2 10 2C14.402 2 18 5.223 18 9.24C18 10.221 17.782 11.153 17.394 12.004" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M10.177 9.21002C10.275 9.30802 10.275 9.46602 10.177 9.56402C10.079 9.66202 9.921 9.66202 9.823 9.56402C9.725 9.46602 9.725 9.30802 9.823 9.21002C9.921 9.11302 10.079 9.11302 10.177 9.21002" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M13.732 9.21002C13.83 9.30802 13.83 9.46602 13.732 9.56402C13.634 9.66202 13.476 9.66202 13.378 9.56402C13.28 9.46602 13.28 9.30802 13.378 9.21002C13.476 9.11302 13.635 9.11302 13.732 9.21002" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.621 9.21002C6.719 9.30802 6.719 9.46602 6.621 9.56402C6.523 9.66202 6.365 9.66202 6.267 9.56402C6.169 9.46602 6.169 9.30802 6.267 9.21002C6.365 9.11302 6.524 9.11302 6.621 9.21002" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M20 22H14C12.895 22 12 21.105 12 20V14C12 12.895 12.895 12 14 12H20C21.105 12 22 12.895 22 14V20C22 21.105 21.105 22 20 22Z" fill="#FFFFFF"/>
                                <path d="M19.222 15.889L16.444 18.667L14.778 17" stroke="#2FB490" stroke-width="1.5294" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </i>
                            <i v-else>
                              <svg width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 11.291C21 15.83 16.952 19.473 12 19.473C11.359 19.473 10.735 19.41 10.131 19.294" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.599 17.826C4.42 16.336 3 13.974 3 11.291" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.59901 17.826C6.59801 18.68 6.60001 19.832 6.60001 21.041" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M3 11.291C3 6.75199 7.048 3.10999 12 3.10999C16.952 3.10999 21 6.75299 21 11.292" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M10.13 19.29L6.60001 21.04" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12.1768 11.3232C12.2744 11.4208 12.2744 11.5791 12.1768 11.6767C12.0792 11.7744 11.9209 11.7744 11.8233 11.6767C11.7256 11.5791 11.7256 11.4208 11.8233 11.3232C11.9209 11.2256 12.0792 11.2256 12.1768 11.3232" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16.1768 11.3232C16.2744 11.4208 16.2744 11.5791 16.1768 11.6767C16.0792 11.7744 15.9209 11.7744 15.8233 11.6767C15.7256 11.5791 15.7256 11.4208 15.8233 11.3232C15.9209 11.2256 16.0792 11.2256 16.1768 11.3232" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M8.17678 11.3232C8.27441 11.4208 8.27441 11.5791 8.17678 11.6767C8.07915 11.7744 7.92085 11.7744 7.82322 11.6767C7.72559 11.5791 7.72559 11.4208 7.82322 11.3232C7.92085 11.2256 8.07915 11.2256 8.17678 11.3232" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="float: right; width: 100%;" v-if="message.ibId && inboxes[message.ibId]">
                      <div class="chat-documents customer">
                        <div class="row title-document">
                          <i class="fa-solid fa-inbox"></i>
                          <div>
                            <p class="category" v-if="inboxes[message.ibId].category">{{inboxes[message.ibId].category}}</p>
                            <p class="category" v-else>Você</p>
                            <p class="title" v-if="inboxes[message.ibId].title.length > 35">{{inboxes[message.ibId].title.substring(0, 33)}}...</p>
                            <p class="title" v-else>{{inboxes[message.ibId].title}}</p>
                          </div>
                        </div>
                        <table class="table" >
                          <thead class="w-100">
                            <tr>
                              <!--<th scope="col">Tipo</th>-->
                              <th scope="col">Título</th>
                              <th scope="col">Situação</th>
                              <th scope="col">Pago</th>
                              <th scope="col">Arquivo</th>
                            </tr>
                          </thead>
                          <tbody class="w-100">
                            <tr
                              :key="i"
                              v-for="(document, key, i) in inboxes[message.ibId].documents"
                            >
                              <template v-if="document">
                                <template>
                                  <td style="min-width: 250px; width: 100%" v-if="document && document.title.length > 17">{{document.title.substring(0, 17)}}...</td>
                                  <td style="min-width: 250px;  width: 100%" v-else>{{document.title}}</td>
                                </template>

                                <td>
                                  <div class="info-status status-success" v-if="document && document.payGuide && document.pay">Pago</div>
                                  <div class="info-status status-warning" v-else-if="document && document.payGuide && !document.pay">Pendente</div>
                                  <div class="info-status status-loading" style="opacity: .35;" v-else></div>
                                </td>

                                <td>
                                  <label class="custom-control custom-checkbox text-center" v-if="document.payGuide">
                                    <input type="checkbox" class="custom-control-input" :checked="document.pay" @click="changePaymentDocument(inboxes[message.ibId], document, key)">
                                    <span class="custom-control-label"></span>
                                  </label>
                                  <label class="custom-control custom-checkbox text-center" style="opacity: .45; cursor: pointer !important;" v-else>
                                    <input type="checkbox" class="custom-control-input" :disabled="true" @click="changePaymentDocument(inboxes[message.ibId], document, key)">
                                    <span class="custom-control-label"></span>
                                  </label>
                                </td>

                                <td class="text-center">
                                  <a v-if="document.received && (document.received.whatsapp || document.received.email || document.received.chat)" @click="openDocument(inboxes[message.ibId], document, key, document.url)"><i class="fa-regular fa-circle-check"></i></a>
                                  <a v-else @click="openDocument(inboxes[message.ibId], document, key, document.url)"><i class="fa-solid fa-circle-down"></i></a>
                                </td>
                              </template>
                            </tr>
                          </tbody>
                        </table>
                        <div class="date document-date row justify-content-end">
                          <div class="mt-auto" style="margin-bottom: 5px; margin-right: 5px;">{{moment(message.ts, "x").format('HH:mm')}}</div>
                          <div class="checks">
                            <i v-if="dtCounterLastSee > message.ts" style="font-size: 13px;">
                              <svg width="24" height="24" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.996 16.239C11.356 16.389 10.69 16.48 10 16.48C9.431 16.48 8.876 16.424 8.339 16.321L5.2 17.867C5.2 16.797 5.198 15.778 5.199 15.022C3.262 13.704 2 11.614 2 9.24C2 5.223 5.598 2 10 2C14.402 2 18 5.223 18 9.24C18 10.221 17.782 11.153 17.394 12.004" stroke="#a7a7a7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M10.177 9.21002C10.275 9.30802 10.275 9.46602 10.177 9.56402C10.079 9.66202 9.921 9.66202 9.823 9.56402C9.725 9.46602 9.725 9.30802 9.823 9.21002C9.921 9.11302 10.079 9.11302 10.177 9.21002" stroke="#a7a7a7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M13.732 9.21002C13.83 9.30802 13.83 9.46602 13.732 9.56402C13.634 9.66202 13.476 9.66202 13.378 9.56402C13.28 9.46602 13.28 9.30802 13.378 9.21002C13.476 9.11302 13.635 9.11302 13.732 9.21002" stroke="#a7a7a7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.621 9.21002C6.719 9.30802 6.719 9.46602 6.621 9.56402C6.523 9.66202 6.365 9.66202 6.267 9.56402C6.169 9.46602 6.169 9.30802 6.267 9.21002C6.365 9.11302 6.524 9.11302 6.621 9.21002" stroke="#a7a7a7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M20 22H14C12.895 22 12 21.105 12 20V14C12 12.895 12.895 12 14 12H20C21.105 12 22 12.895 22 14V20C22 21.105 21.105 22 20 22Z" fill="#2FB490"/>
                                <path d="M19.222 15.889L16.444 18.667L14.778 17" stroke="#FFFFFF" stroke-width="1.5294" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </i>
                            <i v-else>
                              <svg width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 11.291C21 15.83 16.952 19.473 12 19.473C11.359 19.473 10.735 19.41 10.131 19.294" stroke="#a7a7a7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.599 17.826C4.42 16.336 3 13.974 3 11.291" stroke="#a7a7a7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.59901 17.826C6.59801 18.68 6.60001 19.832 6.60001 21.041" stroke="#a7a7a7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M3 11.291C3 6.75199 7.048 3.10999 12 3.10999C16.952 3.10999 21 6.75299 21 11.292" stroke="#a7a7a7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M10.13 19.29L6.60001 21.04" stroke="#a7a7a7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12.1768 11.3232C12.2744 11.4208 12.2744 11.5791 12.1768 11.6767C12.0792 11.7744 11.9209 11.7744 11.8233 11.6767C11.7256 11.5791 11.7256 11.4208 11.8233 11.3232C11.9209 11.2256 12.0792 11.2256 12.1768 11.3232" stroke="#a7a7a7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16.1768 11.3232C16.2744 11.4208 16.2744 11.5791 16.1768 11.6767C16.0792 11.7744 15.9209 11.7744 15.8233 11.6767C15.7256 11.5791 15.7256 11.4208 15.8233 11.3232C15.9209 11.2256 16.0792 11.2256 16.1768 11.3232" stroke="#a7a7a7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M8.17678 11.3232C8.27441 11.4208 8.27441 11.5791 8.17678 11.6767C8.07915 11.7744 7.92085 11.7744 7.82322 11.6767C7.72559 11.5791 7.72559 11.4208 7.82322 11.3232C7.92085 11.2256 8.07915 11.2256 8.17678 11.3232" stroke="#a7a7a7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </i>
                          </div>
                        </div>

                      </div>
                    </div>
                  </td>

                </div>
              </div>
          </tbody>
        </table>
      </div>
      <div class="chat-body" v-else-if="!inboxToSend.documents.length && !hoverDocument && initialPage === 'inbox'" @dragover="dragoverChat">
        <table class="chat-body m-0 w-100">
          <div class="row" :style="'height: ' + fillHeight + ' !important;'"></div>
            <tbody>
              <div class="chat-elements">
                <div
                  :key="i"
                  v-for="(inboxes, key, i) in filteredInboxes"
                  class="row-flex w-100"
                >
                  <td style="float: right; width: 100%;">
                    <div style="float: right; width: 100%;">
                      <div class="chat-documents counter w-100 mt-2" style="border-radius: 4px !important;">
                        <div class="row title-document" style="border-top-left-radius: 4px !important;border-top-right-radius: 4px !important;">
                          <i class="fa-solid fa-inbox"></i>
                          <div>
                            <p class="category" v-if="inboxes.category">{{inboxes.category}}</p>
                            <p class="category" v-else-if="inboxes.customer">Você</p>
                            <p class="category" v-else>{{customersArea.name}}</p>
                            <p class="title" v-if="inboxes.title.length > 35">{{inboxes.title.substring(0, 33)}}...</p>
                            <p class="title" v-else>{{inboxes.title}}</p>
                          </div>
                        </div>
                        <table class="table" >
                          <thead class="w-100">
                            <tr>
                              <!--<th scope="col">Tipo</th>-->
                              <th scope="col">Título</th>
                              <th scope="col">Situação</th>
                              <th scope="col">Pago</th>
                              <th scope="col">Arquivo</th>
                            </tr>
                          </thead>
                          <tbody class="w-100">
                            <tr
                              :key="i"
                              v-for="(document, key, i) in inboxes.documents"
                            >
                              <!--<td>
                                <div class="icon-box">
                                  <i class="fa-solid fa-file"></i>
                                </div>
                              </td>-->
                              <template v-if="document">

                                <template >
                                  <td style="min-width: 200px; width: 100%" v-if="document && document.title.length > 17">{{document.title.substring(0, 17)}}...</td>
                                  <td style="min-width: 200px;  width: 100%" v-else>{{document.title}}</td>
                                </template>

                                <td>
                                  <div class="info-status status-success" v-if="document && document.payGuide && document.pay">Pago</div>
                                  <div class="info-status status-warning" v-else-if="document && document.payGuide && !document.pay">Pendente</div>
                                  <div class="info-status status-loading" style="opacity: .35;" v-else></div>
                                </td>

                                <td>
                                  <label class="custom-control custom-checkbox text-center" v-if="document.payGuide">
                                    <input type="checkbox" class="custom-control-input" :checked="document.pay" @click="changePaymentDocument(inboxes, document, key)">
                                    <span class="custom-control-label"></span>
                                  </label>
                                  <label class="custom-control custom-checkbox text-center" style="opacity: .45; cursor: pointer !important;" v-else>
                                    <input type="checkbox" class="custom-control-input" :disabled="true" @click="changePaymentDocument(inboxes, document, key)">
                                    <span class="custom-control-label"></span>
                                  </label>
                                </td>

                                <td class="text-center">
                                  <a v-if="document.received && (document.received.whatsapp || document.received.email || document.received.chat)" @click="openDocument(inboxes, document, key, document.url)"><i class="fa-regular fa-circle-check"></i></a>
                                  <a v-else @click="openDocument(inboxes, document, key, document.url)"><i class="fa-solid fa-circle-down"></i></a>
                                </td>
                              </template>
                            </tr>
                          </tbody>
                        </table>
                        <div class="date document-date row justify-content-end">
                          {{moment(inboxes.ts, "x").format('DD/MM/YYYY')}} ás {{moment(inboxes.ts, "x").format('HH:mm')}}
                        </div>
                      </div>
                    </div>
                  </td>
                </div>
              </div>
          </tbody>
        </table>
      </div>
      <div class="chat-edit-documents" v-else @dragover="dragoverChat" @dragleave="dragleaveChat" @drop="dropChat">
        <div v-if="documentsLoading" class="row justify-content-center h-100">
          <div class="m-auto">
            <video width="130" height="130" autoplay loop muted>
              <source src="../../assets/images/document-loading.mp4" type="video/mp4">
            </video>
          </div>
        </div>
        <div v-else-if="!inboxToSend.documents.length" class="row justify-content-center h-100">
          <div class="m-auto">
            <h4 class="drap-text">Arraste e solte aqui PDFs ou Imagens</h4>
          </div>
        </div>
        <div v-else-if="inboxToSend.documents.length">
          <div class="row justify-content-end mr-3"><div style="padding: 10px 16px !important;" class="btn btn-secondary btn-sm" @click="closeDocument()"><i class="fa-solid fa-xmark" style="margin-right: 0 !important;"></i></div></div>
          <!--<div class="row justify-content-center"><h4 class="drap-text">Arraste e solte aqui PDFs ou Imagens</h4></div>-->
          <div class="form-group mt-3">
            <p>Título do Envio</p>
            <input
              maxlength="60"
              type="text"
              class="form-control"
              placeholder="Exemplo: Comprovante da Guia de DAS"
              v-model="inboxToSend.title"
              required
            >
          </div>
          <div class="container">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Título da Guia</th>
                  <th scope="col">Apagar</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :key="i"
                  v-for="(inbox, i) in inboxToSend.documents"
                >
                  <td style="width: 50px !important;">
                    <div class="icon-box">
                      <i class="fa-solid fa-file-arrow-up"></i>
                    </div>
                  </td>

                  <td>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Exemplo: Guia de Extrato"
                      v-model="inbox.title"
                      required
                    >
                  </td>

                  <td class="text-center" style="width: 50px !important;">
                    <i @click="inboxToSend.documents.splice(i, 1)" class="delete fa-solid fa-trash-can"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="chat-footer" v-if="!documentsLoading">
        <form class="row" @submit.prevent="sendMessage()">
          <div v-if="!inboxToSend.documents.length && !hoverDocument && permissions.sendGuides">
            <button @click="openFileUpload()" class="btn btn-paperclip"><i>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 8H15C14.448 8 14 7.552 14 7V3M15 12.9999H9M12 16V10M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </i></button>
          </div>
          <div class="form-group col p-0 m-0">
            <textarea
              type="text"
              id="chat-input"
              v-model="textToSend"
              class="form-control ml-0"
              placeholder="Escreva uma mensagem"
              maxlength ="10000"
              ref="input"
              @keydown.enter.exact="sendMessage()"
              @keydown.prevent.ctrl.enter.exact="chatLineBreak()"
              outlined
            ></textarea>
          </div>
          <div>
            <button class="btn btn-send"><i><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.644 19.908L20.1 5.18802C20.397 4.38602 19.616 3.60602 18.815 3.90302L4.09004 9.36302C3.16904 9.70502 3.24104 11.031 4.19404 11.27L11.028 12.987L12.735 19.803C12.975 20.757 14.302 20.83 14.644 19.908V19.908Z" stroke="#0DCE9A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19.87 4.13L11.03 12.99" stroke="#0DCE9A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </i></button>
          </div>
        </form>
      </div>
      <div class="error" v-if="this.error">{{this.error}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['customersArea', 'initialPage', 'permissions', 'name'],
  name: 'home',
  components: {
  },
  data: () => {
    return {
      endGuides: {
        type: '',
        inbox: {}
      },
      receivedInfo: null,
      menuScrollPosition: 0,
      textToSend: '',
      fillHeight: '0px',
      inboxToSend: {
        customer: true,
        title: '',
        category: '',
        documents: []
      },
      section: 'chat',
      hoverDocument: false,
      inboxes: null,
      messages: null,
      limit: 25,
      endAt: null,
      disableOnScroll: false,
      lastDateChat: null,
      error: null,
      documentsLoading: false,
      hasDelete: false,
      dtLastSee: null,
      dtCounterLastSee: null,
      counterViewingPending: 0,
      customerViewingPending: 0,
      online: false
    }
  },
  async created () {
    this.$root.$on('Change::initialPage', () => { this.section = this.initialPage; this.scrollToZero() })
    await this.$root.$on('Messages::off', () => this.offFunctions())
  },
  async mounted () {
    window.addEventListener('scroll', this.onScroll, true)
    window.addEventListener('mousemove', this.onLastSee, true)
    window.addEventListener('click', this.onLastSee, true)
    await this.getInboxes()
    await this.getMessages()
    this.$refs.input.select()
    await this.getCounterLastSee()
    await this.getCounterViewingPending()
    await this.getCustomerViewingPending()
    setInterval(this.onlineStatus, 1000)
    await this.scrollToZero()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll, true)
    window.removeEventListener('mousemove', this.onLastSee, true)
    window.removeEventListener('click', this.onLastSee, true)
  },
  computed: {
    filteredMessages () {
      if (this.messages && (!this.hasDelete || this.hasDelete)) {
        let tempMessages = Object.keys(this.messages).map(i => this.messages[i])
        tempMessages = tempMessages.sort((a, b) => {
          const fa = a.ts
          const fb = b.ts
          if (fa < fb) {
            return -1
          }
          if (fa > fb) {
            return 1
          }
          return 0
        })
        return tempMessages
      }
      return null
    },
    filteredInboxes () {
      if (this.inboxes && (!this.hasDelete || this.hasDelete)) {
        let tempInboxes = Object.keys(this.inboxes).map(i => this.inboxes[i])
        tempInboxes = tempInboxes.sort((a, b) => {
          const fa = a.ts
          const fb = b.ts
          if (fa < fb) {
            return -1
          }
          if (fa > fb) {
            return 1
          }
          return 0
        })
        return tempInboxes
      }
      return null
    }
  },
  methods: {
    async openDocument (inboxes, document, key, url) {
      await this.$firebase.database().ref(`simplesconnect/users/${JSON.parse(this.$cookie.get('profile')).cid}/chatData/${window.uid}/inboxes/${inboxes.ts}/documents/${key}`).child('received').child('chat').push({ date: Date.now() })
      window.open(url)
    },
    onlineStatus () {
      if (this.moment(this.dtCounterLastSee).add(150, 'seconds').valueOf() > Date.now()) {
        this.online = true
      } else {
        this.online = false
      }
    },
    async offFunctions () {
      this.messages = null
      window.removeEventListener('scroll', this.onScroll, true)
      window.removeEventListener('mousemove', this.onLastSee, true)
      window.removeEventListener('click', this.onLastSee, true)
      await this.$firebase.database().ref(`simplesconnect/users/${JSON.parse(this.$cookie.get('profile')).cid}/chatData/${window.uid}/messages`).off()
      await this.$firebase.database().ref(`simplesconnect/users/${JSON.parse(this.$cookie.get('profile')).cid}/chatData/${window.uid}/inboxes`).off()
      await this.$firebase.database().ref(`simplesconnect/users/${JSON.parse(this.$cookie.get('profile')).cid}/chatData/${window.uid}/dtCounterLastSee`).off()
      await this.$firebase.database().ref(`customers/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}/counterViewingPending`).off()
      await this.$firebase.database().ref(`simplesconnect/users/${JSON.parse(this.$cookie.get('profile')).cid}/chatData/${window.uid}/customerViewingPending`).off()
    },
    async verifyDateInboxes () {
      if (this.inboxes && Object.keys(this.inboxes) && Object.keys(this.inboxes).length) {
        const messagesKeys = Object.keys(this.inboxes)
        for (let i = 0; i < messagesKeys.length; i++) {
          this.inboxes[messagesKeys[i]].ts = messagesKeys[i]
        }
      }
    },
    async getCounterLastSee () {
      await this.$firebase.database().ref(`simplesconnect/users/${JSON.parse(this.$cookie.get('profile')).cid}/chatData/${window.uid}/dtCounterLastSee`).on('value', snapshot => {
        const value = snapshot.val()
        if (value) {
          this.dtCounterLastSee = value
        }
      })
    },
    async getCounterViewingPending () {
      await this.$firebase.database().ref(`customers/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}/counterViewingPending`).on('value', snapshot => {
        const value = snapshot.val()
        if (value !== null) {
          this.counterViewingPending = value
        }
      })
    },
    async getCustomerViewingPending () {
      await this.$firebase.database().ref(`simplesconnect/users/${JSON.parse(this.$cookie.get('profile')).cid}/chatData/${window.uid}/customerViewingPending`).on('value', snapshot => {
        const value = snapshot.val()
        if (value !== null) {
          this.customerViewingPending = value
        }
      })
    },
    // Funções relacionadas as mensagens
    async deleteMessage (message) {
      this.hasDelete = true
      await this.$firebase.database().ref(`simplesconnect/users/${JSON.parse(this.$cookie.get('profile')).cid}/chatData/${window.uid}/messages/${message.ts}`).remove()
      await delete this.messages[message.ts]
      if (message.ibId) {
        await this.$firebase.database().ref(`simplesconnect/users/${JSON.parse(this.$cookie.get('profile')).cid}/chatData/${window.uid}/inboxes/${message.ts}`).remove()
        await await this.$firebase.storage().ref(`simplesconnect/users/${JSON.parse(this.$cookie.get('profile')).cid}/inboxes/${window.uid}/${message.ts}`).listAll().then((value) => {
          value.items.forEach((element) => {
            this.$firebase.storage().ref(element.fullPath).delete()
          })
        })
        // await this.$firebase.storage().ref(`simplesconnect/users/${JSON.parse(this.$cookie.get('profile')).cid}/inboxes/${window.uid}/${message.ts}`).delete()
        await delete this.inboxes[message.ts]
      }
      await this.verifyDateChat()
      this.hasDelete = false
      await this.scrollToZero()
    },
    async sendMessage () {
      this.error = null
      const textToSend = this.textToSend
      this.textToSend = ''
      if ((textToSend.length < 1 || textToSend.replaceAll(' ', '').length < 1) && !this.inboxToSend.documents.length) {
        return null
      }
      const ts = Date.now()
      if (this.inboxToSend.documents.length) {
        for (let i = 0; i < this.inboxToSend.documents.length; i++) {
          if (!this.inboxToSend.documents[i].title.length) {
            this.error = 'Todas as guias devem ter algum título'
            await this.sleep(3000)
            this.error = null
            return null
          }
        }
      }

      if (this.inboxToSend.documents.length) {
        if (!this.inboxToSend.title.length) {
          this.error = 'Defina o título do envio para o grupo de guias'
          await this.sleep(3000)
          this.error = null
          return null
        }
        this.documentsLoading = true
        let queries = []
        for (let i = 0; i < this.inboxToSend.documents.length; i++) {
          queries.push(this.$firebase.storage().ref(`simplesconnect/users/${JSON.parse(this.$cookie.get('profile')).cid}/inboxes/${window.uid}/${ts}/${this.inboxToSend.documents[i].name}`).put(this.inboxToSend.documents[i].file))
        }
        let responses = await Promise.all(queries)
        queries = []
        for (let i = 0; i < responses.length; i++) {
          queries.push(responses[i].ref.getDownloadURL())
        }
        responses = await Promise.all(queries)

        for (let i = 0; i < responses.length; i++) {
          let url = ''
          url = responses[i]
          this.inboxToSend.documents[i].url = url
          delete this.inboxToSend.documents[i].file
          delete this.inboxToSend.documents[i].name
        }
        this.inboxToSend.sendDocumentFinalDate = String(Date.parse(this.sendDocumentFinalDate))
        await this.$firebase.database().ref(`simplesconnect/users/${JSON.parse(this.$cookie.get('profile')).cid}/chatData/${window.uid}/inboxes/${ts}`).update(
          this.inboxToSend
        )
        await this.$firebase.database().ref(`simplesconnect/users/${JSON.parse(this.$cookie.get('profile')).cid}/chatData/${window.uid}/messages/${ts}`).update(
          {
            t: textToSend,
            ibId: ts
          }
        )
        await this.$firebase.database().ref(`customers/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}`).update(
          {
            dtLastMessage: ts,
            lastMessage: this.inboxToSend.title.substring(0, 28),
            counterSender: false,
            counterViewingPending: this.counterViewingPending + 1
          }
        )
        this.inboxToSend = {
          customer: true,
          title: '',
          category: '',
          documents: []
        }
        this.documentsLoading = false
        await this.scrollToZero()
      } else {
        await this.$firebase.database().ref(`simplesconnect/users/${JSON.parse(this.$cookie.get('profile')).cid}/chatData/${window.uid}/messages/${ts}`).update(
          {
            t: textToSend
          }
        )
        await this.$firebase.database().ref(`customers/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}`).update(
          {
            dtLastMessage: ts,
            lastMessage: textToSend.substring(0, 28),
            counterSender: false,
            counterViewingPending: this.counterViewingPending + 1
          }
        )
      }
      await this.scrollToZero()
      await this.sleep(1000)
      this.scrollToZero()
    },
    async getInboxes (message) {
      await this.$firebase.database().ref(`simplesconnect/users/${JSON.parse(this.$cookie.get('profile')).cid}/chatData/${window.uid}/inboxes`).on('value', snapshot => {
        const value = snapshot.val()
        this.inboxes = null
        if (value) {
          this.inboxes = value
          this.verifyDateInboxes()
        }
      })
    },
    async getMessages () {
      let beforeHeight = null
      const ref = this.$firebase.database().ref(`simplesconnect/users/${JSON.parse(this.$cookie.get('profile')).cid}/chatData/${window.uid}/messages`).orderByKey()
      let query = ref.limitToLast(this.limit)
      if (this.endAt) {
        query = query.endAt(this.endAt)
      }
      if (!this.messages) {
        await query.on('value', snapshot => {
          var values = snapshot.val()
          if (values) {
            this.messages = { ...this.messages, ...values }
            this.scrollToZero()
            this.verifyDateChat()
          }
        })
      } else {
        await query.once('value', snapshot => {
          var values = snapshot.val()
          if (values) {
            beforeHeight = document.querySelector('.chat-body').scrollHeight
            if (Object.keys(values).length < this.limit) {
              this.disableOnScroll = true
            }
            this.messages = { ...values, ...this.messages }
            this.verifyDateChat()
          }
        })
      }
      await this.verifyDateChat()
      if (beforeHeight) {
        const afterHeight = await document.querySelector('.chat-body').scrollHeight - beforeHeight
        if (await document.querySelector('.chat-body').scrollTop < 30) {
          document.querySelector('.chat-body').scrollTop = afterHeight
        }
      }
    },
    nextPage () {
      if (this.messages && Object.keys(this.messages) && Object.keys(this.messages).length > 0) {
        this.endAt = Object.keys(this.messages)[0]
        this.getMessages()
      }
    },
    async verifyDateChat () {
      let lastDateChat = null
      if (this.messages && Object.keys(this.messages) && Object.keys(this.messages).length) {
        let messagesKeys = Object.keys(this.messages)
        for (let i = 0; i < messagesKeys.length; i++) {
          if (this.messages[messagesKeys[i]].date) {
            delete this.messages[messagesKeys[i]]
          }
        }
        messagesKeys = Object.keys(this.messages)
        for (let i = 0; i < messagesKeys.length; i++) {
          this.messages[messagesKeys[i]].ts = messagesKeys[i]
          const modifiedDate = this.moment(messagesKeys[i], 'x').format('MM/DD/YYYY ') + '00:00:00:0000'
          if (!lastDateChat || lastDateChat !== this.moment(messagesKeys[i], 'x').format('DD/MM/YYYY')) {
            this.messages[this.moment(modifiedDate).valueOf()] = { ts: this.moment(modifiedDate).valueOf(), date: true }
            lastDateChat = this.moment(Object.keys(this.messages)[i], 'x').format('DD/MM/YYYY')
          }
        }
      }
    },
    // Funções de escuta e verificação local
    async changePaymentDocument (inboxes, document, indexGuide) {
      let pay = false
      if (!document.pay) {
        pay = true
      }
      if (document && document.payGuide) {
        await this.$firebase.database().ref(`simplesconnect/users/${JSON.parse(this.$cookie.get('profile')).cid}/chatData/${window.uid}/inboxes/${inboxes.ts}/documents/${indexGuide}`).update({
          pay: pay
        })
      }
    },
    async onScroll () {
      const pre = await document.querySelector('.chat-body')
      await this.sleep(500)
      const el = await document.querySelector('.chat-body')

      if (this.section === 'chat' && el && pre && el.scrollTop === 0 && pre.scrollTop === 0 && !this.disableOnScroll) {
        this.nextPage()
        await this.sleep(50)
      }
    },
    async onLastSee () {
      const tempTime = Date.now()
      if (!JSON.parse(this.$cookie.get('currentProfile'))) {
        if (this.customerViewingPending && this.customerViewingPending !== 0) {
          await this.$firebase.database().ref(`simplesconnect/users/${JSON.parse(this.$cookie.get('profile')).cid}/chatData/${window.uid}`).update({ customerViewingPending: 0 })
          await this.$firebase.database().ref(`customers/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}`).update({ dtCustomerLastSee: tempTime })
        } else if (!this.dtLastSee || this.moment(this.dtLastSee).add(120, 'seconds').isBefore(this.moment(Date.now()))) {
          this.dtLastSee = tempTime
          await this.$firebase.database().ref(`customers/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}`).update({ dtCustomerLastSee: tempTime })
        }
      }
    },
    async scrollToZero () {
      // await this.sleep(300)
      const el = await document.querySelector('.chat-body')
      if (el && el.scrollHeight) {
        el.scrollTop = await el.scrollHeight
        await this.getFillHeight()
      }
    },
    async chatLineBreak () {
      this.textToSend = this.textToSend + '\n'
      const el = await document.getElementById('chat-input')
      el.scrollTop = el.scrollHeight
    },
    async getFillHeight () {
      this.fillHeight = await '0px'
      if (document.querySelector('.chat-elements')) {
        const body = await document.querySelector('.chat-body')
        const elements = await document.querySelector('.chat-elements')
        if (body.scrollHeight > elements.scrollHeight) {
          this.fillHeight = await body.scrollHeight - elements.scrollHeight - 30 + 'px'
        }
      }
      return '0px'
    },
    // Funções relacionadas ao envio de arquivos
    openFileUpload () {
      document.getElementById('dropzone-chat').click()
    },
    async onChangeChat () {
      if (this.permissions.sendGuides) {
        let hasDocuments = false
        if (this.inboxToSend.documents.length) {
          hasDocuments = true
        }
        this.error = null
        this.filelist = [...this.$refs.filesChat.files]
        if (this.filelist.length > 0) {
          for (let i = 0; i < this.filelist.length; i++) {
            // const nameFile = this.filelist[i].name.toLowerCase()
            const finalName = this.filelist[i].name.slice(this.filelist[i].name.length - 5).toLowerCase()
            if (!finalName.includes('.pdf') && !finalName.includes('.jpg') && !finalName.includes('.jpeg') && !finalName.includes('.png')) {
              this.error = 'Tipo de arquivo não compatível'
              this.hoverDocument = false
              if (!hasDocuments) {
                this.inboxToSend = {
                  title: '',
                  category: '',
                  documents: []
                }
              }
              await this.sleep(3000)
              this.error = null
              return null
            }
            if (this.filelist[i].size > 10000000) {
              this.error = 'Cada arquivo deve ter no máximo 10MB'
              this.hoverDocument = false
              if (!hasDocuments) {
                this.inboxToSend = {
                  title: '',
                  category: '',
                  documents: []
                }
              }
              await this.sleep(3000)
              this.error = null
              return null
            }
            // let payGuide = true
            // if (nameFile.includes('currícul') || nameFile.includes('curricul') || nameFile.includes('seguro') || nameFile.includes('relação') || nameFile.includes('ficha') || nameFile.includes('extrato') || nameFile.includes('chave') || nameFile.includes('recibo') || nameFile.includes('relat') || nameFile.includes('ficha') || nameFile.includes('certidão')) {
            //   payGuide = false
            // }
            this.inboxToSend.documents.push({ size: this.filelist[i].size, payGuide: false, name: this.filelist[i].name, title: this.filelist[i].name.replaceAll('.pdf', '').replaceAll('.jpg', '').replaceAll('.jpeg', '').replaceAll('.png', ''), file: this.filelist[i] })
          }
        }
        this.hoverDocument = false
      }
    },
    async dragoverChat (event) {
      if (this.permissions.sendGuides) {
        event.preventDefault()
        this.hoverDocument = true
        await this.sleep(500)
      }
    },
    async dragleaveChat  (event) {
      event.preventDefault()
      await this.sleep(500)
      if (this.hoverDocument) {
        this.hoverDocument = false
      }
    },
    async dropChat (event) {
      if (this.permissions.sendGuides) {
        event.preventDefault()
        this.$refs.filesChat.files = event.dataTransfer.files
        this.onChangeChat()
      }
    },
    // Funções de mudança local
    getInitiais (name) {
      let initiais = ''
      name = name.split(' ')
      initiais = name[0].substring(0, 1)
      if (name[1]) {
        initiais += name[1].substring(0, 1)
      }
      return initiais
    },
    sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async closeDocument () {
      this.inboxToSend.documents = []
      this.hoverDocument = false
      await this.sleep(1)
      await this.scrollToZero()
    }
  }
}
</script>

<style lang="scss" scoped>
  .error{
    position: absolute;
    bottom: 75px;
    right: 0;
    left: 0;
    float: center;
    max-width: 400px;
    margin-top: 10px;
    font-size: 14px;
    background-color: var(--red-light);
    padding: 10px 15px !important;
    margin: auto;
    text-align: center;
    border-radius: 6px;
    font-weight: 500;
    color: white;
  }
  .chat-header{
    display: flex;
    align-items: center;
    padding: 6px 35px 6px 25px;
    background-color: white;
    min-height: 67px !important;
    .nav-tabs{
      border: 0;
      margin: auto 23px;
      // border-radius: 7px !important;
    }
    .nav-tabs .nav-item {
      margin-bottom: 0px;
      text-align: center;
      color: var(--featured-light);
      font-weight: 600;
      padding: 7px 11px;
      font-size: 12.5px;
      border-radius: 0px !important;
      border: var(--featured-light) solid 2px !important;
      cursor: pointer !important;
      &:first-child{
        border-right: var(--featured-light) solid 1px !important;
        border-top-left-radius: 5px !important;
        border-bottom-left-radius: 5px !important;
      }
      &:last-child{
        border-left: var(--featured-light) solid 1px !important;
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
      }
      &.active{
        color: white;
        background: var(--featured-light);
      }
    }
    .btn-primary-border{
      padding: 8px 18px !important;
      margin: auto 20px auto 0;
      color: var(--featured) !important;
      border: 2px solid var(--featured) !important;
      opacity: .75;
      background-color: white !important;
      font-weight: 500 !important;
      transition: all .3s !important;
      i{
          font-size: 17px;
          margin-right: 15px;
          transform: translate(0, 2px);
      }
      &:hover{
        opacity: 1 !important;
        transform: translate(0, 0px) !important;
      }
      &:focus {
        opacity: 1 !important;
        transform: translate(0, 0px) !important;
      }
      &:active {
        opacity: 1 !important;
        transform: translate(0, 0px) !important;
      }
    }
    img{
      margin: auto 0;
      height: 45px !important;
    }
    .status-online{
      margin-top: 15px;
    }
    .name{
      transform: translate(0px, 3px);
      font-size: 13px;
      font-weight: 700;
      margin-left: 8px;
    }
    .document{
      transform: translate(0px, 6px);
      font-size: 13px;
      font-weight: 400;
      margin-left: 8px;
      color: #999;
    }
    .status-dot{
      margin-top: 17px;
      margin-right: 8px;
      height: 18px;
      width: 18px !important;
      vertical-align: middle;
      item-align: center;
      line-height: 40px;
      border-radius: 6px;
      opacity: .7;
    }
    .icon-box{
      //background-color: #505050 !important;
      background-color: var(--featured-light) !important;
      height: 48px;
      width: 48px;
      border-radius: 12px;
      text-align: center;
      margin-right: 7px;
      &.background-success{
        background-color: var(--featured-light) !important;
      }
      &.background-red{
        background-color: var(--red-light) !important;
      }
      &.background-gray{
        background-color: #d1d1d1 !important;
      }
      i{
        font-size: 20px;
        color: white !important;
        height: 48px;
        width: 48px !important;
        vertical-align: middle;
        item-align: center;
        line-height: 48px;
      }
      svg{
        margin-top: -3px;
        margin-left: -1px;
        fill: white !important;
        height: 48px;
        width: 20px !important;
        vertical-align: middle;
        item-align: center;
        line-height: 48px;
      }
      .initial{
        font-size: 20px;
        color: white !important;
        height: 48px;
        width: 48px !important;
        vertical-align: middle;
        font-weight: 500;
        item-align: center;
        line-height: 48px;
      }
    }
  }
  .chat-body{
    padding: 15px;
    height: 100%;
    overflow: hidden auto;
    .dropdown-menu{
      position:absolute;
      box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.08) !important;
      border: none;
      border-radius: 3px !important;
      z-index: 999 !important;
      transform: translate(33px, 17px);
    }
    .dropdown-item{
      font-size: 13px !important;
      cursor: pointer;
      &::selection {
        color: none;
        background: none;
      }
      &:hover{
        background: var(--featured-light);
        color: white !important;
        i{
          color: white !important;
        }
      }
      &:active{
        background: var(--featured);
        color: white;
      }
    }
    .day-date{
      max-width: 110px;
      font-size: 11.7px;
      background-color: rgba(0, 0, 0, .35);
      padding: 6px 6px 4px 6px !important;
      margin: auto;
      text-align: center;
      border-radius: 6px;
      font-weight: 500;
      color: white;
    }
    .chat-box{
      box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.1) !important;
      max-width: 95% !important;
      padding: 4px 40px 2px 10px;
      font-size: 14.5px;
      white-space: pre-wrap;
    }
    .chat-message{
      height: 100%;
      margin-top: 8px;
      p{
        margin-bottom: 0px;
        font-size: 13px;
      }
      .date{
        font-size: 11px;
        margin: 0;
      }
      .customer .delete-arrow{
        font-size: 12.5px;
        text-align: right;
        transform: translate(33px, 0);
        color: rgba(255,255,255,0.95);
        margin-top: -18px;
        .fa-chevron-down{
          transform: translate(0, 17px);
          opacity: 0.8;
          transition: all .2s !important;
          &:hover{
            opacity: 1;
          }
        }
      }
      .counter .delete-arrow{
        font-size: 12.5px;
        text-align: right;
        transform: translate(33px, 0);
        color: rgba(0,0,0,0.45);
        margin-top: -18px;
        .fa-chevron-down{
          transform: translate(0, 17px);
          opacity: 0.6;
          transition: all .2s !important;
          &:hover{
            opacity: 1;
          }
        }
      }
      .counter-date{
        text-align: right;
        transform: translate(35px, 0);
        color: rgba(0,0,0,0.45);
      }
      .customer-date{
        text-align: right;
        transform: translate(35px, 0);
        color: rgba(255,255,255,0.90);
      }
    }
    .document-date{
      margin: 0;
      font-size: 11px;
      text-align: right;
      transform: translate(5px, 5px);
      color: rgba(0,0,0,0.45) !important;
    }
    .chat-documents{
      max-width: 100% !important;
      min-width: 60% !important;
      padding: 12px 15px;
      margin-top: 4px;
      background-color: white !important;
      border-bottom-right-radius: 8px !important;
      .info-status{
        border: none;
        cursor: default !important;
        font-size: 11px !important;
        margin: 0px 0px 0px 3px !important;
        border-radius: 4px;
        height: 24px;
        font-weight: 700 !important;
        background-color: white;
        transition: all .2s;
        padding: 4px 8px;
        min-width: 40px;
        width: 83px;
        text-align: center;
      }
      .title-document{
        transform: translate(0, -12px);
        background-color: var(--featured-dark);
        color: white;
        border-top-right-radius: 8px;
        padding: 15px;
        i{
          font-size: 27px;
          padding: 5px;
          margin-right: 15px;
        }
        .delete-arrow{
          .fa-chevron-down{
            font-size: 12.5px;
            opacity: 0.8;
            transition: all .2s !important;
            &:hover{
              opacity: 1;
            }
          }
          i{
            font-size: 12.5px;
          }
          margin-top: -32px !important;
          width: 100%;
          text-align: right;
          transform: translate(27px, 18px);
          color: rgba(255,255,255,0.95);
        }
        .category{
          margin: 0;
          font-size: 12px;
        }
        .title{
          margin: 0;
          font-weight: 700;
          font-size: 14px;
        }
      }
      table {
        padding: 0;
        display: block;
        width: 100%;
        min-width: 100%;
        box-shadow: none !important;
        a{
          cursor: pointer !important;
        }
        .info-status{
          margin-top: 0px !important;
        }
        .custom-control{
          margin-top: -5px !important;
        }
        td{
          border-top: 1px solid #e9e9e9 !important;
          padding: 5px 7px !important;
          font-size: 13px;
          height: 40px;
        }
        th{
          padding: 5px 7px !important;
        }
        .custom-checkbox{
          margin: 10px 0 0 2px;
        }
        .fa-circle-down{
          font-size: 23px;
          color: var(--featured);
        }
        .fa-circle-check{
          font-size: 23px;
          color: var(--featured-light);
        }
        .custom-control-label::before{
          transform: translate(0, 1px);
          cursor: pointer !important;
          height: 1.2rem !important;
          width: 1.2rem !important;
          top: .15rem;
          left: -1.60rem;
          box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.10) !important;
          border-color: rgba(0,0,0,.01) !important;
          border-radius: 5px;
        }
        .custom-control-label::after{
          transform: translate(0, 1px);
          cursor: pointer !important;
          border-color: var(--featured) !important;
        }
        .custom-control-input:checked~.custom-control-label::before {
          transform: translate(0, 1px);
          cursor: pointer !important;
          box-shadow: none !important;
          color: white !important;
          background-color: var(--featured) !important;
          border-color: var(--featured) !important;
          &:focus {
              border: none !important;
          }
        }
      }
      .icon-box{
        //background-color: #505050 !important;
        background-color: var(--featured-light-1) !important;
        height: 36px;
        width: 36px;
        border-radius: 3px;
        text-align: center;
        margin-right: 0px;
        &.background-success{
          background-color: var(--featured-light) !important;
        }
        &.background-red{
          background-color: var(--red-light) !important;
        }
        i{
          font-size: 24px;
          color: var(--featured-dark) !important;
          height: 36px;
          width: 36px !important;
          vertical-align: middle;
          item-align: center;
          line-height: 36px;
        }
        svg{
          fill: #505050 !important;
          height: 29px;
          width: 29px !important;
          vertical-align: middle;
          item-align: center;
          line-height: 29px;
        }
      }
    }

    .counter{
      background-color: #ffffff;
      border-radius: 8px;
      border-top-left-radius: 0;
      float: left;
      box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.1) !important;
    }
    .customer{
      .title-document{
        border-top-right-radius: 0px;
        border-top-left-radius: 8px;
      }
      background-color: var(--featured);
      color: white;
      border-radius: 8px;
      border-bottom-right-radius: 0;
      float: right;
      box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.1) !important;
    }

  }
  .chat-edit-documents{
    padding: 15px 0;
    height: 100%;

    overflow: hidden auto;
    background-color: white !important;
    .form-group p{
      font-size: 14px !important;
      margin: 0;
      font-weight: 700 !important;
    }
    .drap-text{
      margin: auto 0;
      font-size: 19px;
      font-weight: 700;
      color: var(--featured)
    }
    .title-document{
      transform: translate(0, -12px);
      background-color: var(--featured-light);
      color: white !important;
      border-top-left-radius: 5px;
      padding: 15px;
      i{
        font-size: 27px;
        padding: 5px;
        margin-right: 15px;
      }
      .category{
        margin: 0;
        font-size: 12px;
      }
      .title{
        margin: 0;
        font-weight: 700;
        font-size: 14px;
      }
    }
    .form-group{
      max-width: 800px;
      margin: 0 auto 20px;
    }
    input{
      border-radius: 10px !important;
      box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.13) !important;
      font-size: 15px;
      &:focus, &:active{
        box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.13) !important;
        outline: none !important;
      }
      &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: #ccc !important;
      }
      &::-webkit-input-placeholder {
        color: #ccc !important;
      }
    }
    table {
      max-width: 800px;
      margin: auto;
      box-shadow: none !important;
      .info-status{
        margin-top: 0px !important;
      }
      .fa-trash-can{
        font-size: 15px;
        color: var(--red-light);
      }
      .custom-control{
        margin-top: -5px !important;
      }
      td{
        border-top: 1px solid #e9e9e9 !important;
        padding: 5px 7px !important;
        font-size: 13px;
        height: 40px;
      }
      th{
        padding: 5px 7px !important;
      }
      .fa-circle-down{
        font-size: 23px;
        color: var(--featured);
      }
      .icon-box{
        background-color: white !important;
      }
    }
    .custom-checkbox{
      margin: 10px 0 0 2px;
    }

    .fa-circle-check{
      font-size: 20px;
      color: var(--featured-light);
    }

    .custom-control-label::before{
      cursor: pointer !important;
      height: 1.3rem !important;
      width: 1.3rem !important;
      top: .09rem;
      left: -1.67rem;
      box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.15) !important;
      border-color: white !important;
      border-radius: 5px;
    }
    .custom-control-label::after{
      cursor: pointer !important;
      border-color: var(--featured) !important;
    }
    .custom-control-input:checked~.custom-control-label::before {
      cursor: pointer !important;
      box-shadow: none !important;
      color: white !important;
      font-size: 15px !important;
      background-color: var(--featured) !important;
      border-color: var(--featured) !important;
      &:focus {
          border: none !important;
      }

    }
    .custom-control-label p{
      font-size: 13px !important;
      font-weight: 600;
      transform: translate(0, 2px) !important;
      margin-left: 3px;
      margin-top: 4px;
    }
    .icon-box{
      //background-color: #505050 !important;
      background-color: var(--featured-light-1) !important;
      height: 36px;
      width: 36px;
      border-radius: 3px;
      text-align: center;
      margin-right: 0px;
      &.background-success{
        background-color: var(--featured-light) !important;
      }
      &.background-red{
        background-color: var(--red-light) !important;
      }
      i{
        font-size: 28px;
        color: var(--featured-light) !important;
        height: 36px;
        width: 36px !important;
        vertical-align: middle;
        item-align: center;
        line-height: 36px;
        &.delete{
          font-size: 18px !important;
          cursor: pointer;
          color: var(--red-light) !important;
        }
      }
      svg{
        fill: #505050 !important;
        height: 29px;
        width: 29px !important;
        vertical-align: middle;
        item-align: center;
        line-height: 29px;
      }
    }
  }
  .chat-footer{
    background-color: white;
    padding: 10px 30px 0px;
    height: 78px;
    padding-top: 10px;
    padding-bottom: 0px;
    .btn-send{
      // background: var(--featured-light) !important;
      border-radius: 10px !important;
      padding-left: 12px !important;
      padding-right: 5px !important;
      padding-top: 9px !important;
      margin-left: 2px;
      svg{
        path {
          stroke: var(--featured-dark) !important;
        }
        height: 30px;
        width: 30px;
      }
      &:focus {
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
      }
    }
    .btn-paperclip{
      margin: 2px 6px 8px 0px;
      color: var(--featured-dark) !important;
      border: 0px solid var(--featured-dark) !important;
      background-color: var(--featured-dark) !important;
      padding: 3px 8px 5px !important;
      font-weight: 400 !important;
      transition: all .3s !important;
      border-radius: 10px !important;
      font-size: 24px;
      svg{
        height: 30px;
        width: 30px;
      }
      &:focus, &:active{
        border: none;
        outline: none !important;
        box-shadow: none !important;
      }
    }
    textarea{
      padding-top: 12px;
      margin-left: 15px;
      margin-bottom: 0 !important;
      resize: none;
      border-radius: 10px !important;
      box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.09) !important;
      &:focus, &:active{
        box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.09) !important;
        outline: none !important;
      }
      &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: #ccc !important;
      }
      &::-webkit-input-placeholder {
        color: #ccc !important;
      }
    }
    button{
      padding: 12px 20px !important;
      margin-left: 10px;
      i{
        transform: translate(-2px, 0);
        margin: 0;
      }
    }
  }
  .content-container{
    background-color: var(--featured-light-03) !important;
  }
  .btn-arrow {
    position: absolute;
    height: 55px;
    z-index: 100;
    border-radius: 0 !important;
    color: #000000;
    &.to-left{
      left: 15px;
      width: 140px;
      top: 0;
      text-align: left;
      padding-left: 8px !important;
      background: linear-gradient(90deg, rgba(242,243,248,.95) 0%, rgba(242,243,248,0.95) 10%, rgba(242,243,248,0.95) 20%, rgba(248,248,251,0.85) 30%, rgba(255,255,255,0.75) 40%, rgba(255,255,255,0.55) 50%, rgba(255,255,255,0.45) 60%, rgba(255,255,255,0.35) 70%, rgba(255,255,255,0.25) 80%, rgba(255,255,255,0.15) 90%, rgba(255,255,255,0) 100%);
    }
    &.to-right{
      right: 0px;
      width: 140px;
      top: 0;
      text-align: right;
      padding-right: 8px !important;
      background: linear-gradient(-90deg, rgba(242,243,248,.95) 0%, rgba(242,243,248,0.95) 10%, rgba(242,243,248,0.95) 20%, rgba(248,248,251,0.85) 30%, rgba(255,255,255,0.75) 40%, rgba(255,255,255,0.55) 50%, rgba(255,255,255,0.45) 60%, rgba(255,255,255,0.35) 70%, rgba(255,255,255,0.25) 80%, rgba(255,255,255,0.15) 90%, rgba(255,255,255,0) 100%);}
    &:active{
      opacity: 1 !important;
      box-shadow: none !important;
    }
  }

  .horizontal-scrollable {
    margin: 0 15px 10px 15px !important;
    scroll-behavior: smooth;
    ::-webkit-scrollbar {
      display: none;
    }
    .btn-primary {
      &:hover{
        transform: translate(0, 0px);
      }
      &.disabled {
        background-color: transparent !important;
        color: #505050;
        svg{
          fill: #505050 !important;
        }
      }
    }
  }

  .horizontal-scrollable > .row {
    overflow-x: auto;
    white-space: nowrap;
    height: 55px !important;
  }

  .chat-card{
    height: calc(100vh - 95px) !important;
    overflow: hidden auto;
    background-color: white !important;
    box-shadow: none !important;
    background-color: rgba(255, 255, 255, .40) !important;
    box-shadow: 0px 0px 4px 3px rgba(0,0,0,.03) !important;
  }

  .table-card{
    overflow: hidden auto;
    background-color: white !important;
    box-shadow: none !important;
    tbody{
      background: #f2f3f8;
    }
    tr{
      background: transparent;
    }
    td{
      padding: 0 !important;
      background: transparent;
      border: none;
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d1d1d1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }

  .dropzone-chat {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    clip: rect(1px, 1px, 1px, 1px);
    cursor: pointer !important;
  }
</style>
