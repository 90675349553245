<template>
  <div>
    <div class="pl-0 pr-0 card historic-card" :style="'height: calc('+ this.$screen.cardHeight + ' - 85px) !important;'">
      <div class="row chat-header">
        <div class="col">
          <div class="row">
            <img class="img-fluid" :src="customersArea.logoUrl">
            <div>
              <p class="document mt-auto mb-auto">{{customersArea.name}}</p>
              <p class="name mt-auto mb-auto" v-if="name && name.length > 30">{{name.substring(0,30)}}...</p>
              <p class="name mt-auto mb-auto" v-else>{{name}}</p>
            </div>
          </div>
        </div>
        <div class="pr-2">
          <div class="row justify-content-end" v-if="totalTabs && moment(moment(data[tabsType].lastProcess, 'DD-MM-YYYY')).isBefore(moment(new Date()).add('days', -5)) && permissions.recalculos && !(statusLoading && !totalTabs || (totalTabs && data[tabsType].status.info !== 'Não Verificado'))">
            <button class="btn btn-sm btn-primary" @click="requestRecalculations()">Atualizar/Recálcular Guias</button>
          </div>
        </div>
      </div>
      <div class="historic-body">
        <div class="infos row">
          <div class="col pl-0 pr-0">
            <div class="row">
              <div>
                <p class="status-title">Situação:</p>
                <template v-if="statusLoading || !totalTabs || (totalTabs && (data[tabsType].status.info === 'Não Verificado' || data[tabsType].lastProcess === 'Não Executado'))">
                  <div class="info-status status-loading" style="font-size: 12px; font-weight: 700;">
                    Buscando...
                  </div>
                </template>
                <template v-else-if="statusPending">
                  <div class="info-status status-warning" style="font-size: 12px; font-weight: 700;">
                    Pendências
                  </div>
                </template>
                <template v-else>
                  <div class="info-status status-success" style="font-size: 12px; font-weight: 700;">
                    Em Dia
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="row">
            <p v-if="statusLoading || !totalTabs || (totalTabs && (data[tabsType].status.info === 'Não Verificado' || data[tabsType].lastProcess === 'Não Executado'))">Buscando Informações...</p>
            <p v-else>Informações atualizadas em: {{data[tabsType].lastProcess.replaceAll('-', '/')}}</p>
          </div>
        </div>
        <div class="data-content">
          <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <a v-if="data.simplesInstallments && totalTabs" class="nav-item nav-link" :class="[tabsType === 'simplesInstallments' ? 'active' : '' || data.simplesInstallments ? '' : '' || !data.simplesInstallments ? 'disabled' : '']" @click="tabsType = 'simplesInstallments'" :style="'width: ' + 100 / totalTabs + '%;'">Simples</a>
              <a v-if="data.receitaInstallments && totalTabs" class="nav-item nav-link" :class="[tabsType === 'receitaInstallments' ? 'active' : '' || data.receitaInstallments ? '' : '' || !data.receitaInstallments ? 'disabled' : '']" @click="tabsType = 'receitaInstallments'" :style="'width: ' + 100 / totalTabs + '%;'">Receita</a>
              <a v-if="data.especialInstallments && totalTabs" class="nav-item nav-link" :class="[tabsType === 'especialInstallments' ? 'active' : ''  || data.especialInstallments ? '' : ''|| !data.especialInstallments ? 'disabled' : '']" @click="tabsType = 'especialInstallments'" :style="'width: ' + 100 / totalTabs + '%;'">Especiais</a>
              <a v-if="data.pgfnInstallments && totalTabs" class="nav-item nav-link" :class="[tabsType === 'pgfnInstallments' ? 'active' : ''  || data.pgfnInstallments ? '' : '' || !data.pgfnInstallments ? 'disabled' : '']" @click="tabsType = 'pgfnInstallments'" :style="'width: ' + 100 / totalTabs + '%;'">Procuradoria</a>
            </div>
          </nav>
          <simples-installments v-if="tabsType === 'simplesInstallments' && totalTabs" :data="data.simplesInstallments"/>
          <receita-installments v-if="tabsType === 'receitaInstallments' && totalTabs" :data="data.receitaInstallments"/>
          <especial-installments v-if="tabsType === 'especialInstallments' && totalTabs" :data="data.especialInstallments"/>
          <pgfn-installments v-if="tabsType === 'pgfnInstallments' && totalTabs" :data="data.pgfnInstallments"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimplesInstallments from './SimplesReferences'
import ReceitaInstallments from './ReceitaReferences'
import EspecialInstallments from './EspecialReferences'
import PgfnInstallments from './PgfnReferences'

export default {
  props: ['customersArea', 'permissions', 'name'],
  name: 'home',
  components: {
    SimplesInstallments,
    ReceitaInstallments,
    EspecialInstallments,
    PgfnInstallments

  },
  data: () => {
    return {
      data: {
        simplesInstallments: null,
        receitaInstallments: null,
        especialInstallments: null,
        pgfnInstallments: null
      },
      tabsType: null,
      totalTabs: null,
      statusPending: false,
      statusLoading: false
    }
  },
  mounted () {
    this.getData()
  },
  computed: {
  },
  methods: {
    async requestRecalculations () {
      const tempCustomer = await this.$firebase.database().ref(`customers/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}`).once('value').then(snapshot => snapshot.val())
      if (!tempCustomer) {
        this.$root.$emit('Do::Logout')
      }
      if (this.data.simplesInstallments) {
        const tempDataSimples = { ...tempCustomer, ...this.data.simplesInstallments }
        await this.$firebase.database().ref('simplesInstallments').child(tempDataSimples.uId).child(tempDataSimples.customerId).update({ loading: true })
        await this.$firebase.database().ref('executeSimplesInstallments').child(tempDataSimples.uId + tempDataSimples.customerId).set(tempDataSimples)
      }
      if (this.data.receitaInstallments) {
        const tempDataReceita = { ...tempCustomer, ...this.data.receitaInstallments }
        await this.$firebase.database().ref('receitaInstallments').child(tempDataReceita.uId).child(tempDataReceita.customerId).update({ loading: true })
        await this.$firebase.database().ref('executeReceitaInstallments').child(tempDataReceita.uId + tempDataReceita.customerId).set(tempDataReceita)
      }
      if (this.data.especialInstallments) {
        const tempDataEspecial = { ...tempCustomer, ...this.data.tempDataEspecial }
        await this.$firebase.database().ref('especialInstallments').child(tempDataEspecial.uId).child(tempDataEspecial.customerId).update({ loading: true })
        await this.$firebase.database().ref('executeEspecialInstallments').child(tempDataEspecial.uId + tempDataEspecial.customerId).set(tempDataEspecial)
      }
      if (this.data.pgfnInstallments) {
        const tempDataPgfn = { ...tempCustomer, ...this.data.tempDataPgfn }
        await this.$firebase.database().ref('pgfnInstallments').child(tempDataPgfn.uId).child(tempDataPgfn.customerId).update({ loading: true })
        await this.$firebase.database().ref('executePgfnInstallments').child(tempDataPgfn.uId + tempDataPgfn.customerId).set(tempDataPgfn)
      }
    },
    verifyTabs (tab) {
      var tempCounter = 0
      const tempData = Object.keys(this.data).map(i => this.data[i])
      for (let i = 0; i < tempData.length; i++) {
        if (tempData[i]) {
          tempCounter = tempCounter + 1
        }
      }
      this.totalTabs = tempCounter
      if (!this.tabsType) {
        this.tabsType = tab
      }
    },
    async getData () {
      var initialRun = true
      await this.$firebase.database().ref(`simplesInstallments/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}`).on('value', snapshot => {
        const data = snapshot.val()
        if (data && data.status && data.status.color !== 'status-danger') {
          this.data.simplesInstallments = data
          if (data.loading || data.status.info === 'Verificando Erro' || data.status.info === 'Não Verificado') {
            this.statusLoading = true
          } else if (data.status.info === 'Pendências') {
            this.statusPending = true
          } else if (!initialRun) {
            this.statusLoading = false
          }
          this.verifyTabs('simplesInstallments')
        }
      })
      await this.$firebase.database().ref(`receitaInstallments/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}`).on('value', snapshot => {
        const data = snapshot.val()
        if (data && data.status && data.status.color !== 'status-danger') {
          this.data.receitaInstallments = data
          if (data.loading || data.status.info === 'Verificando Erro' || data.status.info === 'Não Verificado') {
            this.statusLoading = true
          } else if (data.status.info === 'Pendências') {
            this.statusPending = true
          } else if (!initialRun) {
            this.statusLoading = false
          }
          this.verifyTabs('receitaInstallments')
        }
      })
      await this.$firebase.database().ref(`especialInstallments/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}`).on('value', snapshot => {
        const data = snapshot.val()
        if (data && data.status && data.status.color !== 'status-danger') {
          this.data.especialInstallments = data
          if (data.loading || data.status.info === 'Verificando Erro' || data.status.info === 'Não Verificado') {
            this.statusLoading = true
          } else if (data.status.info === 'Pendências') {
            this.statusPending = true
          } else if (!initialRun) {
            this.statusLoading = false
          }
          this.verifyTabs('especialInstallments')
        }
      })
      await this.$firebase.database().ref(`pgfnInstallments/${JSON.parse(this.$cookie.get('profile')).cid}/${JSON.parse(this.$cookie.get('profile')).customerId}`).on('value', snapshot => {
        const data = snapshot.val()
        if (data && data.status && data.status.color !== 'status-danger') {
          this.data.pgfnInstallments = data
          if (data.loading || data.status.info === 'Verificando Erro' || data.status.info === 'Não Verificado') {
            this.statusLoading = true
          } else if (data.status.info === 'Pendências') {
            this.statusPending = true
          } else if (!initialRun) {
            this.statusLoading = false
          }
          this.verifyTabs('pgfnInstallments')
        }
      })
      initialRun = false
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-tabs{
  border: 0;
  margin: 0;
  // border-radius: 7px !important;
}
.nav-tabs .nav-item {
  margin-bottom: 0px;
  text-align: center;
  color: var(--featured-dark);
  font-weight: 700;
  border-radius: 0px !important;
  border: var(--featured-dark) solid 2px !important;
  cursor: pointer !important;
  font-size: 12.7px;
  &:first-child{
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }
  &:last-child{
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
  &.active{
    color: white;
    background: var(--featured-dark);
  }
  &.disabled{
    color: white;
    border: var(--secondary) solid 2px !important;
    border-right: var(--secondary) solid 2px !important;
    background: var(--secondary);
  }
}
.info-status{
  border: none;
  cursor: default !important;
  font-size: 12px !important;
  margin: 0px 0px 0px 3px !important;
  border-radius: 4px;
  height: 30px;
  font-weight: 700 !important;
  background-color: white;
  transition: all .2s;
  padding: 6px 8px !important;
  min-width: 40px;
  width: 140px !important;
  text-align: center;
}
.infos{
  padding: 0px 13px 10px 13px;
  .row{
    margin: 0;
  }
  .status-title{
    margin: 0 !important;
    margin-left: 3px !important;
    padding: 0;
  }
  p{
    margin: auto 0px 4px 7px;
    font-size: 12.5px;
    font-weight: 600;
    color: #a1a1a1;
  }
  .info-status{
    width: 160px;
  }
}
.chat-header{
    display: flex;
    align-items: center;
    padding: 6px 35px 6px 25px;
    background-color: white;
    min-height: 67px !important;
    .nav-tabs{
      border: 0;
      margin: auto 23px;
      // border-radius: 7px !important;
    }
    .nav-tabs .nav-item {
      margin-bottom: 0px;
      text-align: center;
      color: var(--featured-light);
      font-weight: 600;
      padding: 7px 11px;
      font-size: 12.5px;
      border-radius: 0px !important;
      border: var(--featured-light) solid 2px !important;
      cursor: pointer !important;
      &:first-child{
        border-right: var(--featured-light) solid 1px !important;
        border-top-left-radius: 5px !important;
        border-bottom-left-radius: 5px !important;
      }
      &:last-child{
        border-left: var(--featured-light) solid 1px !important;
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
      }
      &.active{
        color: white;
        background: var(--featured-light);
      }
    }
    .btn-primary-border{
      padding: 8px 18px !important;
      margin: auto 20px auto 0;
      color: var(--featured) !important;
      border: 2px solid var(--featured) !important;
      opacity: .75;
      background-color: white !important;
      font-weight: 500 !important;
      transition: all .3s !important;
      i{
          font-size: 17px;
          margin-right: 15px;
          transform: translate(0, 2px);
      }
      &:hover{
        opacity: 1 !important;
        transform: translate(0, 0px) !important;
      }
      &:focus {
        opacity: 1 !important;
        transform: translate(0, 0px) !important;
      }
      &:active {
        opacity: 1 !important;
        transform: translate(0, 0px) !important;
      }
    }
    img{
      margin: auto 0;
      height: 45px !important;
    }
    .status-online{
      margin-top: 15px;
    }
    .name{
      transform: translate(0px, 3px);
      font-size: 13px;
      font-weight: 700;
      margin-left: 8px;
    }
    .document{
      transform: translate(0px, 6px);
      font-size: 13px;
      font-weight: 400;
      margin-left: 8px;
      color: #999;
    }
    .status-dot{
      margin-top: 17px;
      margin-right: 8px;
      height: 18px;
      width: 18px !important;
      vertical-align: middle;
      item-align: center;
      line-height: 40px;
      border-radius: 6px;
      opacity: .7;
    }
    .icon-box{
      //background-color: #505050 !important;
      background-color: var(--featured-light) !important;
      height: 48px;
      width: 48px;
      border-radius: 12px;
      text-align: center;
      margin-right: 7px;
      &.background-success{
        background-color: var(--featured-light) !important;
      }
      &.background-red{
        background-color: var(--red-light) !important;
      }
      &.background-gray{
        background-color: #d1d1d1 !important;
      }
      i{
        font-size: 20px;
        color: white !important;
        height: 48px;
        width: 48px !important;
        vertical-align: middle;
        item-align: center;
        line-height: 48px;
      }
      svg{
        margin-top: -3px;
        margin-left: -1px;
        fill: white !important;
        height: 48px;
        width: 20px !important;
        vertical-align: middle;
        item-align: center;
        line-height: 48px;
      }
      .initial{
        font-size: 20px;
        color: white !important;
        height: 48px;
        width: 48px !important;
        vertical-align: middle;
        font-weight: 500;
        item-align: center;
        line-height: 48px;
      }
    }
  }
.historic-body{
  background: white;
  padding: 15px;
  height: 100%;
  overflow: hidden auto;
  .chat-message{
    height: 100%;
    margin-top: 15px;
    p{
      margin-bottom: 0px;
      font-size: 13px;
    }
    .customer-date{
      text-align: right;
    }
  }
  .chat-box{
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.1) !important;
    max-width: 95% !important;
    padding: 12px 15px;
    font-size: 14px;
  }
  .chat-documents{
    max-width: 95% !important;
    padding: 12px 15px;
    margin-top: 4px;
    .title-document{
      transform: translate(0, -12px);
      background-color: var(--featured);
      color: white;
      border-top-right-radius: 8px;
      padding: 15px;
      i{
        font-size: 27px;
        padding: 5px;
        margin-right: 15px;
      }
      .category{
        margin: 0;
        font-size: 12px;
      }
      .title{
        margin: 0;
        font-weight: 700;
        font-size: 14px;
      }
    }
    table {
      display: block;
      width: 100%;
      box-shadow: none !important;
      padding: 5px !important;
      .info-status{
        margin-top: 0px !important;
      }
      .custom-control{
        margin-top: -5px !important;
      }
      td{
        border-top: 1px solid #e9e9e9 !important;
        padding: 5px 7px !important;
        font-size: 13px;
      }
      th{
        border-top: #e9e9e9 solid 1px;
        padding: 5px 7px !important;
      }
      .custom-checkbox{
        margin: 10px 0 0 2px;
      }
      .fa-circle-down{
        font-size: 23px;
        color: var(--featured-dark);
      }
      .fa-check{
        font-size: 20px;
        color: var(--featured-light);
      }
      .custom-control-label::before{
        height: 1.2rem !important;
        width: 1.2rem !important;
        top: .15rem;
        left: -1.60rem;
        box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.15) !important;
        border-color: white !important;
        border-radius: 5px;
      }
      .custom-control-label::after{
        border-color: var(--featured) !important;
      }
      .custom-control-input:checked~.custom-control-label::before {
        box-shadow: none !important;
        color: white !important;
        background-color: var(--featured) !important;
        border-color: var(--featured) !important;
        &:focus {
            border: none !important;
        }
      }
    }
    .icon-box{
      //background-color: #505050 !important;
      background-color: var(--featured-light-1) !important;
      height: 36px;
      width: 36px;
      border-radius: 3px;
      text-align: center;
      margin-right: 0px;
      &.background-success{
        background-color: var(--featured-light) !important;
      }
      &.background-red{
        background-color: var(--red-light) !important;
      }
      i{
        font-size: 24px;
        color: var(--featured-dark) !important;
        height: 36px;
        width: 36px !important;
        vertical-align: middle;
        item-align: center;
        line-height: 36px;
      }
      svg{
        fill: #505050 !important;
        height: 29px;
        width: 29px !important;
        vertical-align: middle;
        item-align: center;
        line-height: 29px;
      }
    }
  }
  .counter{
    background-color: #ffffff;
    border-radius: 8px;
    border-top-left-radius: 0;
    float: left;
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.1) !important;
  }
  .customer{
    background-color: var(--featured-dark);
    color: white;
    border-radius: 8px;
    border-bottom-right-radius: 0;
    float: right;
  }

}
.chat-footer{
  background-color: white;
  padding-right: 30px;
  height: 78px;
  padding-top: 15px;
  textarea{
    padding-top: 12px;
    margin-left: 15px;
    resize: none;

  }
  button{
    padding: 12px 20px !important;
    margin-left: 10px;
    i{
      transform: translate(-2px, 0);
      margin: 0;
    }
  }
}
.content-container{
  background-color: var(--featured-light-03) !important;
}
.btn-arrow {
  position: absolute;
  height: 55px;
  z-index: 100;
  border-radius: 0 !important;
  color: #000000;
  &.to-left{
    left: 15px;
    width: 140px;
    top: 0;
    text-align: left;
    padding-left: 8px !important;
    background: linear-gradient(90deg, rgba(242,243,248,.95) 0%, rgba(242,243,248,0.95) 10%, rgba(242,243,248,0.95) 20%, rgba(248,248,251,0.85) 30%, rgba(255,255,255,0.75) 40%, rgba(255,255,255,0.55) 50%, rgba(255,255,255,0.45) 60%, rgba(255,255,255,0.35) 70%, rgba(255,255,255,0.25) 80%, rgba(255,255,255,0.15) 90%, rgba(255,255,255,0) 100%);
  }
  &.to-right{
    right: 0px;
    width: 140px;
    top: 0;
    text-align: right;
    padding-right: 8px !important;
    background: linear-gradient(-90deg, rgba(242,243,248,.95) 0%, rgba(242,243,248,0.95) 10%, rgba(242,243,248,0.95) 20%, rgba(248,248,251,0.85) 30%, rgba(255,255,255,0.75) 40%, rgba(255,255,255,0.55) 50%, rgba(255,255,255,0.45) 60%, rgba(255,255,255,0.35) 70%, rgba(255,255,255,0.25) 80%, rgba(255,255,255,0.15) 90%, rgba(255,255,255,0) 100%);}
  &:active{
    opacity: 1 !important;
    box-shadow: none !important;
  }
}

.horizontal-scrollable {
  margin: 0 15px 10px 15px !important;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    display: none;
  }
  .btn-primary {
    &:hover{
      transform: translate(0, 0px);
    }
    &.disabled {
      background-color: transparent !important;
      color: #505050;
      svg{
        fill: #505050 !important;
      }
    }
  }
}

.horizontal-scrollable > .row {
  overflow-x: auto;
  white-space: nowrap;
  height: 55px !important;
}

.historic-card{
  height: calc(100vh - 95px) !important;
  overflow: hidden auto;
  background-color: white !important;
  box-shadow: none !important;
  background-color: rgba(255, 255, 255, .1) !important;
  box-shadow: 0px 0px 4px 3px rgba(0,0,0,.03) !important;
}

.table-card{
  height: calc(100vh - 135px) !important;
  overflow: hidden auto;
  background-color: white !important;
  box-shadow: none !important;
  tbody{
    background: #f2f3f8;
  }
  tr{
    background: transparent;
  }
  td{
    padding: 0 !important;
    background: transparent;
    border: none;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d1d1d1;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c1c1c1;
}
</style>
