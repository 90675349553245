<template>
  <div>
    <div class="data-content" :class="{show: tabsType}" :style="{display: tabsType ? 'none' : 'block'}">
      <div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Situação</th>
              <th scope="col">Tipo</th>
              <th scope="col">Busca</th>
            </tr>
          </thead>
          <tbody>
            <tr
              :key="i"
              v-for="(registered, key, i) in data.registered"
            >
              <template v-if="registered.sendMail && (registered.status && (registered.status.info !== 'Verificando Erro' && registered.status.info !== 'Sistema Indisponível'))">
                <td>
                  <template v-if="data.loading || data.status.info === 'Verificando Erro'">
                    <div class="info-status status-loading" style="font-size: 12px; font-weight: 700;">
                      Buscando...
                    </div>
                  </template>
                  <template v-else>
                    <div class="info-status" :class="[registered.status ? registered.status.color : '']" style="font-size: 12px; font-weight: 700;">
                      <template v-if="registered.status.info === 'Em Aberto'">
                        Em Dia
                      </template>
                      <template v-else>
                        {{registered.status.info}}
                      </template>
                    </div>
                  </template>
                </td>
                <template v-if="registered.category == 'federal'">
                  <td>{{registered.name}}</td>
                </template>
                <template v-else>
                  <td>{{capitalizeType(registered.name)}}</td>
                </template>
                <td>{{registered.lastProcess.replaceAll('-', '/')}}</td>
                <td style="width: 130px"><a class="btn btn-sm btn-primary w-100" @click="tabsType = key">Acessar Guias</a></td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="data-content" :class="{show: tabsType}" :style="{display: tabsType ? 'block' : 'none'}">
      <div class="row justify-content-end pr-3 pt-3"><div style="padding: 10px 16px !important;" class="btn btn-secondary btn-sm" @click="tabsType = null"><i class="fa-solid fa-xmark" style="margin-right: 0 !important;"></i></div></div>
      <div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col" style="width: 190px">Data da Busca</th>
              <th scope="col" style="width: 120px"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              :key="i"
              v-for="(historic, i) in sortedHistoric"
            >
              <td>Guia de {{historic.dtFinished.replaceAll('-', '/')}}</td>
              <td><a class="btn btn-sm btn-primary w-100" target="_black" :href="historic.documentUrl">Baixar Guia</a></td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--<div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal()" data-bs-dismiss="modal">Fechar</button>
        </div>-->
    </div>
  </div>
</template>

<script>

export default {
  props: ['data'],

  components: {
  },

  data: () => ({
    showModal: false,
    tabsType: null,
    content: null,
    subject: null
  }),

  mounted () {
    this.$root.$on('CertificatesHistoric::show', () => this.initicalChanges())
    this.initicalChanges()
  },

  computed: {
    historicLenght () {
      var tempRegistered = this.registered
      if (this.registered) {
        tempRegistered = Object.keys(this.registered).map(i => this.registered[i]).slice().reverse()
      }
      return tempRegistered.length
    },
    sortedHistoric () {
      if (this.tabsType) {
        var tempHistoric = this.data.registered[this.tabsType].historic
        if (this.data.registered[this.tabsType].historic) {
          tempHistoric = Object.keys(this.data.registered[this.tabsType].historic).map(i => this.data.registered[this.tabsType].historic[i]).slice().reverse()
        }
        return tempHistoric
      }
      return null
    }
  },

  methods: {
    capitalizeState (data) {
      var capitalized = []
      var dataTemp = data + ''
      dataTemp.split('-').forEach(word => {
        capitalized.push(
          word.charAt(0).toUpperCase() +
          word.slice(1).toLowerCase()
        )
      })
      var dataTemp2 = capitalized[0]
      capitalized = []
      dataTemp2.split(' ').forEach(word => {
        capitalized.push(
          word.charAt(0).toUpperCase() +
          word.slice(1).toLowerCase()
        )
      })
      return capitalized.join(' ')
    },
    capitalizeType (data) {
      var capitalized = []
      var dataTemp = data + ''
      dataTemp.split('-').forEach(word => {
        capitalized.push(
          word.charAt(0).toUpperCase() +
          word.slice(1).toLowerCase()
        )
      })
      var dataTemp2 = capitalized[1]
      capitalized = []
      dataTemp2.split(' ').forEach(word => {
        if (word.length > 2) {
          capitalized.push(
            word.charAt(0).toUpperCase() +
            word.slice(1).toLowerCase()
          )
        } else {
          capitalized.push(
            word.charAt(0).toLowerCase() +
            word.slice(1).toLowerCase()
          )
        }
      })
      return capitalized.join(' ')
    },
    closeModal () {
      this.showModal = false
    },
    initicalChanges () {
      if (this.registered) {
        this.tabsType = Object.keys(this.registered)[0]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title{
  margin: 0 !important;
  margin-left: 3px !important;
  margin-top: 15px !important;
  padding: 0;
  margin: auto 0px 4px 7px;
  font-size: 13px;
  font-weight: 600;
  color: #a1a1a1;
}
.data-content{
  .btn-primary{
    background: var(--featured-dark) !important;
  }
  .modal-header, .modal-footer{
    border: none !important;
  }

  .modal-body{
    padding-top: 0;
  }

  .nav-tabs{
    border: 0;
    margin: 0;
    // border-radius: 7px !important;
  }
  .nav-tabs .nav-item {
    margin-bottom: 0px;
    text-align: center;
    color: var(--featured-dark);
    font-weight: 700;
    border-radius: 0px !important;
    border: var(--featured-dark) solid 2px !important;
    cursor: pointer !important;
    border-right: 0 !important;
    font-size: 11px;
    &.active{
      color: white;
      background: var(--featured-dark);
    }
  }

  .table{
    margin-top: 15px;
    box-shadow: none;
    background-color: white;
    border-radius: 4px;
    padding: 0;
    border-spacing: 0;
    th{
      border-top: none !important;
      border-bottom: none !important;
      padding: 7px 5px !important;
      padding-top: 5px !important;
      font-size: 13.5px;
    }
    td{
      border-top: #e9e9e9 solid 1px;
      font-size: 12.5px;
      padding: 7px 5px !important;
    }
    .btn-secondary, .btn-primary, .btn-danger, .btn-warning{
      font-size: 12px !important;
      padding: .65rem .9rem !important;
    }
    .info-status{
      border: none;
      cursor: default !important;
      font-size: 12px !important;
      margin: 0px 0px 0px 3px !important;
      border-radius: 4px;
      height: 30px;
      font-weight: 700 !important;
      background-color: white;
      transition: all .2s;
      padding: 6px 8px !important;
      min-width: 40px;
      width: 170px !important;
      text-align: center;
    }
  }
  h4, h5{
    font-weight: 700;
  }
  h5{
    font-size: 15px;
  }
  .form-group{
    margin: 7px 0;
  }
}
.details-buttom{
  color: blue !important;
  cursor: pointer;
  &[disabled]{
    cursor: not-allowed !important;
    color: gray !important;
  }
  &::selection {
      color: none;
      background: none;
  }
}
</style>
