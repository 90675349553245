<template>
  <div>
    <div class="data-content">
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <div :key="i" v-for="(historic, key, i) in historic" :style="{'width': 100 / historicLenght + '%'}">
            <a class="nav-item nav-link" :class="[tabsType === key ? 'active' : '']" :style="historicLenght === 1 ? 'border-radius: 5px !important;' : '' || i === 0 ? 'border-top-left-radius: 5px !important; border-bottom-left-radius: 5px !important;' : '' || i === historicLenght - 1 ? 'border-top-right-radius: 5px !important; border-bottom-right-radius: 5px !important; border-right: var(--featured-dark) solid 2px !important;' : ''" @click="tabsType = key">{{key.toUpperCase()}}</a>
          </div>
        </div>
      </nav>
      <div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Parc.</th>
              <th scope="col">Situação</th>
              <th scope="col" v-if="$screen.height > 900">Feito</th>
              <th scope="col">Venc.</th>
              <th scope="col">Valor</th>
              <th scope="col" style="width: 120px"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              :key="i"
              v-for="(historic, i) in sortedHistoric"
            >
              <td style="width: 40px !important;">
                <div class="info-status m-0 status-success" style="width: 50px !important; min-width: 50px !important; height: 30px; padding: 7px">
                  {{historic.paymentNumber}}
                </div>
              </td>
              <td>{{historic.paymentStatus}}</td>
              <td v-if="$screen.height > 900">{{historic.dtFinished.replaceAll('-', '/')}}</td>
              <td>{{historic.dtDue}}</td>
              <td>{{historic.value}}</td>
              <td><a class="btn btn-sm btn-primary w-100" target="_black" :href="historic.documentUrl" :class="historic.documentUrl && historic.paymentStatus !== 'Pago' ? '' : 'disabled'">Baixar Guia</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['historic'],

  data: () => ({
    tabsType: ''
  }),

  created () {
    this.$root.$on('MeiHistoric::show', () => this.initicalChanges())
    this.initicalChanges()
  },

  computed: {
    sortedHistoric () {
      var tempHistoric = this.historic

      if (this.historic && this.historic.[this.tabsType]) {
        tempHistoric = this.historic.[this.tabsType]
        tempHistoric = Object.keys(this.historic.[this.tabsType]).map(i => this.historic.[this.tabsType][i]).slice().reverse()

        tempHistoric = tempHistoric.sort((a, b) => { return parseInt(a.paymentNumber, 10) - parseInt(b.paymentNumber, 10) }).slice().reverse()
      }
      // return tempHistoric.sort((a, b) => a.paymentNumber - b.paymentNumber).slice().reverse()
      return tempHistoric
    },
    historicLenght () {
      var tempHistoric = this.historic
      if (this.historic) {
        tempHistoric = Object.keys(this.historic).map(i => this.historic[i]).slice().reverse()
      }
      return tempHistoric.length
    }
  },

  methods: {
    emailsReadList (emailsRead) {
      let info = ''
      emailsRead = Object.keys(emailsRead).map(i => emailsRead[i])
      for (let i = 0; i < emailsRead.length; i++) {
        info += emailsRead[i].emailAddress + ' abriu no dia ' + emailsRead[i].dtSent + ',\n'
      }
      return info
    },
    initicalChanges () {
      var tempHistoric = this.historic
      if (this.historic) {
        tempHistoric = Object.keys(this.historic).map(i => this.historic[i]).slice().reverse()
        this.tabsType = Object.keys(this.historic)[tempHistoric.length - 1]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.data-content{
  .btn-primary{
    background: var(--featured-dark) !important;
  }
  .modal-header, .modal-footer{
    border: none !important;
  }

  .modal-body{
    padding-top: 0;
  }

  .nav-tabs{
    border: 0;
    margin: 0;
    // border-radius: 7px !important;
  }
  .nav-tabs .nav-item {
    margin-bottom: 0px;
    text-align: center;
    color: var(--featured-dark);
    font-weight: 700;
    border-radius: 0px !important;
    border: var(--featured-dark) solid 2px !important;
    cursor: pointer !important;
    border-right: 0 !important;
    font-size: 13.5px;
    &.active{
      color: white;
      background: var(--featured-dark);
    }
  }

  .table{
    box-shadow: none;
    background-color: white;
    border-radius: 7px;
    padding: 0;
    border-spacing: 0;
    th{
      border-top: none !important;
      border-bottom: none !important;
      padding: 7px 5px !important;
      padding-top: 5px !important;
      font-size: 13.5px;
    }
    td{
      border-top: #e9e9e9 solid 1px;
      font-size: 13px;
      padding: 7px 5px !important;
    }
    .btn-secondary, .btn-primary, .btn-danger, .btn-warning{
      font-size: 12px !important;
      padding: .65rem .9rem !important;
    }
  }
  h4, h5{
    font-weight: 700;
  }
  h5{
    font-size: 15px;
  }
  .form-group{
    margin: 7px 0;
  }
}
.details-buttom{
  color: blue !important;
  cursor: pointer;
  &[disabled]{
    cursor: not-allowed !important;
    color: gray !important;
  }
  &::selection {
      color: none;
      background: none;
  }
}
</style>
