<template>
  <div>
    <div class="data-content" :class="{show: details}" :style="{display: details ? 'none' : 'block'}">
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <div :key="i" v-for="(historic, key, i) in historic" :style="{'width': 100 / historicLenght + '%'}">
            <a class="nav-item nav-link" :class="[tabsType === key ? 'active' : '']" :style="historicLenght === 1 ? 'border-radius: 5px !important;' : '' || i === 0 ? 'border-top-left-radius: 5px !important; border-bottom-left-radius: 5px !important;' : '' || i === historicLenght - 1 ? 'border-top-right-radius: 5px !important; border-bottom-right-radius: 5px !important; border-right: var(--featured-dark) solid 2px !important;' : ''" @click="tabsType = key">{{key.toUpperCase()}}</a>
          </div>
        </div>
      </nav>
      <div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Per.</th>
              <th scope="col">Comprovante</th>
              <th scope="col" v-if="$screen.height > 900">Busca</th>
              <th scope="col">Valor</th>
              <th scope="col">Detalhes</th>
              <th scope="col" style="width: 120px"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              :key="i"
              v-for="(historic, i) in sortedHistoric"
            >
              <template v-if="historic.paymentNumber">
                <td style="width: 40px">
                  <div class="info-status m-0 status-success" style="width: 50px !important; min-width: 50px !important; height: 30px; padding: 7px">
                    {{historic.paymentNumber}}
                  </div>
                </td>

                <td style="width: 150px;">
                  <template v-if="historic.paied">
                    <template v-if="hover == historic.paymentNumber && hover !== false">
                      <button class="btn btn-sm btn-danger w-100" data-toggle="tooltip" data-placement="bottom" style="font-size: 12.5px" @mouseleave="hover = false" @click="changePaymentStatus(historic.paymentNumber)">
                        Retirar pago
                      </button>
                    </template>
                    <template v-else>
                      <div @mouseover="hover = historic.paymentNumber">
                        Pago
                      </div>
                    </template>
                  </template>
                  <template v-else-if="historic.receiptsGuides && historic.receiptsGuides">
                    <div class="dropdown mr-1">
                      <button class="btn btn-sm dropdown-toggle w-100" style="font-size: 12.5px;" :class="(Object.keys(historic.receiptsGuides).length) > 1 ? 'btn-warning' : 'btn-primary'" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Comprovante
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" target="_black" :href="historic.receiptsGuides[i].documentUrl" :key="i" v-for="(guide, i) in historic.receiptsGuides">Comprovante</a>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="historic.type.includes('sem movimento') || historic.type.includes('zerada') || historic.value === '0,00'">Sem Movimento</template>
                  <template v-else-if="collection.loading || collection.status.info == 'Verificando Erro'">Buscando...</template>
                  <template v-else>
                    <template v-if="hover == historic.paymentNumber && hover !== false">
                      <button class="btn btn-sm btn-danger w-100" data-toggle="tooltip" data-placement="bottom" title="Após dar como pago, o período não será mais gerado, e nem enviado ao seu cliente." style="font-size: 12.5px" @mouseleave="hover = false" @click="changePaymentStatus(historic.paymentNumber)">
                        Dar como pago
                      </button>
                    </template>
                    <template v-else>
                      <div @mouseover="hover = historic.paymentNumber">
                        Sem Comprovante
                      </div>
                    </template>
                  </template>
                </td>

                <td v-if="$screen.height > 900">{{historic.dtFinished.replaceAll('-', '/')}}</td>
                <td>R$ {{historic.value}}</td>
                <td><a class="details-buttom" @click="details = historic.details">Visualizar</a></td>
                <td>
                  <div class="dropdown">
                    <button class="btn btn-sm btn-primary dropdown-toggle w-100" style="font-size: 12.5px" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Baixar Guia
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item" target="_black" :href="historic.documentStatementUrl" :class="historic.documentStatementUrl ? '' : 'disabled'">Extrato</a>
                      <a class="dropdown-item" target="_black" :href="historic.documentReceiptUrl" :class="historic.documentReceiptUrl ? '' : 'disabled'">Recibo</a>
                      <a class="dropdown-item" target="_black" :href="historic.documentPaymentUrl" :class="historic.documentPaymentUrl ? '' : 'disabled'">Darf/Dae</a>
                    </div>
                  </div>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="data-details" :class="{show: details}" :style="{display: details ? 'block' : 'none'}">
      <div class="row justify-content-end pr-3"><div style="padding: 10px 16px !important;" class="btn btn-secondary btn-sm" @click="details = null"><i class="fa-solid fa-xmark" style="margin-right: 0 !important;"></i></div></div>
      <table class="table">
        <tbody class="w-100">
          <div :key="i"
            v-for="(detail, i) in details">
          <thead class="w-100" v-if='i == 0'>
            <tr>
              <th scope="col" style="width: 770px">Descrição</th>
              <th scope="col" style="width: 150px">PA do Débito</th>
              <th scope="col" style="width: 150px">Débito Apurado</th>
              <th scope="col" style="width: 150px">Créditos Vinc.</th>
              <th scope="col" style="width: 150px">Saldo a Pagar</th>
            </tr>
          </thead>
          <thead v-else>
            <tr>
              <th scope="col" style="width: 770px"></th>
              <th scope="col" style="width: 150px"></th>
              <th scope="col" style="width: 150px"></th>
              <th scope="col" style="width: 150px"></th>
              <th scope="col" style="width: 150px"></th>
            </tr>
          </thead>
          <tr>
            <td class="font-weight-bold" style="background-color: var(--background-gray)">{{detail.title}}</td>
            <td class="font-weight-bold" style="background-color: var(--background-gray)"></td>
            <td class="font-weight-bold" style="background-color: var(--background-gray)"></td>
            <td class="font-weight-bold" style="background-color: var(--background-gray)"></td>
            <td class="font-weight-bold" style="background-color: var(--background-gray)">R$ {{detail.total}}</td>
          </tr>
          <tr
            :key="i"
            v-for="(item, i) in detail.items"
            class="text-left"
          >
            <td>{{item.name}}</td>
            <td>{{item.dtApuration}}</td>
            <td>R$ {{item.debitApuration}}</td>
            <td>R$ {{item.credits}}</td>
            <td>R$ {{item.total}}</td>
          </tr>
          </div>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  props: ['collection', 'historic'],

  components: {
  },

  data: () => ({
    hover: false,
    tabsType: '',
    details: null
  }),

  created () {
    this.$root.$on('DctfwebHistoric::show', () => this.initicalChanges())
    this.initicalChanges()
  },

  computed: {
    sortedHistoric () {
      var tempHistoric = this.historic

      if (this.historic && this.historic.[this.tabsType]) {
        tempHistoric = this.historic.[this.tabsType]
        tempHistoric = Object.keys(this.historic.[this.tabsType]).map(i => this.historic.[this.tabsType][i]).slice().reverse()

        tempHistoric = tempHistoric.sort((a, b) => { return parseInt(a.paymentNumber, 10) - parseInt(b.paymentNumber, 10) }).slice().reverse()
      }
      // return tempHistoric.sort((a, b) => a.paymentNumber - b.paymentNumber).slice().reverse()
      return tempHistoric
    },
    historicLenght () {
      var tempHistoric = this.historic
      if (this.historic) {
        tempHistoric = Object.keys(this.historic).map(i => this.historic[i]).slice().reverse()
      }
      return tempHistoric.length
    }
  },

  methods: {
    async changePaymentStatus (paymentNumber) {
      if (!this.collection.historic[this.tabsType][paymentNumber].paied) {
        this.collection.historic[this.tabsType][paymentNumber].paied = true
        await this.$firebase.database().ref('dctfwebCollections').child(window.uid).child(this.collection.customerId).child('historic').child(this.tabsType).child(paymentNumber).update({ paied: true })
        if (JSON.parse(this.$cookie.get('currentProfile'))) {
          await this.$firebase.database().ref(`usersHistoric/${window.uid}`).child(this.moment(new Date()).valueOf()).update({
            name: JSON.parse(this.$cookie.get('currentProfile')).name,
            office: JSON.parse(this.$cookie.get('currentProfile')).office,
            category: 'edit',
            color: 'status-purple',
            date: this.moment(new Date()).format('DD/MM/YYYY') + ' ás ' + this.moment(new Date()).format('hh:mm') + 'h',
            description: 'Deu como pago o período ' + paymentNumber + '/' + this.tabsType + ' do cliente ' + this.collection[this.collection.documentType] + ' no processo "DCTFWeb"'
          })
        } else {
          await this.$firebase.database().ref(`usersHistoric/${window.uid}`).child(this.moment(new Date()).valueOf()).update({
            name: 'Sem Perfil',
            category: 'edit',
            color: 'status-purple',
            date: this.moment(new Date()).format('DD/MM/YYYY') + ' ás ' + this.moment(new Date()).format('hh:mm') + 'h',
            description: 'Deu como pago o período ' + paymentNumber + '/' + this.tabsType + ' do cliente ' + this.collection[this.collection.documentType] + ' no processo "DCTFWeb"'
          })
        }
      } else {
        this.$delete(this.collection.historic[this.tabsType][paymentNumber], 'paied')
        await this.$firebase.database().ref('dctfwebCollections').child(window.uid).child(this.collection.customerId).child('historic').child(this.tabsType).child(paymentNumber).child('paied').remove()
        if (JSON.parse(this.$cookie.get('currentProfile'))) {
          await this.$firebase.database().ref(`usersHistoric/${window.uid}`).child(this.moment(new Date()).valueOf()).update({
            name: JSON.parse(this.$cookie.get('currentProfile')).name,
            office: JSON.parse(this.$cookie.get('currentProfile')).office,
            category: 'edit',
            color: 'status-purple',
            date: this.moment(new Date()).format('DD/MM/YYYY') + ' ás ' + this.moment(new Date()).format('hh:mm') + 'h',
            description: 'Retirou como pago o período ' + paymentNumber + '/' + this.tabsType + ' do cliente ' + this.collection[this.collection.documentType] + ' no processo "DCTFWeb"'
          })
        } else {
          await this.$firebase.database().ref(`usersHistoric/${window.uid}`).child(this.moment(new Date()).valueOf()).update({
            name: 'Sem Perfil',
            category: 'edit',
            color: 'status-purple',
            date: this.moment(new Date()).format('DD/MM/YYYY') + ' ás ' + this.moment(new Date()).format('hh:mm') + 'h',
            description: 'Retirou como pago o período ' + paymentNumber + '/' + this.tabsType + ' do cliente ' + this.collection[this.collection.documentType] + ' no processo "DCTFWeb"'
          })
        }
      }
    },
    emailsReadList (emailsRead) {
      let info = ''
      emailsRead = Object.keys(emailsRead).map(i => emailsRead[i])
      for (let i = 0; i < emailsRead.length; i++) {
        info += emailsRead[i].emailAddress + ' abriu no dia ' + emailsRead[i].dtSent + '\n'
      }
      return info
    },
    initicalChanges () {
      var tempHistoric = this.historic
      if (this.historic) {
        tempHistoric = Object.keys(this.historic).map(i => this.historic[i]).slice().reverse()
        this.tabsType = Object.keys(this.historic)[tempHistoric.length - 1]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.data-content{
  .btn-primary{
    background: var(--featured-dark) !important;
  }
  .modal-header, .modal-footer{
    border: none !important;
  }

  .modal-body{
    padding-top: 0;
  }

  .nav-tabs{
    border: 0;
    margin: 0;
    // border-radius: 7px !important;
  }
  .nav-tabs .nav-item {
    margin-bottom: 0px;
    text-align: center;
    color: var(--featured-dark);
    font-weight: 700;
    border-radius: 0px !important;
    border: var(--featured-dark) solid 2px !important;
    font-size: 13.5px;
    cursor: pointer !important;
    border-right: 0 !important;
    &.active{
      color: white;
      background: var(--featured-dark);
    }
  }

  .table{
    box-shadow: none;
    background-color: white;
    border-radius: 4px;
    padding: 0;
    border-spacing: 0;
    th{
      border-top: none !important;
      border-bottom: none !important;
      padding: 7px 5px !important;
      padding-top: 5px !important;
      font-size: 13.5px;
    }
    td{
      border-top: #e9e9e9 solid 1px;
      font-size: 13px;
      padding: 7px 5px !important;
    }
    .btn-secondary, .btn-primary, .btn-danger, .btn-warning{
      font-size: 12px !important;
      padding: .65rem .9rem !important;
    }
  }
  h4, h5{
    font-weight: 700;
  }
  h5{
    font-size: 15px;
  }
  .form-group{
    margin: 7px 0;
  }
}
.details-buttom{
  color: blue !important;
  cursor: pointer;
  &[disabled]{
    cursor: not-allowed !important;
    color: gray !important;
  }
  &::selection {
      color: none;
      background: none;
  }
}
.data-details{
  .table{
    box-shadow: none;
    background-color: white;
    border-radius: 4px;
    padding: 0;
    border-spacing: 0;
    th{
      border-top: none !important;
      border-bottom: none !important;
      padding: 7px 10px !important;
      padding-top: 5px !important;
      font-size: 12.5px;
    }
    td{
      border-top: none;
      font-size: 12.5px;
      padding: 7px 10px !important;
    }
    .btn-primary, .btn-danger, .btn-warning{
      padding: .7rem 1rem !important;
    }
  }
}
</style>
